import React from 'react';
import './index.less';


function TopBanner(props) {
    return (
        <section id="TopBanner" className="top_banner">
            <img src={props.coverImg} alt="banner" />
            <section className="w">{props.title}</section>
        </section>
    )
}

export default TopBanner;