import React, { useEffect, useImperativeHandle } from "react";
import Player from 'griffith';

import "./index.less";

const Content = props => {

  useEffect(() => {
    console.log('props=>', props);
  }, [])

  // 自定义ref可以访问的属性
  useImperativeHandle(props.onRef, () => {
    return {
      changeEpisode: (item) => {
        document.querySelector('video').src = item.path
        document.querySelector('video').load()
        document.querySelector('video').play()
      }
    }
  })

  const preview = href => {
    window.location.href = href;
  }

  const createContent = () => {
    const { data } = props;
    if (data.type == 'video') {
      return < Player
        id="media_player"
        duration={0}
        cover={data.cover}
        sources={data.video}
        initialObjectFit="cover"
        locale="zh-Hans"
      ></Player>
    } else if (data.type == 'image') {
      return <img className="cover-img" src={data.image} />
    } else if (data.type == 'html') {
      return <div className="html-view">
        <img className="cover-img" src={data.html} alt="" />
        {
          data.previewUrl ? <div className="mask" onClick={preview.bind(this, data.previewUrl)} >
            <div>查看全部</div>
          </div> : ''
        }
      </div>
    } else if (data.type == 'audio') {
      return <div className="audio-view">
        <img className="cover-img" src={data.cover} alt="" />
        <audio className="audio" src={data.audio} controls></audio>
      </div>
    } else {
      return <img className="cover-img" src={data.cover} alt="" />
    }
  }

  return (
    <div className="detail_content_container">
      {createContent()}
    </div>
  )
}
export default Content;
// export default Content;