import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import { GET_USER_INFO } from '../../../../utils/api';
import { MailOutlined } from '@ant-design/icons';
import './index.less';

import AUTH_EXPERT from '../../../../assets/images/yes.png';
import EXPER_DEFAULT_AVATAR from '../../../../assets/images/expert_avatar.png';
import USER_DEFAULT_AVATAR from '../../../../assets/images/avatar.png';

class UserInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            infos: {},
            desc: '', //用户简介
            isOpen: false, //是否展开详情
        }
    }

    componentDidMount() {
        this.getUserInfo(this.props.userId)
    }

    // 获取用户信息
    async getUserInfo(userId) {
        const { data: ret } = await axios.post(GET_USER_INFO, qs.stringify({ userId }))
        console.log("用户信息=>", ret.data);
        this.setState({
            infos: ret.data,
            desc: ret.data.userDesc || ret.data.proDesc,
        })
    }

    openChange = () => {
        this.setState(({ isOpen }) => (
            { isOpen: !isOpen }
        ))
    }

    render() {
        const { infos, desc, isOpen } = this.state;
        return (
            <section className="user_info flex">
                <img src={infos.headUrl || (infos.userType === 3 ? EXPER_DEFAULT_AVATAR : USER_DEFAULT_AVATAR)} alt="avatar" />
                <section className="infos">
                    <section className="flex flex-align-items-center" >
                        <span>{infos.userName}</span>
                        {
                            infos.userType === 3
                                ?
                                <section className="flex flex-align-items-center" >
                                    <img src={AUTH_EXPERT} alt="AUTH_EXPERT" />
                                    {
                                        infos.email &&
                                        <section className="contact_me flex flex-align-items-center " >
                                            <MailOutlined /><a href={`mailto:${infos.email}`}>与我联系</a>
                                        </section>
                                    }
                                </section>
                                :
                                null
                        }
                    </section>
                    {
                        infos.userType !== 1
                            ?
                            null
                            :
                            <section className="address flex flex-align-items-center" >
                                <span className="flex-shrink" >省份：</span>
                                <span>{infos.province}</span>
                            </section>
                    }
                    <section style={{ marginTop: infos.userType === 3 ? 22 : 0 }} className="introduction flex flex-align-items-center" >
                        <span className="flex-shrink flex-align-self-start" >简介：</span>
                        <p>
                            {
                                desc && desc.length > 250 && !isOpen ? desc.substring(0, 250) : desc
                            }
                            {
                                desc && desc.length > 250 ?
                                    <span className="toggle-text" onClick={this.openChange}>
                                        {isOpen ? '收起' : '展开'}
                                    </span>
                                    : ''
                            }
                        </p>
                    </section>
                </section>
            </section>
        )
    }
}

export default UserInfo;