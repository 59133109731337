import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import qs from 'querystring';
import './index.less';
import { CHECK_DOWN, DELETE_CONFIRM, DEL_WORKS_ITEM, GET_FILE_LIST } from '../../utils/api';
import { message, notification, Popconfirm, Tooltip, Modal, Button } from 'antd';
import { WROKS_TYPE } from '../../utils/config';
import { isLoginEvent } from '../../utils/tools';
import { inject, observer } from 'mobx-react';
import { HeartFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Progress } from '../components';

@inject('store')
@observer
class WorkCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            progressCount: 0,
            progressShow: false,
            downListVisible: false,
            fileList: [],
            projectFileInfo: {},
            coverWidth: 100
        }
        this.coverImgEl = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.calcCoverHeight();
        }, 200)
    }

    // 判断图片高度是否小于高度
    calcCoverHeight(containerHeight = 180) {
        const imgHeight = this.coverImgEl.current
            ? this.coverImgEl.current.height : 0;

        if (imgHeight < containerHeight) {
            // this.coverImgEl.current.style.marginLeft = '-12%'
            this.setState({
                coverWidth: this.state.coverWidth + 25
            });
        }
    }


    // 检查下载
    async checkDownload(worksId, coin, filePath, fileSize, type = 0) {
        const { progressShow } = this.state;
        if (progressShow) return;
        notification['info']({
            message: '应急科普共享平台提示',
            description: '正在进行安全校验...'
        })
        if (!isLoginEvent()) {
            notification['warning']({
                message: '应急科普共享平台提示',
                description: '检测您还未登录，请先登录进行下载'
            })
            this.props.store.setLogin(true)
            return
        }
        const { data: ret } = await axios.post(CHECK_DOWN, qs.stringify({
            worksId,
            coin,
            type
        }))
        if (ret.code !== 200) return notification['error']({
            message: '应急科普共享平台提示',
            description: ret.msg
        });
        notification['success']({
            message: '应急科普共享平台提示',
            description: '安全校验成功'
        })

        this.downLoadFile(filePath, fileSize)
    }
    // async checkDownload(worksId, coin, fileSizeB) {
    //     const { progressShow } = this.state;
    //     if (progressShow) return;
    //     notification['info']({
    //         message: '应急科普共享平台提示',
    //         description: '正在进行安全校验...'
    //     })
    //     if (!isLoginEvent()) {
    //         notification['warning']({
    //             message: '应急科普共享平台提示',
    //             description: '检测您还未登录，请先登录进行下载'
    //         })
    //         this.props.store.setLogin(true)
    //         return
    //     }
    //     // const { data: info } = await axios.post(GET_USER_INFO, qs.stringify({
    //     //     userId: JSON.parse(window.localStorage.getItem('userInfo')).id
    //     // }))
    //     // if (parseInt(info.data.coin) < parseInt(coin) && parseInt(status) === 0) return notification['warning']({
    //     //     message: '应急科普共享平台提示',
    //     //     description: '您的科普力不足'
    //     // });
    //     const { data: ret } = await axios.post(CHECK_DOWN, qs.stringify({
    //         worksId,
    //         coin
    //     }))
    //     if (ret.code !== 200) return notification['error']({
    //         message: '应急科普共享平台提示',
    //         description: ret.msg
    //     });
    //     notification['success']({
    //         message: '应急科普共享平台提示',
    //         description: '安全校验成功'
    //     })
    //     this.downLoadFile(worksId, parseInt(fileSizeB))
    // }

    // 开始下载
    async downLoadFile(filePath, fileSize) {
        const path = filePath.split('/')

        const { data: ret, status: code } = await axios.get(filePath, {
            responseType: 'blob',
            onDownloadProgress: progress => {
                // console.log('progress', progress, Math.floor((progress.loaded / fileSize) * 100))
                this.setState({
                    progressCount: Math.floor((progress.loaded / fileSize) * 100),
                    progressShow: true
                })
            }
        })

        if (code !== 200) return notification['error']({
            title: '应急科普共享平台',
            message: '资源加载错误，请重新下载'
        })

        // console.log(ret)

        const downloadUrl = URL.createObjectURL(ret);
        const _link = document.createElement('a');
        _link.download = path[path.length - 1]
        _link.href = downloadUrl;
        _link.style.display = 'none';
        document.body.append(_link);
        _link.click()
        URL.revokeObjectURL(ret)

        this.setState({
            progressShow: false
        })
        notification['success']({
            message: '应急科普共享平台提示',
            description: '资源加载成功，请选择保存文件目录'
        })
    }

    // 获取相关附件
    async getFileList(worksId) {
        const { data: ret } = await axios.get(GET_FILE_LIST, { params: { worksId } })
        console.log(ret)
        const { list: fileList = [], id, projectFile, projectFileSize } = ret.data[0]
        this.setState({
            fileList,
            projectFileInfo: {
                id,
                projectFile,
                projectFileSize
            }
        })
    }


    // 删除作品
    async handleDeleteWorksItem(id) {
        const { data: ret } = await axios.post(DEL_WORKS_ITEM, qs.stringify({
            id
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        message.success('删除成功')
        this.props.onDelete()
    }


    // 被删除后的确定
    async handleDeleteConfirm(worksId, type) {
        const { data: ret } = await axios.post(DELETE_CONFIRM, qs.stringify({
            worksId,
            type
        }))
        console.log(ret)
        if (ret.code !== 200) return message.error(ret.msg);
        message.success('操作成功');
        this.props.onConfirm && this.props.onConfirm();
    }

    render() {
        // 缺少 鼠标放上 视频预览功能
        const {
            progressCount, progressShow, fileList, projectFileInfo, coverWidth
        } = this.state;
        const {
            listType,
            title,
            avatar,
            author,
            dateTime,
            fileSize = 0,
            integral,
            coverImg,
            userId,
            btnType = 'down',
            // isAuth,   这个本来是注释的，父组件也没有传过来，所以我把他相关的代码都注释了，因为有报错
            id,
            wId,
            isColl = false,
            isDel = 0,
            confirmDelType = 2,
            detail
        } = this.props
        const Btn = type => {
            if (type === 'down') {
                return (
                    <section onClick={e => {
                        e.nativeEvent.stopImmediatePropagation()
                        e.stopPropagation()
                        console.log('download')
                        Modal.confirm({
                            title: '下载提示',
                            icon: <ExclamationCircleOutlined />,
                            content: '下载作品时，请勿离开此页面；否则会停止下载，是否要下载作品？',
                            onOk: () => {
                                this.getFileList(detail.id)
                                this.setState({
                                    downListVisible: true
                                })
                            },
                            okText: '确定',
                            cancelText: '取消'
                        })
                    }}>
                        <Tooltip title={`下载将消耗${integral}个科普力`} >
                            <i className="iconfont icon-xiazaiwancheng"></i> 下载
                        </Tooltip>
                    </section>
                )
            } else if (type === 'edit') {
                return (
                    <section
                        onClick={e => {
                            e.nativeEvent.stopImmediatePropagation()
                            e.stopPropagation()
                            console.log('edit')
                            console.log(listType)
                            this.props.history.push(`/upload/${listType === 'media' || listType === 'venue' ? 'video' : 'imgText'}/${WROKS_TYPE[listType]}/${id}`)
                        }}
                    >
                        <i className="iconfont icon-bianji" ></i> 编辑
                    </section>
                )
            } else if (type === 'get') {
                return (
                    <section
                        onClick={e => {
                            e.nativeEvent.stopImmediatePropagation()
                            e.stopPropagation()
                            console.log('get')
                            Modal.confirm({
                                title: '下载提示',
                                icon: <ExclamationCircleOutlined />,
                                content: '下载作品时，请勿离开此页面；否则会停止下载，是否要下载作品？',
                                onOk: () => {
                                    this.getFileList(detail.id)
                                    this.setState({
                                        downListVisible: true
                                    })
                                    // this.checkDownload(id, integral, fileSize)
                                },
                                okText: '确定',
                                cancelText: '取消'
                            })
                        }}
                    >
                        <Tooltip title={`您已购买，本次下载不扣除科普力`} >
                            <i className="iconfont icon-huoqu" ></i> 获取
                        </Tooltip>
                    </section>
                )
            } else if (type === 'del') {
                return (
                    <section
                        onClick={e => {
                            e.nativeEvent.stopImmediatePropagation()
                            e.stopPropagation()
                            console.log('delete')
                        }}
                    >
                        <Popconfirm
                            title={`确定要删除《${title}》作品吗 ? `}
                            onConfirm={this.handleDeleteWorksItem.bind(this, wId)}
                            // onCancel={e => e}
                            okText="确定"
                            cancelText="取消"
                        >
                            <i className="iconfont icon-shanchu1" ></i> 删除
                        </Popconfirm>
                    </section>
                )
            } else if (type === 'exam') {
                return (
                    <section
                        onClick={e => {
                            e.nativeEvent.stopImmediatePropagation()
                            e.stopPropagation()
                            console.log('正在审核')
                        }}
                    >
                        <i className="iconfont icon-huoqu" ></i> 审核中
                    </section>
                )
            }
        }
        return (
            <section id="WorkCard" className="works_list_container">
                {/* 已删除模态框 */}
                {
                    isDel
                        ?
                        <section className="works_del_container flex-direction-column flex-align-items-center flex-justify-content-center" onClick={e => {
                            e.nativeEvent.stopImmediatePropagation()
                            e.stopPropagation()
                            // console.log('作品已被删除')
                        }}>
                            <span>该作品已被删除</span>
                            {
                                JSON.parse(window.localStorage.getItem('userInfo')).id === userId
                                    ?
                                    <>
                                        <div className="del_confirm" onClick={e => {
                                            e.nativeEvent.stopImmediatePropagation()
                                            e.stopPropagation()
                                            console.log('作品还原')
                                            this.handleDeleteConfirm(wId, confirmDelType)
                                        }} >还原</div>
                                        <div className="del_confirm" onClick={e => {
                                            e.nativeEvent.stopImmediatePropagation()
                                            e.stopPropagation()
                                            console.log('作品已被删除')
                                            this.handleDeleteConfirm(wId, confirmDelType)
                                        }} >移除</div>
                                    </>
                                    :
                                    <div className="del_confirm" onClick={e => {
                                        e.nativeEvent.stopImmediatePropagation()
                                        e.stopPropagation()
                                        console.log('作品已被删除')
                                        this.handleDeleteConfirm(wId, confirmDelType)
                                    }} >移除</div>
                            }
                        </section>
                        :
                        null
                }
                <Progress
                    progress={progressCount}
                    isShow={progressShow}
                    showTop={0}
                    hideTop='-70px'
                />
                {/* 作品封面 */}
                <section className="works_preview_container" onClick={this.props.onClick}>
                    <img
                        style={{ marginLeft: `-${(coverWidth - 100) / 2}%` }}
                        ref={this.coverImgEl}
                        width={coverWidth + "%"}
                        src={coverImg}
                        alt="coverImg"
                    />
                    {/* {
                        (detail.isAuth && detail.isAuth > 0)   ?
                            <img src={require('../../assets/images/isAuth.png')} style={{ width: 40, height: 24 }} alt="auth" />
                            :
                            null
                        // <img src={require('../../assets/images/isAuth.png')} style={{ width: 40, height: 24 }} alt="auth" />
                    } */}
                    <span>{title}</span>
                    {
                        (listType === 'media' || listType === 'venue')
                        &&
                        <i className="iconfont icon-bofang" ></i>
                    }
                    {
                        isColl
                            ?
                            <HeartFilled className="coll_icon" onClick={e => {
                                e.nativeEvent.stopImmediatePropagation()
                                e.stopPropagation()
                                console.log('coll')
                                this.props.onCollWorks && this.props.onCollWorks()
                            }} />
                            :
                            null
                    }
                </section>
                {/* 基本信息 */}
                <section className="works_info_container" onClick={this.props.onClick}>
                    {/* 用户信息 */}
                    <section className="works_author flex flex-align-items-center">
                        <img src={avatar ? avatar : require('../../assets/images/avatar.png')} alt="avatar" />
                        <span title={author}>{author!=null && author.length > 15 ? author.substring(0, 14) + '…' : author}</span>
                    </section>
                    {/* 时间 科普力 操作 */}
                    <section className="works_action flex flex-align-items-center flex-justify-content-space-between">
                        <span>{dateTime}</span>
                        <span> <i className="iconfont icon-wanggeguanli-wanggeliliang"></i> {integral}科普力</span>
                        {
                            Btn(btnType)
                        }
                    </section>
                </section>

                <Modal
                    visible={this.state.downListVisible}
                    footer={null}
                    onCancel={() => {
                        this.setState({
                            downListVisible: false
                        })
                    }}
                    width="600px"
                >
                    <div className="select_file">
                        <h3 className="select_title">下载作品</h3>
                        <div className="select_content">
                            <ul className="select_file_list flex flex-align-items-center flex-wrap" >
                                {
                                    fileList.length > 0 ?

                                        fileList.map((item, index) => {
                                            return (
                                                <li
                                                    key={item.id}
                                                    onClick={() => {
                                                        this.checkDownload(
                                                            item.id,
                                                            parseInt(item.coin),
                                                            item.filePath,
                                                            item.fileSizeB
                                                        )
                                                        this.setState({
                                                            downListVisible: false
                                                        })
                                                    }}
                                                >
                                                    <Button type="primary" >{this.props.match.params.type === 'venue' || this.props.match.params.type === 'media' ? `第${index + 1}集` : '下载'}</Button>
                                                </li>
                                            )
                                        })
                                        :
                                        <li
                                            onClick={() => {
                                                this.checkDownload(
                                                    id,
                                                    integral,
                                                    detail.filePath,
                                                    fileSize
                                                )
                                                // this.checkDownload(id, integral, fileSize)
                                                this.setState({
                                                    downListVisible: false
                                                })
                                            }}
                                        >
                                            <Button type="primary" >下载</Button>
                                        </li>
                                }

                            </ul>
                            {/* <div className="select_content_name">快乐无限，有道有限快乐无限，有道有限快乐无限，有道有限快乐无限，有道有限.mp4</div> */}
                            {/* <Button style={{ marginTop: '-10px' }} onClick={() => {
                                this.checkDownload(worksDetail['fileList'][worksIndex].id,
                                    worksDetail['fileList'][worksIndex].coin,
                                    worksDetail['fileList'][worksIndex].fileSizeB)
                                this.setState({
                                    downListVisible: false
                                })
                            }}>下载</Button> */}
                        </div>
                    </div>
                    {
                        !projectFileInfo.projectFile
                            ?
                            null
                            :
                            <div className="select_file">
                                <h3 className="select_title">工程文件</h3>
                                <div className="select_content">
                                    <Button onClick={e => {
                                        this.checkDownload(
                                            projectFileInfo.id,
                                            30,
                                            projectFileInfo.projectFile,
                                            projectFileInfo.projectFileSize,
                                            1
                                        )
                                        this.setState({
                                            downListVisible: false
                                        })
                                    }}>工程文件下载</Button>
                                </div>
                            </div>
                    }
                </Modal>

            </section>
        )
    }
}

export default withRouter(WorkCard)