import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import moment from 'moment';
import './index.less';
import { GET_USER_WORKS } from '../../../../utils/api';
import { Classify, WorkCard, NotFound } from '../../../../compoents/components';
import { Pagination } from 'antd';
import { WROKS_TYPE } from '../../../../utils/config';
import { withRouter } from 'react-router-dom';

class UserWorks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            worksList: [],
            total: 1,
            pageNum: 1
        }
    }

    componentDidMount() {
        this.getUserWorks({ userId: this.props.userId, pageNum: 1, type: null })
    }
    // 获取用户作品
    async getUserWorks(params) {
        const { data: ret } = await axios.post(GET_USER_WORKS, qs.stringify({ ...params, pageSize: 8 }))
        console.log(ret);
        this.setState({
            worksList: ret.data,
            pageNum: params.pageNum,
            total: ret.total
        })
    }

    render() {
        const { worksList, total } = this.state;
        return (
            <section className="user_works">
                <section className="flex flex-align-items-center flex-justify-content-space-between" >
                    <h1 style={{ fontSize: 24, marginBottom: 0 }} >TA的作品</h1>
                    <Classify onChange={e => {
                        this.getUserWorks({ userId: this.props.userId, pageNum: 1, type: e === 'null' ? null : parseInt(e) })
                    }} />
                    {/* test git push  */}
                </section>
                {
                    worksList.length > 0
                        ?
                        <section className="flex flex-wrap" >
                            {
                                worksList.map(item => {
                                    return (
                                        <WorkCard
                                            key={item.id}
                                            id={item.pathId}
                                            listType={WROKS_TYPE[`${item.worksType}`]}
                                            title={item.title}
                                            coverImg={item.cover}
                                            avatar="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2292445447,3204725421&fm=26&gp=0.jpg"
                                            dateTime={moment(item.createTime).format('YYYY/MM/DD')}
                                            integral={item.coin}
                                            author={item.userName}
                                            fileSize={item.fileSizeB}
                                            btnType={item.isDown > 0 ? 'get' : 'down'}
                                            detail={item}
                                            onClick={() => {
                                                this.props.history.push(`/detail/${WROKS_TYPE[`${item.worksType}`]}/${item.id}`)
                                            }}
                                        />
                                    )
                                })
                            }
                        </section>
                        :
                        <NotFound text="该用户暂未发布作品" />
                }

                <Pagination
                    defaultCurrent={1}
                    pageSize={8}
                    total={total}
                    style={{ textAlign: 'right', margin: '50px 0 68px' }}
                    onChange={pageNum => {
                        this.getUserWorks({
                            userId: this.props.userId,
                            pageNum
                        })
                    }}
                />
            </section>
        )
    }
}

export default withRouter(UserWorks);