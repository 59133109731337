import React, { Component } from 'react';
import { message, Pagination } from 'antd';
import axios from 'axios';
import qs from 'querystring';
import './index.less';
import { GET_IS_READ, GET_MY_MSG, MSG_ITEM_READ, READ_ALL_MSG } from '../../../utils/api';
import { isLoginEvent } from '../../../utils/tools';
import { WROKS_TYPE } from '../../../utils/config';

import { inject, observer } from 'mobx-react';


@inject('store') @observer
class MyMsg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 0,
            msgTab: [
                {
                    title: '审核通知',
                    type: 1
                },
                {
                    title: '评论通知',
                    type: 2
                }
            ],
            msgList: [],
            total: 1,
            allIsRead: []
        }
    }

    componentDidMount() {
        if (!isLoginEvent()) return;
        this.getMessageList()
        this.getIsReadMsg()
    }

    // 获取消息列表
    async getMessageList(type = 1, pageNum = 1) {
        // type 1 审核 2 评论
        const { data: ret } = await axios.post(GET_MY_MSG, qs.stringify({
            type,
            pageNum,
            pageSize: 6
        }))
        this.setState({
            msgList: ret.data || [],
            total: ret.total
        })
    }

    /**
     * @name 全部已读
     * @param {number} types 0 不提示全部已读 1 提示全部已读 默认：1
     */
    async handleReadAllMsg(types = 1) {
        const { msgTab, tabIndex } = this.state;
        const type = msgTab[tabIndex].type
        const { data: ret } = await axios.post(READ_ALL_MSG, qs.stringify({
            type
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        if (types) {
            message.success('全部已读')
        }
        this.getMessageList(type)
        this.getIsReadMsg()
        this.props.store.setAllIsNotRead(0)
        // window.location.reload()
    }

    async getIsReadMsg() {
        const { data: ret } = await axios.post(GET_IS_READ)
        console.log(ret)
        const allIsRead = ret.data ? [ret.data.isReadExa, ret.data.isReadCom] : []
        console.log(allIsRead)
        if (allIsRead.filter(item => item < 2).length > 0) {
            this.props.store.setAllIsNotRead(0)
        }
        this.setState({
            allIsRead
        })
    }

    // 单条已读
    async handleReadMessageItem(id) {
        const { msgTab, tabIndex } = this.state;
        const type = msgTab[tabIndex].type
        const { data: ret } = await axios.post(MSG_ITEM_READ, qs.stringify({ id, type }))
        console.log(ret)
        this.getMessageList(type)
        this.getIsReadMsg()
        // this.handleReadAllMsg(0)
    }

    handleChangeTab(index) {
        const { msgTab } = this.state;
        this.getMessageList(msgTab[index].type)
        this.getIsReadMsg()
        this.setState({
            tabIndex: index
        })
    }

    render() {
        const { msgList, msgTab, tabIndex, total, allIsRead } = this.state;
        return (
            <section id="MyMsg">
                <section className="msg_tab flex flex-align-items-center flex-justify-content-space-between">
                    <section className="msg_tab_list flex flex-align-items-center " >
                        {
                            msgTab.map((item, index) => {
                                return (
                                    <section
                                        key={index}
                                        className={`${tabIndex === index ? 'active' : ''}`}
                                        onClick={this.handleChangeTab.bind(this, index)}
                                    >
                                        {item.title}
                                        {
                                            allIsRead[index] === 1
                                                ?
                                                <section className="msg_active"></section>
                                                :
                                                null
                                        }
                                    </section>
                                )
                            })
                        }
                    </section>
                    <a onClick={this.handleReadAllMsg.bind(this)} >全部已读</a>
                </section>
                <ul className="msg_list" >
                    {
                        msgList.map(item => {
                            return (
                                <li
                                    key={item.id}
                                    className={`msg_item ${item.isRead === 1 ? 'msg_not_read' : ''}`}
                                    onClick={this.handleReadMessageItem.bind(this, item.id)}
                                >
                                    {/* <section className="title flex flex-align-items-center flex-justify-content-space-between ">
                                        <span>{item.content}</span>
                                        {
                                            tabIndex === 1
                                                ?
                                                <i className="iconfont icon-double-arrow-left" ></i>
                                                :
                                                null
                                        }
                                    </section>
                                    <section className="content flex flex-align-items-center flex-justify-content-space-between">
                                        <p>{tabIndex === 0 && item.content}</p>
                                        <span>{item.createTime}</span>
                                    </section> */}

                                    <section
                                        className="title flex flex-align-items-center flex-justify-content-space-between "
                                        onClick={e => {
                                            this.handleReadMessageItem(item.id);
                                            tabIndex === 1 &&
                                                this.props.history.push(`/detail/${WROKS_TYPE[item.worksType]}/${item.worksId}?cid=${item.commentId}`);
                                        }}
                                    >
                                        <span>{item.content}</span>
                                        <span>{item.createTime}</span>
                                    </section>
                                    {/* <section className="content flex flex-align-items-center flex-justify-content-space-between">
                                        <p>{tabIndex === 0 && item.content}</p>
                                    </section> */}
                                </li>
                            )
                        })
                    }
                </ul>
                <Pagination
                    style={{ textAlign: 'right' }}
                    defaultCurrent={1}
                    pageSize={6}
                    total={total}
                    showSizeChanger={false}
                    onChange={p => {
                        this.getMessageList(msgTab[tabIndex].type, p)
                    }}
                />
            </section>
        )
    }
}

export default MyMsg;