import React, { Component } from 'react';
import { Pagination } from 'antd';
import axios from 'axios';
import moment from 'moment';
import qs from 'querystring';
import './index.less';
import { Classify, WorkCard, NotFound } from '../../../compoents/components';
import { GET_MY_DOWN } from '../../../utils/api';
import { WROKS_TYPE } from '../../../utils/config';


class MyDown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNum: 1,
            total: 1,
            worksList: []
        }
    }

    componentDidMount() {
        this.getMyDowmList(null)
    }

    // 获取下载列表
    async getMyDowmList(type, pageNum = 1) {
        const { data: ret } = await axios.post(GET_MY_DOWN, qs.stringify({
            type,
            pageNum,
            pageSize: 6
        }))
        console.log("结果=>", ret.data);
        this.setState({
            worksList: ret.data,
            total: ret.total,
            pageNum,
            worksType: type
        })
    }

    render() {
        const { total, worksList, worksType, pageNum } = this.state;
        return (
            <section id="MyDown">
                {/* 所属分类 */}
                <Classify onChange={v => this.getMyDowmList(v === 'null' || v === null ? null : parseInt(v))} />

                {
                    worksList.length > 0
                        ?
                        <section className="works_list flex flex-wrap" >
                            {
                                worksList.map(item => {
                                    return (
                                        <WorkCard
                                            key={item.id}
                                            id={item.pathIds}
                                            wId={item.id}
                                            listType={WROKS_TYPE[item.worksType]}
                                            title={item.title}
                                            coverImg={item.cover}
                                            avatar={item.headUrl}
                                            dateTime={moment(item.createTime).format('YYYY/MM/DD')}
                                            integral={item.coin}
                                            author={item.userName}
                                            isDel={item.isDel}
                                            fileSize={item.fileSizeB}
                                            btnType='get'
                                            detail={item}
                                            onClick={() => {
                                                this.props.history.push(`/detail/${WROKS_TYPE[item.worksType]}/${item.id}`)
                                            }}
                                            onConfirm={e => {
                                                this.getMyDowmList(worksType, pageNum)
                                            }}
                                        />
                                    )
                                })
                            }

                        </section>
                        :
                        <NotFound text="暂未找到相关下载" />
                }
                <Pagination
                    style={{ textAlign: 'right' }}
                    defaultCurrent={1}
                    pageSize={6}
                    total={total}
                    showSizeChanger={false}
                    onChange={p => {
                        this.getMyDowmList(worksType, p)
                    }}
                />
            </section>
        )
    }
}

export default MyDown;