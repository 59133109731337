import { UPLOAD_PATH } from "./api";

export const BASE_URL =  "http://yjkp.mempe.org.cn/kpgx" ;
// "http://yjkp.mempe.org.cn/kpgx" ;
    //    "http://124.221.64.110:8889/kpgx"// jml add

export const UPLOAD = BASE_URL + UPLOAD_PATH;

export const WROKS_TYPE = {
  1: "doc",
  2: "media",
  3: "other",
  4: "venue",
  doc: 1,
  media: 2,
  other: 3,
  venue: 4,
};

export const ACTIVITY_WORKS_TYPE = {
  // 4: 'media',
  // 5: 'doc',
  // 6: 'other',
  // 7: 'rmedia',

  10:'aqscf',
  11:'aqwh',
  12:'aqgl',
  13:'aqsccs',
  14:'other',
  // media: 4,
  // doc: 5,
  // other: 6,
  // rmedia: 7
}
