import React, { useState } from 'react';
import qs from 'querystring';
import axios from 'axios';
import './index.less';
import { SEND_COMMENT_ITEM } from '../../utils/api';

const QuickReply = props => {

    const { visible = false, onCancel, comment, onSuccess, worksId } = props;
    const [content, setContent] = useState('')

    const { replyCommentItem } = {

        // 回复评论
        async replyCommentItem(worksId, parentId) {

            const { data: ret } = await axios.post(SEND_COMMENT_ITEM, qs.stringify({
                worksId,
                content,
                parentId
            }))

            console.log(ret)

            onSuccess(ret)
        }
    }

    return (
        <section id="QuickReply" style={{ display: visible ? 'block' : 'none' }}>
            <section className="quick_user_name">
                {comment.loginName || ''}
                {
                    comment.userType === 3
                        ?
                        <img style={{ width: 100 }} src={require('../../assets/images/yes.png')} alt="" />
                        :
                        null
                }
            </section>
            <p className="quick_comment_container" >
                {comment.content}
            </p>
            <textarea onInput={e => setContent(e.target.value)} placeholder={`回复 ${comment.loginName}`}></textarea>
            <section className="quick_action_container flex flex-align-items-center flex-justify-content-center">
                <section onClick={() => onCancel(false)}>取消</section>
                <section onClick={e => replyCommentItem(worksId, comment.id)}>回复</section>
            </section>
        </section>
    )
}

export default QuickReply;