import React, { Component } from 'react';
import S from 'swiper';
import axios from 'axios';
import './index.less';
import "swiper/swiper-bundle.css";
import { GET_SWIPER_LIST } from '../../../utils/api';
import { withRouter } from 'react-router-dom';

let swiper;

class Swiper extends Component {

    constructor(props) {
        super(props)

        this.state = {
            swiperIndex: 0,
            swiperList: [
                {
                    coverUrl: ''
                }
            ]
        }
    }

    async getSwiperList() {
        const { data: ret } = await axios.get(GET_SWIPER_LIST);
        console.log(ret);
        this.setState({
            swiperList: ret.data || []
        })

        console.log(parseInt(Math.random() * this.state.swiperList.length))

        this.initSwiper()

        this.initVideoObject()
    }

    initVideoObject() {
        // const { swiperIndex } = this.state;
        document.querySelectorAll('video').forEach(item => {
            item.defaultMuted = true
            item.muted = true
            item.volume = 0
            // if (index === swiperIndex) {
            //     console.log('initVideoObject', index)
            //     item.load()
            //     item.play()
            // }
        })
    }

    initSwiper() {
        swiper = new S('.swiper-container', {
            autoplay: 2000,//自动滑动
            speed: 500,//自动滑动开始到结束的时间（单位ms）
            loop: true,//开启循环
            loopedSlides: 5,//在loop模式下使用slidesPerview:'auto',还需使用该参数设置所要用到的loop个数。
            slidesPerView: 'auto',//设置slider容器能够同时显示的slides数量(carousel模式)。另外，支持'auto'值，会根据容器container的宽度调整slides数目。
            effect: 'coverflow',//可以实现3D效果的轮播,
            pagination: '.swiper-pagination',//分页器
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            centeredSlides: true,//设定为true时，active slide会居中，而不是默认状态下的居左。
            coverflow: {
                rotate: 0,//slide做3d旋转时Y轴的旋转角度。默认50。
                stretch: 100,//每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
                depth: 150,//slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
                modifier: 1,//depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
                slideShadows: false,//开启slide阴影。默认 true。
            },
            on: {
                slideChange: e => {
                    const swiperIndex = e.realIndex;
                    // console.log(e.realIndex)
                    this.initVideoObject()
                    this.setState({
                        swiperIndex
                    })
                },
            },
        });
    }

    componentDidMount() {
        this.getSwiperList()
    }

    render() {
        const { swiperIndex, swiperList } = this.state;
        return (
            <div id="swiper_container">
                <img className="swiper_bg_img" src={swiperList[swiperIndex].coverUrl} alt="" />
                <div className="swiper-container">
                    <div className="swiper-wrapper flex flex-align-items-center">
                        {
                            swiperList.map((item, index) => {
                                return (
                                    <div className={`swiper-slide`} key={index} onClick={e => this.props.history.push(`/detail/media/${item.worksId}`)} >
                                        <section>
                                            <img src={item.coverUrl} alt="" style={{ display: swiperIndex === index ? 'none' : 'block' }} />
                                            {
                                                swiperIndex === index ?
                                                    <video poster={item.coverUrl} src={item.filePath} autoPlay muted></video>
                                                    :
                                                    null
                                            }
                                        </section>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* <div className="swiper-pagination"></div> */}
                    <div className="swiper-button-next swiper_button" onClick={
                        e => {
                            swiper.slideNext()
                        }
                    }></div>
                    <div className="swiper-button-prev swiper_button" onClick={
                        e => {
                            swiper.slidePrev()
                        }
                    }></div>
                </div>
                <div className="swiper_title" >
                    <div className="w">
                        {swiperList[swiperIndex].title}
                    </div>
                </div>
            </div >
        )
    }

}


export default withRouter(Swiper);
