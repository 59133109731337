import React, { useContext } from 'react';
import Provider from '../Provider';
import './index.less';

const Tab = props => {

    const {
        dataSource = [],
        onChange = (index) => console.log(index)
    } = props;

    const handleChangeIndex = index => {
        onChange(index);
    }

    const tabIndex = useContext(Provider)

    return (
        <section id="TabContainer">
            <ul className="flex flex-align-items-center flex-justify-content-center tab_container_list" >
                {
                    dataSource.map((item, index) => {
                        return (
                            <li
                                className={`tab_list_item ${tabIndex == index ? 'active' : ''}`}
                                key={index}
                                onClick={handleChangeIndex.bind(this, index)}
                            >
                                {item.title}
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    )

}

export default Tab;