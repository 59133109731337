import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import qs from 'querystring';
import { Pagination } from 'antd';
import { SEARCH_LIST } from '../../utils/api';
import { WROKS_TYPE } from '../../utils/config';
import { getRouteParams } from '../../utils/tools';
import { WorkCard, TeamCard, ExpertCard, NotFound } from '../../compoents/components';
import './index.less';

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 0,
            searchTab: [
                {
                    title: '全部',
                    type: null,
                    worksType: -1
                },
                {
                    title: '视频',
                    type: 'works1',
                    worksType: 2
                },
                {
                    title: '图文',
                    type: 'works2',
                    worksType: 1
                },
                {
                    title: '其他',
                    type: 'works3',
                    worksType: 3
                },
                {
                    title: '科普专家',
                    type: 'user3'
                },
                {
                    title: '融创团队',
                    type: 'user2'
                },
                {
                    title: '科普场馆',
                    type: 'works4',
                    worksType: 2
                }
            ],
            searchList: [],
            isLoading: false,
            total: 1
        }
    }

    componentDidMount() {
        const { key } = getRouteParams()
        this.searchResult(null, decodeURI(key))
    }

    async searchResult(type = null, content = '', pageNum = 1) {
        this.setState({
            isLoading: true
        })
        console.log('78978789',type,content,pageNum)
        const params = {
            type,
            content,
            pageNum,
            pageSize: 20
        }

        if (type === null) Reflect.deleteProperty(params, 'type');

        const { data: ret } = await axios.post(SEARCH_LIST, qs.stringify(params))

        console.log('搜索结果=>', ret.data);

        this.setState({
            isLoading: false
        })
        this.setState({
            searchList: ret.data || [],
            total: ret.total
        })
    }

    handleChangeTab(tabIndex) {
        console.log('tabIndex->', tabIndex);
        const { searchTab, tabIndex: index } = this.state;
        if (tabIndex === index) return;
        const { key } = getRouteParams()
        this.searchResult(searchTab[tabIndex].type, decodeURI(key))
        this.setState({
            tabIndex
        })
    }

    render() {
        const { key } = getRouteParams()
        const { searchTab, tabIndex, searchList, total, isLoading } = this.state;
        const searchRes = item => {
            if ((tabIndex >= 0 && tabIndex < 4) || tabIndex === 5) {
                return (
                    <WorkCard
                        key={item.id}
                        listType={tabIndex === 0 ? WROKS_TYPE[item.worksType] : WROKS_TYPE[searchTab[tabIndex].worksType]}
                        title={item.title}
                        coverImg={item.cover}
                        avatar={item.headUrl}
                        dateTime={moment(item.createTime).format('YYYY/MM/DD')}
                        integral={item.coin}
                        author={item.userName}
                        detail={item}
                        onClick={() => {
                            this.props.history.push(`/detail/${tabIndex === 0 ? WROKS_TYPE[item.worksType] : WROKS_TYPE[searchTab[tabIndex].worksType]}/${item.id}`)
                        }}
                    />
                )
            } else if (tabIndex === 3) {
                return <TeamCard key={item.id} item={item} />
            } else if (tabIndex === 4) {
                return <ExpertCard key={item.id} item={item} />
            }
        }
        console.log('searchTab',searchTab,tabIndex)
        return (
            <section id="Search">
                <section style={{ zIndex: isLoading ? 2 : -1 }} className="loading flex flex-align-items-center flex-justify-content-center">
                    <div></div>
                    <div></div>
                    <div></div>
                </section>
                {/* 搜索关键词 */}
                <section className="search_keywords">
                    <section className="w flex flex-align-items-center">
                        <h1>{`"${decodeURI(key)}"`}</h1>
                        <span>
                            <span>{total}</span>条
                        </span>
                    </section>
                </section>

                {/* 搜索结果页 */}
                <section className="search_success">
                    <section className="w">
                        {/* 搜索分类Tab */}
                        <ul className="search_tab_list flex flex-align-items-center">
                            {
                                searchTab.map((item, index) => {
                                    return <li onClick={this.handleChangeTab.bind(this, index)} className={`search_tab_item ${tabIndex === index ? 'active' : ''} `} key={index} >{item.title}</li>
                                })
                            }
                        </ul>

                        <div className={`search_list_container ${(tabIndex >= 0 && tabIndex < 4) || tabIndex === 5 || tabIndex === 4 ? 'flex flex-wrap' : ''}`} >
                            {
                                searchList.length > 0
                                    ?
                                    searchList.map(item => {
                                        return searchRes(item)
                                    })
                                    :
                                    <NotFound />
                                // <div className="search_notfound flex flex-align-items-center flex-direction-column flex-justify-content-center " style={{ color: '#999' }} >
                                //     <img style={{ width: 300 }} src={require('../../assets/images/search_not.svg')} alt="not_found" />
                                //     <p style={{ marginTop: 20 }}>未搜索到结果</p>
                                // </div>
                            }
                        </div>

                        <Pagination
                            defaultCurrent={1}
                            pageSize={16}
                            total={total}
                            style={{ textAlign: 'right', margin: '50px 0 10px' }}
                            onChange={pageNum => {
                                this.searchResult(searchTab[tabIndex].type, decodeURI(getRouteParams().key),pageNum)
                            }}
                        />

                    </section>
                </section>
            </section>
        )
    }
}

export default Search;