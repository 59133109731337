import React, { Component } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import './index.less';
import { withRouter } from 'react-router-dom';

class GoBack extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <section className="go_back w" >
                <span onClick={e => this.props.customGoBack ? this.props.customGoBack() : this.props.history.goBack()} >
                    <LeftOutlined />
                    <span>返回</span>
                </span>
            </section>
        )
    }
}

export default withRouter(GoBack);