import React from 'react';
import { notification } from 'antd';
import './index.less';
import axios from 'axios';
const Axios = axios.create()
const Text = props => {
    const downloadFile = async (filePath) => {
        const path = filePath.split('/');
        console.log('download url=>', filePath);
        const { data: ret, status: code } = await Axios.get(filePath, {
            responseType: 'blob',
        })
        if (code !== 200) return notification['error']({
            title: '应急科普共享平台',
            message: '资源加载错误，请重新下载'
        })
        const downloadUrl = URL.createObjectURL(ret);
        const _link = document.createElement('a');
        _link.download = path[path.length - 1];
        _link.href = downloadUrl;
        _link.style.display = 'none';
        document.body.append(_link);
        _link.click();
        URL.revokeObjectURL(ret);
    }
    return (
        <section id="Text" style={{ paddingTop: 52 }} className="w">
            <section className="Text_content_container">
                <section className="title">{props.title}</section>
                <section>发布：{props.author}</section>
                <section dangerouslySetInnerHTML={{ __html: props.html }}></section>
            </section>
            {
                props.fileList && props.fileList.length > 0 ?
                    (
                        <section className="Text_file_container">
                            附件：
                            {props.fileList.map(item => (
                                <section className="flex flex-align-items-center" key={item.id}>
                                    <img src={require('../../../../assets/images/activity_file_icon.png')} alt="附件" />
                                    <section>
                                        <h3>{item.fileName}</h3>
                                        <a
                                            onClick={downloadFile.bind(this, item.filePath)}
                                            style={{ textDecoration: 'underline' }}
                                        >下载</a>
                                    </section>
                                </section>
                            ))}
                        </section>
                    ) :
                    ''
            }
        </section>
    )
}

export default Text;