import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import { Pagination, message } from 'antd';
import { ExpertCard, Loading, NotFound, TopBanner } from '../../compoents/components';
import { ApplyExpert } from '../../compoents/components';
import './index.less';

// import ExpertBanner from '../../assets/images/expert_img.png';
import JOIN_EXPERT from '../../assets/images/banner.png';
import { GET_EXPERT_LIST } from '../../utils/api';

class Expert extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expertList: [],
            pageNum: 1,
            total: 1,
            isShowModal: false,
            isLoading: true
        }
    }

    componentDidMount() {
        this.getExpertList()
    }

    // 获取专家列表
    async getExpertList(pageNum = 1, pageSize = 8) {
        this.setState({ isLoading: true })
        const { data: ret } = await axios.post(GET_EXPERT_LIST, qs.stringify({
            userType: 3,
            pageNum,
            pageSize
        }))
        console.log(ret)
        this.setState({
            expertList: ret.data,
            pageNum,
            total: ret.total,
            isLoading: false
        })
    }

    render() {
        const { expertList, total, isShowModal, isLoading } = this.state;
        return (
            <section id="Expert">
                <Loading isLoading={isLoading} />
                <TopBanner coverImg={require('../../assets/images/expert_img.png')} title="科普专家" />
                <section className="expert_container w">
                    <img src={JOIN_EXPERT} alt="join" useMap="#JoinMap" onClick={e => {
                        window.localStorage.getItem('token') === null || window.localStorage.getItem('token') === '' ?
                            message.error('请先登录')
                            :
                            this.setState({
                                isShowModal: true
                            })
                    }} />
                    {/* <area shape="rect" id="JoinMap" coords="0,0,100,100" style={{ border: '1px solid red' }} /> */}

                    <section className="expert_list_container">
                        {
                            expertList.length > 0
                                ?
                                <ul className="expert_list flex flex-wrap">
                                    {
                                        expertList.map(item => {
                                            return (
                                                <li key={item.id} >
                                                    <ExpertCard item={item} />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                :
                                <NotFound text="暂无专家列表" />
                        }

                        <Pagination onChange={page => {
                            this.getExpertList(page)
                        }} defaultCurrent={1} pageSize={8} total={total} style={{ textAlign: 'right', marginTop: 54 }} />
                    </section>
                </section>

                <ApplyExpert visible={isShowModal} onCancel={e => this.setState({ isShowModal: e })} />

            </section>
        )
    }
}

export default Expert;