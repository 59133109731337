
// 登录
export const LOGIN = '/login';

// 首页轮播图
export const GET_SWIPER_LIST = '/home/getHomeList';

// 首页通知
export const GET_NOTICE_LIST = '/openNotice/getNotices';

// 首页作品推荐
export const GET_WORKS_RECO = '/works/getWorksRecommond';

// 科普力排行
export const GET_INTEGRAL_RANK = '/openUser/getProvinceOder';

// 作品热度paihng
export const GET_HOT_RANK = '/openWork/getWorkHotOrder';


// 获取征集活动入口id
export const GET_NOTICE = '/openNotice/getNotices';


// 专家列表
export const GET_EXPERT_LIST = '/openUser/getProfessorUser';

// 融创团队
export const GET_TEAM_LIST = '/openUser/getEnterpriseUser';

// 作品列表 作品类别 ： 1-图文，2-视频，3-其他
export const GET_WORKS_LIST = '/works/getWorksVo';

// 获取作品形式
export const GET_CLASSIFY_LIST = '/openWork/getClassifys';

// 获取作品分类
export const GET_WORKS_CLASSIFY = '/openWork/getIndustrys';

// 通用上传
export const UPLOAD_PATH = '/cos/upload';

// 获取用户信息 userId
export const GET_USER_INFO = '/openUser/getUserInfoById';

// 通过token获取用户信息
export const GET_USER_INFO_TOKEN = '/openUser/getUserInfo';

// 获取指定用户作品
export const GET_USER_WORKS = '/openUser/getUserWorksById';

// 获取作品详情
export const GET_WORKS_DETAIL = '/openWork/getWorkDetail';

// 作品点赞
export const HANDLE_LIKE_WORKS = '/workPraise/savaWorkPraise';

// 取消作品点赞
export const HANDLE_DELETE_LIKE = '/workPraise/deleteWorkPraise';

// 收藏作品
export const HANDLE_COLL_WORKS = '/openWork/savaWorksCollect';

// 取消收藏
export const HANDLE_COLL_DELETE = '/openWork/deleteWorksCollect';

// 获取评论列表
export const GET_COMMENT_LIST = '/workComment/getWorkComments';

// 发布评论
export const SEND_COMMENT_ITEM = '/workComment/savaWorkComment';

// 删除评论
export const DELETE_COMMENT_ITEM = '/workComment/deleteWorkCommentById';

// 获取公告详情
export const GET_NOTICE_DETAIL = '/openNotice/getNoticeById';

// 个人作品
export const GET_MY_WORKS = '/works/getWorksByStatus';

// 下载记录
export const GET_MY_DOWN = '/openUser/getUserDownWorks';

// 获取消息通知列表
export const GET_MY_MSG = '/info/getInfos';

// 消息通知全部已读
export const READ_ALL_MSG = '/info/editInfoRead';

// 获取友情链接
export const GET_FRIEND_LINK = '/link/getLinks';

// 查询是否有未读消息
export const GET_IS_READ = '/info/getInfoUnRead';

// 搜索
export const SEARCH_LIST = '/works/getSearch';

// 下载作品
export const DOWN_FILE = '/cos/download';

// 验证下载
export const CHECK_DOWN = '/works/checkDownload';

// 获取科普力消息记录
export const GET_INTE_LIST = '/openUser/getUserCoinRec';

// 我的收藏
export const GET_MY_COLL = '/works/getWorksByUserId';

// 申请专家认证
export const APPLY_EXPERT = '/openUser/savaProfessorInfo';

// 作品删除
export const DEL_WORKS_ITEM = '/works/deleteWorks';

// 草稿箱删除
export const DEL_WORKS_DRAFT = '/works/deleteDraft';

// 修改用户信息
export const EDIT_USER_INFO = '/openUser/editUserInfo';

// 被删除确定
export const DELETE_CONFIRM = '/openUser/delDownWorks';

// 被删除确定
export const CHECK_USER = '/openUser/checkUserExist';

// 获取省市县排行榜
export const GET_RANK_LISTS = '/openUser/getCoinCount';

// 注册
export const REGISTER_SUBMIT = '/user/register';

// 获取手机验证码
export const GET_MOBILE_CODE = '/smsCode';

// 获取省市县
export const GET_SHENGSHIXIAN_LIST = '/user/getProviceList';

// 获取相关附件
export const GET_FILE_LIST = '/works/allWorkPath';

// 注册专家
export const REGISTER_EXPERT = '/openUser/registerProfessorInfo';

// 每日首次登录获取科普力
export const TODAY_GET_INTE = '/openUser/getLoginCount';

// 专家认证
export const EXPERT_WORKS_AUTH = '/workComment/authWorks';

// 单条已读
export const MSG_ITEM_READ = '/info/editInfoRead';

// 重置密码
export const REST_PASSWORD = '/openUser/resetPwd';

// 获取快速回复
export const GET_QUICK_REPLY = '/workComment/getQuickComments';

// 专家点评
export const EXPERT_COMMENT = '/workComment/saveProfessorComment';

// 手机号/登录名修改
export const UPDATE_MOBILE_NAME = '/user/changeMobile';





// 获取活动详情
export const GET_ACTIVITY_DETAIL = '/works/getNoticeWorksById';

// 作品投票接口
export const HANDLE_WORKS_INTE = '/works/saveVote';

// 刷新投票、浏览数量
export const GET_WORKS_NUM = '/works/getNoticeWorksNumById';

// 作品类型
export const GET_ACTIVITE_CLASSIFY = '/works/getWorksType';

// 上传接口
export const UPLOAD_ACTIVITY_ITEM = '/works/saveCollectWorks';

// 专家打分
export const EXPERT_SAVE_SOURCE = '/works/saveWorkScore';

// 获取作品
export const GET_WORKS_A_LIST = '/works/getNoticeWorks';

// 获取未评分作品
export const GET_WORKS_A_NO_LIST = '/works/getWorksNotScore';

// 获取作品形式
export const GET_WORKS_X = '/openWork/getClassifys';

// 获取活动作品详情
export const GET_ACTIVEITY_WORKS_DETAIL = '/works/getNoticeWorksById';

// 页面生成二维码
export const GET_PAGE_QR_CODE = '/works/shareImg';