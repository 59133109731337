import React, { useState, useEffect } from 'react';
import Tab from './components/Tab';
import Text from './components/Text';
import TopBg from './components/TopBg';
import Provider from './components/Provider';
import Excellent from './components/Excellent';
import YX_Excellent from '../Activity/components/Yx_Excellent';
import axios from 'axios';
import qs from 'querystring';
import './index.less';
import { GET_NOTICE_DETAIL } from '../../utils/api';

const OldActivity = props => {

    const [tabIndex, setTabIndex] = useState(window.localStorage.getItem('ac_tabIndex') || 0);
    const [notice, setNotice] = useState({});

    const tabList = [
        {
            title: '活动简介'
        },
        {
            title: '参赛作品'
        }/*,
        {
            title: '优秀作品'
        }*/
    ]

    const getNoticeContent = async (Id) => {
        const { data: ret } = await axios.post(GET_NOTICE_DETAIL, qs.stringify({
            Id
        }));
        setNotice(ret.data)
    }

    useEffect(() => {
        getNoticeContent(props.match.params.id)
    }, [])

    const renderContent = () => {
        const renderMap = {
            0: <Text title={notice.title} author={notice.createTime} html={notice.content} fileList={notice.list} />,
            1: <Excellent />,
            2: <YX_Excellent/>
        }
        return renderMap[tabIndex]
    }

    return (
        <section id="Activity">
            {/* top background image */}
            <TopBg />
            {/* Tab */}
            <Provider.Provider value={tabIndex} >
                <Tab dataSource={tabList} onChange={i => {
                    if (i != 1) {
                        window.localStorage.setItem('ac_excellent_id', 0);
                    }
                    window.localStorage.setItem('ac_tabIndex', i);
                    // tab切换，页码为1
                    window.localStorage.setItem('ac_page_num', 1);
                    setTabIndex(i)
                }} />
            </Provider.Provider>
            {/* Content */}
            {
                renderContent()
            }
        </section>
    )
}

export default OldActivity;

