import React, { Component } from 'react';
import { Modal } from 'antd';

class AppleKepu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            s: 60,
            mobile: undefined
        }
    }

    // 关闭modal
    handleCancelEvent() {
        this.props.onCancel(false)
        // this.refs.form.resetFields()
    }

    render() {
        const { visible = false } = this.props;
        // const { s, mobile } = this.state;
        return (
            <section id="ApplyExpert">
                <Modal
                    visible={visible}
                    title="应急管理系统机构（科普工作者）"
                    footer={null}
                    width="60%"
                    onCancel={this.handleCancelEvent.bind(this)}
                >

                    <section className="flex flex-direction-column flex-align-items-center flex-justify-content-center">
                        <img style={{ width: 168, height: 168 }} src={require('../../assets/images/register_kepu.png')} alt="kepu" />

                        <section style={{ textAlign: 'center', marginTop: 47 }}>
                            <p style={{ width: 546, color: '#999999', textAlign: 'left', textIndent: '2em', marginTop: 0, fontSize: 16 }}>
                                本平台已为各级应急管理机构预留账号，请各级应急管理部门下载并填写下方申请表，填好后扫描发送至yingjikepu@qq.com。审核通过后将激活各单位建立账号并电话通知您，您的默认账号、密码均为申请表内所填的手机号码。如有疑问，请致电010-64463422。
                            </p>
                            <span>
                                点击下载：<a href="https://kepu-1307893792.cos.ap-beijing.myqcloud.com/assets/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E8%B4%A6%E5%8F%B7%E5%88%9B%E5%BB%BA%E7%94%B3%E8%AF%B7%E8%A1%A8.docx" rel="noopener noreferrer" target="_bank" >应急管理系统机构（科普工作者）.docx</a>
                                {/*点击下载：<a href="https://kepu-1300652598.cos.ap-beijing.myqcloud.com/assets/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E8%B4%A6%E5%8F%B7%E5%88%9B%E5%BB%BA%E7%94%B3%E8%AF%B7%E8%A1%A8.docx" rel="noopener noreferrer" target="_bank" >应急管理系统机构（科普工作者）.docx</a>*/}
                            </span>
                        </section>
                    </section>

                </Modal>
            </section>
        )
    }
}

export default AppleKepu;