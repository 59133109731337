import React, { Component } from 'react';
import Rank from './Rank';
import Reco from './Reco';
import Guide from './Guide';
import Float from './Float';
import Swiper from './Swiper';
import Upload from './Upload';
import Notice from './Notice';
import MapRank from './MapRank';
import axios from 'axios';
import qs from 'querystring';
import './index.less';
import { isLoginEvent } from '../../utils/tools';
import { TODAY_GET_INTE, GET_NOTICE } from '../../utils/api';
import { notification } from 'antd';

class Home extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loginCount: 1,
            floatId: 0,
        }
    }

    componentDidMount() {
        if (isLoginEvent()) {
            this.checkCount()
            // this.autoTodayGetInte()
        }

        this.getFloatId();

        // 清空跳转缓存
        window.localStorage.setItem('ac_tabIndex', '');
        window.localStorage.setItem('ac_excellent_id', '');
        window.localStorage.setItem('filterInfo', '');
        window.localStorage.setItem('filterObjStr', '');
        window.localStorage.setItem('ac_page_num', '');

    }

    async getFloatId() {
        const params = { callUp: 3 };
        const { data: ret = {} } = await axios.post(GET_NOTICE, qs.stringify(params));

        this.setState({
            floatId: ret.data && ret.data.length != 0 ? ret.data[0].id : 0
        })
    }

    // 每次首次登录获取科普力提示
    async autoTodayGetInte() {
        const { data: ret } = await axios.post(TODAY_GET_INTE)
        console.log(ret)
        if (ret.data !== "") {
            // Modal.confirm({
            //     title: '每日首次登录提示',
            //     content: '恭喜你获得50科普力',
            //     okText: '确定',
            //     cancelText: '取消'
            // })
            notification['success']({
                message: '恭喜您获得50科普力'
            })
        }
    }

    onChangeUserInfo() {
        const data = JSON.parse(window.localStorage.getItem('userInfo'))
        // console.log(data)
        data.loginCount = 1
        window.localStorage.setItem('userInfo', JSON.stringify(data))
        this.setState({
            loginCount: 1
        })
    }

    checkCount() {
        const { loginCount } = JSON.parse(window.localStorage.getItem('userInfo'))
        this.setState({
            loginCount
        })
    }

    onFloatClick = () => {
        const { floatId } = this.state;
        console.log(floatId)
        if (floatId == 0) {
            notification['info']({
                message: '暂无活动信息'
            })

        } else {
            this.props.history.push(`/activity/detail/${this.state.floatId}`);
        }
    }

    render() {
        const { loginCount } = this.state;
        return (
            <section id="Home_container" >

                <Swiper />
                <Upload />
                <Notice />
                <Reco />
                <MapRank />
                <Rank />

                <Float onClick={this.onFloatClick} />
                <Guide onChangeUserInfo={this.onChangeUserInfo.bind(this)} isShow={!loginCount} count={loginCount} />
            </section>

        )
    }
}


export default Home;