
// 设置名称
export const setNavigationBarTitle = title => {
    document.title = `${title}-应急科普共享平台`;
}


// 是否登录
export const isLoginEvent = () => {
    return window.localStorage.getItem('token') !== null;
}

// 获取query params
export const getRouteParams = key => {
    let search = window.location.search.substr(1).split('&')
    if (search && search[0] === "") return {};
    let res = {}
    search.forEach(item => {
        let _r = new RegExp(`(.*)=(.*)`).exec(item)
        res[_r[1]] = _r[2]
    })
    return res;
}


export function debounce(fn, ms) {
    let timerId;
    return function () {
        timerId && clearTimeout(timerId)
        timerId = setTimeout(() => {
            console.log(this, arguments)
            fn.apply(this, arguments)
        }, ms)
    }
}

/**
 * @name 设置Storage
 * @param {string} type localStorage 本地存储 ｜ sessionStorage 会话存储
 * @param {string} key 
 * @param {any} val 
 */
export const setStorage = (type = 'localStorage', key, val) => {
    // const typeOf = Object.prototype.toString.call(val);
    // window[type].setItem(key, typeOf === '[object Object]' || typeOf === '[object Array]' ? JSON.stringify(val) : val);
    window[type].setItem(key, val)
}

/**
 * @name 获取Storage
 * @param {string} type localStorage 本地存储 ｜ sessionStorage 会话存储
 * @param {string} key 
 * @returns String
 */
export const getStorage = (type = 'localStorage', key) => {
    return JSON.parse(window[type].getItem(key));
}

// export const checkLogin = () => {
//     if (!isLoginEvent()) {
//         message.warning('请先登录')
//         this.props.store.setLogin(true)
//         return
//     }
// }