import React, { useEffect } from 'react';
import { Frame } from './compoents/components';
import { Route, Switch } from 'react-router-dom';
import { ROUTE_LIST } from './router/index';
import { BASE_URL } from './utils/config';
import { Provider } from 'mobx-react';
import store from './store';
import axios from 'axios';
import { message } from 'antd';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['token'] = window.localStorage.getItem('token') || '';

axios.interceptors.response.use(res => {
  let { data: ret } = res;
  if (ret.code === 603 || ret.code === 604) {
    message.warning('用户登录超时，请重新登录')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('userInfo')
    window.location.reload()
  }
  return res
}, async err => {
  if (err.response) {
    let status = err.response.status
    if (status === 603 || status === 604) {
      await message.warning('用户登录超时，请重新登录')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userInfo')
      window.location.reload()
    }
  }

  return Promise.resolve(err)
})

function App() {

  useEffect(() => {

    // let element = document.createElement('checkDevTools');

    // console.log(element)

    // Object.defineProperty(element, 'id', {
    //   get: function () {
    //     /* TODO */
    //     return window.location.href = 'about:blank'
    //   }
    // });
  }, [])

  return (
    <Provider store={store} >
      <Frame>
        {/* <Router> */}
        <Switch>
          {
            ROUTE_LIST.map(item => <Route key={item.path} path={item.path} exact={item.excat} render={routerProps => <item.component {...routerProps} />} />)
          }
        </Switch>
        {/* </Router> */}
      </Frame>
    </Provider>

  );
}

export default App;
