import React from 'react';
import './index.less';

function NotFound(props) {
    const { text = '未搜索到结果' } = props;
    return (
        <div className="search_notfound flex flex-align-items-center flex-direction-column flex-justify-content-center " style={{ color: '#999' }} >
            <img style={{ width: 300 }} src={require('../../assets/images/search_not.svg')} alt="not_found" />
            <p style={{ marginTop: 20 }}>{text}</p>
        </div>
    )
}

export default NotFound;