import React from 'react';
import { withRouter } from 'react-router';
import { useLocalStore } from 'mobx-react';
import { CloudUploadOutlined } from '@ant-design/icons';
import './index.less';
import store from '../../../../store/index';
import { isLoginEvent } from "../../../../utils/tools";


const TopBg = props => {
    const localStore = useLocalStore(() => store);
    const fn = (e) => {
        if (isLoginEvent()) {
            props.history.push(`/activity/upload/${props.match.params.id}`)
        } else {
            localStore.setLogin(true)
        }
    }
    return (
        <section className="activity_container">
            <img src={require('../../../../assets/images/20220524133600.jpg')} alt="" className="activity_top_bg" useMap="#ActivityTopBg" />
           {/* <div className="activity_button"  style={{marginBottom:'80px'}} onClick={fn}>
                <div><CloudUploadOutlined style={{ marginRight: '10px' }} />上传</div>
            </div>*/}
        </section>
    )
}

export default withRouter(TopBg);