import React from 'react';
import { withRouter } from 'react-router-dom';
import { useObserver, useLocalStore } from 'mobx-react';
import { isLoginEvent } from '../../../utils/tools';
import store from '../../../store';
import './index.less';
import { notification } from 'antd';

const Upload = props => {

    const localStore = useLocalStore(() => store);

    const { push: goTo } = props.history;

    const { handleGoToPage } = {
        // 
        handleGoToPage(path) {
            if (!isLoginEvent()) {
                notification['warning']({
                    message: '您还未登录，请登录后继续操作'
                })
                localStore.setLogin(true)
                return;
            };
            goTo(path)
        }
    }

    return useObserver(() => (
        <div id="upload_container" className={props.className} >
            <section className="w">
                <img src={require('../../../assets/images/upload_index.png')} useMap="#uploadmap" alt="上传作品" />
                <map name="uploadmap" id="uploadmap">
                    <area shape="rect" coords="0,0,622,140" onClick={e => handleGoToPage('/upload/type')} alt="上传作品" />
                    <area shape="rect" coords="661,0,763,140" onClick={e => handleGoToPage('/upload/video/2/0')} alt="视频" />
                    <area shape="rect" coords="796,0,898,140" onClick={e => handleGoToPage('/upload/imgText/1/0')} alt="图文" />
                    <area shape="rect" coords="935,0,1034,140" onClick={e => handleGoToPage('/upload/imgText/3/0')} alt="其他" />
                    <area shape="rect" coords="1075,0,1200,140" onClick={e => handleGoToPage('/upload/video/4/0')} alt="科普场馆" />
                </map>
            </section>
        </div>
    ))
}

export default withRouter(Upload);
