import { Row, Col, Modal, Upload } from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { COS_CONFIG } from "../../../../utils/config";

import Cos from 'cos-js-sdk-v5';

const cos = new Cos({
  SecretId:  window.atob("QUtJREtUUTdZaWJEWWNQd0JBc0tWUDJPVEhvNDRuRlBJdUZK"),
  SecretKey:  window.atob('b01lZ1J2ODRqOEY1ZXd5aUJPS3pOVUx1Q0R4UmVhRmQ=')
})
// const baseUrl = "http://123.57.33.116:8080"; 
/**
 * 上传
 *
 * @class App
 * @extends {React.Component}
 */
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      previewVisible: false,
      previewImage: undefined,
      fileList: [],
    };
  }

  // Cos 上传
  handleCosUploadFile = async file => {
    let   Bucket = window.atob('a2VwdS0xMzA3ODkzNzky');
    let   Region  = window.atob('YXAtYmVpamluZw==');
    console.log(file)
    const data = await cos.putObject({
      Bucket,
      Region,
      StorageClass: 'STANDARD',
      Body: file,
      Key: `${new Date().getTime()}-${file.name}`,
    })
    // console.log('handleCosUploadFile', data)
    return data;
  }

  // 自定义上传
  customRequest = async options => {
    const { file, onSuccess } = options;
    console.log('customRequest', options)
    const { Location } = await this.handleCosUploadFile(file)
    const path = `https://${Location}`;
    const result = {
      path,
      size: file.size,
      fileName: file.name
    }
    onSuccess(result)
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.response,
      previewVisible: true,
    });
  };
  handleChange = ({ fileList, file }) => {
    if (this.props.getFileList) {
      this.props.getFileList(fileList);
    }
  };
  render() {
    const { fileList } = this.props;
    const props = {
      // action: UPLOAD,
      listType: "picture-card",
      fileList: fileList,
      onPreview: this.handlePreview,
      onChange: this.handleChange,
      customRequest: this.customRequest,
      // beforeUpload:this.beforeUpload,
      headers: {
        token: window.localStorage.getItem('token'),
      },
    };
    const { previewImage, previewVisible } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Row>
        <Col span={24}>
          <Upload {...props}>
            {fileList.length >= (this.props.length || 10) ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </Col>
      </Row>
    );
  }
}
