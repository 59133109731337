import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Form, Input, Button, Col, Row, notification } from 'antd';
import { GET_MOBILE_CODE, REST_PASSWORD } from '../../utils/api';
import qs from 'querystring';
import './index.less';
import CryptoJS from "crypto-js";

let timer;
const { Item } = Form;

const EditPwd = props => {

    // 1-科普工作者2-融创团队3-科普专家4-管理员5-普通群众',
    const [s, setS] = useState(60)  // 倒计时 s
    const [userType, setUserType] = useState(0) // 用户类型
    const [typeIndex, setTypeIndex] = useState(0) // 索引选择
    const [mobile, setMobile] = useState(undefined) // 手机号
    const [isShowModal, setShowModal] = useState(false) // 显示Modal

    const [form] = Form.useForm()

    const {
        handleUpdateState,
        handleUpdateUserPassword,
        handleGetMobileCode
    } = {
        /**
         * @name 修改状态
         * @param {number} type 角色类型
         */
        handleUpdateState(type, index) {
            setUserType(type)
            setTypeIndex(index)
            setShowModal(true)
        },

        /**
         * @name 修改密码
         * @param {object} params 
         */
        async handleUpdateUserPassword(params) {

            params['userType'] = userType
            console.log(qs.stringify(params)+"*******")

            const { data: ret } = await axios.post(REST_PASSWORD, qs.stringify(params))

            if (ret.code !== 200) return notification['error']({
                message: ret.msg
            })

            notification['success']({
                message: '密码修改成功！'
            })

            form.resetFields()
            clearInterval(timer)
            setShowModal(false)
            setS(60)
        },

        /**
         * @name 获取手机验证码
         * @param {number} phoneNumber 
         */
        async handleGetMobileCode(phoneNumber) {
            let _s = s
            if (mobile === undefined || mobile === '') return notification['warn']({
                message: '手机号不能为空'
            })
            const key = parseInt(phoneNumber.slice(0,5))+parseInt(phoneNumber.slice(6,11));

            const baseKey=  CryptoJS.enc.Utf8.parse(key+"yUmENGyUmEN");

            const keySec = CryptoJS.AES.encrypt( CryptoJS.enc.Utf8.parse(phoneNumber+Date.parse(new Date())),baseKey,{
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
            const { data: ret } = await axios.post(GET_MOBILE_CODE, { "phoneNumber": phoneNumber ,"keySec":keySec ,type:"chgpsd" })
            console.log(ret)

            if (ret.code !== 200) {
                return notification['warning']({
                    message: ret.msg
                })
            }else {
                notification['success']({
                    message: '验证码以短信的形式已发送至您的手机，请注意查收'
                })
            }
            clearInterval(timer)

            timer = setInterval(() => {
                console.log(s)
                if (_s <= 0) {
                    clearInterval(timer)
                    setS(60)
                } else {
                    setS(--_s)
                }
            }, 1000)
        }
    }

    const TYPE_LIST = [
        {
            title: '应急管理系统机构',
            desic: '科普工作者',
            icon: require('../../assets/images/register_kepu.png'),
            type: 1,
            onClick() {
                handleUpdateState(this.type, ...arguments)
            }
        },
        {
            title: '应急科普专家',
            desic: '科普专家',
            icon: require('../../assets/images/register_zhuanjia.png'),
            type: 3,
            onClick() {
                handleUpdateState(this.type, ...arguments)
            }
        },
        {
            title: '企事业单位/公众',
            desic: '',
            icon: require('../../assets/images/register_minzhong.png'),
            type: 5,
            onClick() {
                handleUpdateState(this.type, ...arguments)
            }
        },
        {
            title: '科普产品供应方',
            desic: '融创团队',
            icon: require('../../assets/images/register_gongying.png'),
            type: 2,
            onClick() {
                handleUpdateState(this.type, ...arguments)
            }
        },
    ]


    return (
        <section className="register_container flex-shrink">
            <section className="top flex flex-align-items-center flex-justify-content-space-between ">
                <h2>修改密码</h2>
                <span onClick={e => props.onBackLogin(0)} style={{ cursor: 'pointer' }} >返回登录</span>
            </section>

            <ul className="register_list flex flex-align-items-center flex-justify-content-space-between" >
                {
                    TYPE_LIST.map((item, index) => {
                        return (
                            <li
                                className="register_item flex-align-items-center flex-justify-content-center flex-direction-column"
                                onClick={() => item.onClick(index)}
                                key={index}
                            >
                                <img src={item.icon} alt={item.title} />
                                <section>{item.title}</section>
                                <span>{item.desic ? `（ ${item.desic} ）` : ''}</span>
                            </li>
                        )
                    })
                }
            </ul>

            <Modal
                title={`${TYPE_LIST[typeIndex].title}（修改密码）`}
                visible={isShowModal}
                onCancel={() => {
                    setShowModal(false)
                    form.resetFields()
                }}
                footer={null}
            >
                <Form
                    labelAlign="right"
                    labelCol={{ span: 4 }}
                    onFinish={e => handleUpdateUserPassword(e)}
                    form={form}
                >
                    <Item name="mobile" label="手机号" hasFeedback rules={[
                        {
                            required: true,
                            message: '请填写正确格式手机号',
                            pattern: /^1[3456789]\d{9}/ig
                        }
                    ]} >
                        <Input type="number" onChange={e => setMobile(e.target.value)} placeholder="请输入手机号" />
                    </Item>
                    <Row style={{ paddingLeft: 10 }}>
                        <Col>
                            <Item name="vCode" labelCol={{ span: 7 }} label="验证码" hasFeedback rules={[
                                {
                                    required: true
                                }
                            ]}>
                                <Input type="number" placeholder="请输入验证码" />
                            </Item>
                        </Col>
                        <Col style={{ marginLeft: 20 }}>
                            <Item>
                                <Button style={{ marginLeft: 20 }} disabled={s < 60} onClick={() => handleGetMobileCode(mobile)} >
                                    {
                                        s < 60
                                            ?
                                            `${s}s后重新获取`
                                            :
                                            '获取验证码'
                                    }
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                    <Item name="pwd" hasFeedback label="输入密码" rules={[
                        {
                            required: true,
                            message: '密码不能为空,必须包含字母数字以及特殊字符并且至少8位',
                            pattern: /(?=.*[a-z_])(?=.*\d)(?=.*[^a-z0-9_])[\S]{8,}/ig
                        }
                    ]} >
                        <Input type="password" placeholder="请输入密码" />
                    </Item>

                    <Item name="textPwd" hasFeedback dependencies={['pwd']} label="确认密码" rules={[
                        {
                            required: true,
                            message: '确认密码不能为空'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('pwd') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('确认密码与您输入的密码不一致');
                            },
                        }),
                    ]} >
                        <Input type="password" placeholder="请确认密码" />
                    </Item>

                    <Item style={{ textAlign: 'center' }}>
                        <Button htmlType="submit" type="primary">提交修改</Button>
                    </Item>
                </Form>
            </Modal>
        </section>
    )
}

export default EditPwd;