import React, { useState } from 'react';
import { UserOutlined, UnlockOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useObserver, useLocalStore } from 'mobx-react';
import { Form, Input, notification, message } from 'antd';
import axios from 'axios';
import qs from 'querystring';
import store from '../../store/index';
import './index.less';
import { LOGIN } from '../../utils/api';
import { withRouter } from 'react-router-dom';
import Register from '../Register';
import EditPwd from '../EditPwd';



const Login = props => {

    const [userName, setUserName] = useState('');
    const [password, setPassWord] = useState('');
    const [moveX, setMoveX] = useState(0);
    const localStore = useLocalStore(() => store);

    const { handleLoginEvent } = {
        async handleLoginEvent() {
            let loginName = userName;
            let pwd = password;
            if (loginName === null || loginName === '') return notification['warning']({
                title: '应急科普共享平台提示',
                message: '请输入手机号/专家账号'
            })
            if (pwd === null || pwd === '') return notification['warning']({
                title: '应急科普共享平台提示',
                message: '请输入密码'
            })
            const { data: ret } = await axios.post(LOGIN, qs.stringify({
                loginName,
                pwd
            }))
            console.log(ret)
            if (ret.code !== 200) return message.error(ret.msg);
            message.success('登录成功')
            window.localStorage.setItem('token', ret.data.token)
            window.localStorage.setItem('userInfo', JSON.stringify(ret.data.user))
            setTimeout(() => {
                window.location.reload()
            }, 100)
        }
    }

    return useObserver(() => (
        <section className={`login_container ${localStore.isLogin ? 'login_container_active' : ''} flex flex-align-items-center flex-justify-content-center`}>
            <section className="w">
                <section className="move flex flex-align-items-center" style={{ transform: `translateX(-${moveX}px)` }}>
                    <section className="login flex">
                        <section className="left flex-shrink flex flex-align-items-center flex-justify-content-center">
                            <img src={require('../../assets/images/login_img.png')} alt="login" />
                        </section>

                        <section className="right flex-shrink">
                            <section>用户登录</section>
                            <Form>
                                <Form.Item style={{ marginBottom: 34 }}>
                                    <Input
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                handleLoginEvent()
                                            }
                                        }}

                                        onInput={e => setUserName(e.target.value)} value={userName} prefix={<UserOutlined />} placeholder="手机号/专家账号" ></Input>
                                </Form.Item>
                                <Form.Item>
                                    <Input onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            handleLoginEvent()
                                        }
                                    }} onInput={e => setPassWord(e.target.value)} value={password} prefix={<UnlockOutlined />} placeholder="密码" type="password" ></Input>
                                </Form.Item>
                            </Form>
                            <section style={{ textAlign: 'right' }} >
                                <a onClick={e => setMoveX(2400)}>修改密码</a>
                            </section>
                            <section className="login_btn_container flex flex-algin-items-center flex-justify-content-space-between">
                                <section className="login_button" onClick={() => handleLoginEvent()} >
                                    登录
                                </section>
                                <section className="register_button" onClick={() => setMoveX(1200)} >
                                    注册
                                </section>
                            </section>
                            <section className="login_close">
                                <CloseCircleOutlined onClick={
                                    e => localStore.setLogin(false)
                                } style={{ fontSize: 25, color: '#666' }} />
                            </section>
                        </section>
                    </section>

                    <Register onBackLogin={e => setMoveX(e)} />

                    <EditPwd onBackLogin={e => setMoveX(e)} />
                </section>
            </section>
        </section>
    ))
}


export default withRouter(Login);