import React, { useState, useEffect } from 'react';
import { WROKS_TYPE as WORKS_TYPE } from '../../../utils/config';
import axios from 'axios';
import './index.less';
import { GET_WORKS_RECO } from '../../../utils/api';
import { withRouter } from 'react-router-dom';

const Reco = props => {

    const tabList = [
        {
            title: "视频推荐",
            type: "video",
            list: ["公益广告", "微电影"],
        },
        {
            title: "图文推荐",
            type: "doc",
            list: ["科普图书", "海报挂图"],
        },
        {
            title: "其他推荐",
            type: "outher",
            list: ["科普游戏", "科普游戏"],
        },
    ]
    const [tabIndex, setTabIndex] = useState(0)
    const [worksList, setWorksList] = useState([])
    const { push: goTo } = props.history;

    const { getWorksReco, handleChangeTab } = {
        async getWorksReco() {
            const { data: ret } = await axios.post(GET_WORKS_RECO);
            console.log("getWorksReco", ret);
            const _info = ret.data;
            const worksList = [_info[1], _info[0], _info[2]];
            setWorksList(worksList)
        },

        handleChangeTab(index) {
            setTabIndex(index)
        }
    }

    useEffect(() => {
        getWorksReco()
    }, [])

    return (
        <div className="reco_container">
            <section className="w">
                <section className="reco_title flex flex-align-items-center flex-justify-content-space-between">
                    <h2>作品推荐</h2>
                    <section
                        className="reco_right_top"
                        onClick={(e) =>
                            goTo(
                                `/content/list/${WORKS_TYPE[worksList[tabIndex].type]}`
                            )
                        }
                    >
                        更多作品 <i className="iconfont icon-double-arrow-left"></i>
                    </section>
                </section>

                <section className="reco_container flex flex-align-items-center">
                    <ul style={{ marginBottom: 0 }} className="reco_left">
                        {tabList.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={`reco_left_list flex flex-direction-column flex-justify-content-center ${tabIndex === index ? "active" : ""
                                        }`}
                                    onMouseOver={() => handleChangeTab(index)}
                                >
                                    <section className="reco_list_item_title flex flex-align-items-center">
                                        <span>{item.title}</span>
                                        {tabIndex === index && (
                                            <i className="iconfont icon-xiangyoujiantou"></i>
                                        )}
                                    </section>
                                    <section style={{ marginTop: 26 }}>
                                        {item.list.map((_item, _index) => (
                                            <span key={_index}>{_item}</span>
                                        ))}
                                    </section>
                                </li>
                            );
                        })}
                        <li
                            className="move_item"
                            style={{ top: tabIndex * 210, left: 0 }}
                        ></li>
                    </ul>
                    <section className="reco_right">
                        <ul className="flex flex-wrap">
                            {worksList[tabIndex] &&
                                worksList[tabIndex].list.slice(0, 9).map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            style={{
                                                background: `url("${item.cover}") no-repeat center / 100% 100%`,
                                            }}
                                            onClick={(e) =>
                                                goTo(
                                                    `/detail/${WORKS_TYPE[worksList[tabIndex]["type"]]
                                                    }/${item.id}`
                                                )
                                            }
                                        >
                                            <span>{item.title}</span>
                                            {tabIndex === 0 ? (
                                                <i className="iconfont icon-bofang"></i>
                                            ) : null}
                                        </li>
                                    );
                                })}
                        </ul>
                    </section>
                </section>
            </section>
        </div>
    )
}

export default withRouter(Reco);