import otherIcon from './otherIcon.svg'
import videoIcon from './videoIcon.svg'
import kpcgIcon from './kpcgIcon.svg'
import itIcon from './itIcon.svg'

export default {
    otherIcon,
    videoIcon,
    kpcgIcon,
    itIcon
}