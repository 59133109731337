import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import { Pagination } from 'antd';
import { TopBanner, TeamCard, NotFound, Loading } from '../../compoents/components';
import './index.less';

import TeamBanner from '../../assets/images/team_img.png';
import { GET_TEAM_LIST } from '../../utils/api';

class Team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamList: [],
            pageNum: 1,
            total: 1,
            isLoading: true
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getTeamList()
    }

    // 获取团队列表
    async getTeamList(pageNum = 1, pageSize = 4) {
        this.setState({ isLoading: true })
        const { data: ret } = await axios.post(GET_TEAM_LIST, qs.stringify({
            pageNum,
            pageSize,
            userType: 2
        }))
        console.log(ret)
        this.setState({
            teamList: ret.data,
            pageNum,
            total: ret.total,
            isLoading: false
        })
    }

    render() {
        const { teamList, total, isLoading } = this.state;
        return (
            <section id="Team">
                <TopBanner coverImg={TeamBanner} title="融创团队" />
                <Loading isLoading={isLoading} />
                <section className="team_container">
                    {
                        teamList.length > 0
                            ?
                            <ul className="team_list_container">
                                {
                                    teamList.map((item, index) => {
                                        return (
                                            <li key={index} >
                                                <TeamCard item={item} />
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            :
                            <NotFound text="暂无融创团队列表" />
                    }

                    <Pagination
                        defaultCurrent={1}
                        pageSize={4}
                        total={total}
                        className="w"
                        style={{ textAlign: 'right', marginBottom: 57 }}
                        onChange={p => {
                            this.getTeamList(p)
                        }}
                    />
                </section>
            </section>
        )
    }
}

export default Team;