import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import { GoBack } from '../../compoents/components';
import { Table } from 'antd';
import './index.less';
import { GET_HOT_RANK, GET_RANK_LISTS } from '../../utils/api';
import { WROKS_TYPE } from '../../utils/config';

class Rank extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabList: [
                {
                    title: '科普力排行'
                },
                {
                    title: '作品热度排行'
                }
            ],
            tabIndex: 0,
            integralList: [],
            worksList: []
        }
    }

    componentDidMount() {

        window.scrollTo(0, 0)

        this.getIntegralList()

        this.getWorksList()
    }

    // 获取科普力排行
    async getIntegralList() {
        const { data: ret } = await axios.get(GET_RANK_LISTS)
        console.log(ret)
        console.time('省市县遍历写入index用时')
        ret.data && ret.data.forEach((item, index) => {
            item.index = index + 1
            item.children && item.children.forEach((_item, _index) => {
                _item.index = `${index + 1}-${_index + 1}`
                _item.children && _item.children.forEach((_val, _i) => {
                    _val.index = `${index + 1}-${_index + 1}-${_i + 1}`
                })
            })
        })
        console.timeEnd('省市县遍历写入index用时')
        this.setState({
            integralList: ret.data
        })
    }

    // 获取作品排行
    async getWorksList() {
        const { data: ret } = await axios.post(GET_HOT_RANK, qs.stringify({
            limit: 34
        }))
        ret.data && ret.data.forEach((item, index) => item.index = index + 1)
        this.setState({
            worksList: ret.data
        })
    }

    // 切换Tab
    handleChangeTab(tabIndex) {
        this.setState({
            tabIndex
        })
    }

    render() {
        const { tabList, tabIndex, integralList, worksList } = this.state;
        const integralCloumns = [
            {
                title: '名次',
                align: 'center',
                dataIndex: 'index',
                key: 'index'
            },
            {
                title: '省份',
                align: 'center',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '科普力',
                align: 'center',
                dataIndex: 'value',
                key: 'value'
            },
        ]

        const worksColumns = [
            {
                title: '名次',
                align: 'center',
                dataIndex: 'index',
                key: 'index'
            },
            {
                title: '作品名称',
                align: 'center',
                render: item => {
                    return (
                        <span style={{ display: 'inline-block', width: 800, textAlign: 'center', margin: 0 }}>
                            {item.title}
                        </span>
                    )
                }
                // key: 'title',
                // dataIndex: 'title'
            },
            {
                title: '上传者',
                align: 'center',
                dataIndex: 'user_name',
                key: 'user_name'
            },
        ]

        return (
            <section id="Rank">
                <GoBack />
                <section className="rank_container w">
                    <section className="rank_title"></section>

                    <section className="rank_tab_container flex flex-align-items-center flex-justify-content-center">
                        {
                            tabList.map((item, index) => <section onClick={this.handleChangeTab.bind(this, index)} key={index} className={`${tabIndex === index ? 'active' : ''}`} >{item.title}</section>)
                        }
                    </section>

                    <Table
                        pagination={false}
                        rowKey="index"
                        columns={tabIndex === 0 ? integralCloumns : worksColumns}
                        dataSource={tabIndex === 0 ? integralList : worksList}
                        expandable={{
                            expandRowByClick: false,
                            expandIcon: ({ expanded }) => {
                                return <li></li>
                            },
                            onExpandedRowsChange: expandedRows => {
                                console.log(expandedRows)
                            }
                        }}
                        expandIcon={null}
                        onRow={r => {
                            return {
                                onClick: () => {
                                    console.log(r);
                                    tabIndex === 1
                                        ?
                                        this.props.history.push(`/detail/${WROKS_TYPE[r.works_type]}/${r.id}`)
                                        :
                                        this.props.history.push(`/rank/child/list?city=${r.name}&id=${r.id}`)
                                }
                            }
                        }}
                    ></Table>
                </section>
            </section>
        )
    }
}

export default Rank;