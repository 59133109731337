/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Radio,
  InputNumber,
  TreeSelect,
  Upload,
  Modal,
  notification
} from "antd";
import axios from "axios";
import qs from "querystring";
import { GET_WORKS_CLASSIFY } from "../../../utils/api";
import Uploadx from "../components/Upload";
import { GoBack } from "../../../compoents/components";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./index.less";
import { COS_CONFIG } from "../../../utils/config";

import Cos from 'cos-js-sdk-v5';

const cos = new Cos({
  SecretId:  window.atob("QUtJREtUUTdZaWJEWWNQd0JBc0tWUDJPVEhvNDRuRlBJdUZK"),
  SecretKey:  window.atob('b01lZ1J2ODRqOEY1ZXd5aUJPS3pOVUx1Q0R4UmVhRmQ=')
})


const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
};
const token = window.localStorage.getItem('token');
export default (props) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [fileList21, setFileList21] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [fileList4, setFileList4] = useState([]);
  const [preview, setPreview] = useState([]);
  const [classifyList, setClassifyList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [ifEmpower, setifEmpower] = useState(0)
  const [projectFile, setProjectFile] = useState(''); // 工程文件
  const [projectFileSize, setProjectFileSize] = useState(0) // 工程文件大小
  const [cover, setCover] = useState('');
  // 判断保存和发布
  const [isRelease, setIsRelease] = useState(false);


  const type = props.match.params.type;
  const id = props.match.params.id;
  const [form] = Form.useForm();

  // console.log('图文', id);


  // Cos 上传
  const handleCosUploadFile = async (file, onProgress = p => { }) => {
    let   Bucket = window.atob('a2VwdS0xMzA3ODkzNzky');
    let   Region  = window.atob('YXAtYmVpamluZw==');
    console.log(file)
    const data = await cos.putObject({
      Bucket,
      Region,
      StorageClass: 'STANDARD',
      Body: file,
      Key: `${new Date().getTime()}-${file.name}`,
      onProgress
    })
    // console.log('handleCosUploadFile', data)
    return data;
  }

  // 自定义上传
  const customRequest = async options => {
    const { file, onSuccess, onProgress } = options;
    console.log('customRequest', options)
    const { Location } = await handleCosUploadFile(file, p => {
      let percent = Math.floor((p.loaded / p.total) * 100)
      console.log('progress', percent)
      onProgress({ percent })
    })
    const path = `https://${Location}`;
    const result = {
      path,
      size: file.size,
      fileName: file.name
    }
    onSuccess(result)
  }


  const uploadButton = (
    <div>
      {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // 获取领域
  // 获取作品形式
  const getClassifyList = async () => {
    const { data: ret } = await axios.post(
      "/openWork/getClassifys",
      qs.stringify({
        type,
      })
    );
    setClassifyList(ret.data);
  };
  // 获取作品分类
  const getWroksClassify = async () => {
    const { data: ret } = await axios.post(
      GET_WORKS_CLASSIFY,
      qs.stringify({
        type,
      })
    );
    let newData = [...ret.data];
    const changeChild = (arr) => {
      for (let i = 0; i < arr.length; i++) {
        let item = { ...arr[i] };
        item.value = item.id;
        item.title = item.industryName || item.name;
        if (item.industryName) {
          item.name = item.industryName;
        }
        if (item.childList && item.childList.length) {
          changeChild(item.childList);
          item.children = item.childList;
        } else {
          item.children = null;
        }
        arr[i] = item;
      }
    };
    changeChild(newData);
    setFilterList(newData);
  };
  useEffect(() => {
    getClassifyList();
    getWroksClassify();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (id > 0) {
      getList();
    }
  }, [id]);

  // 获取列表
  const getList = () => {
    axios
      .get(`/works/getWorksById?id=${id}`, {
        headers: {
          token: token,
        },
      })
      .then((ret) => {
        if (ret.data.code === 200) {
          let item = ret.data.data;
          form.setFieldsValue({
            title: item.title,
            cover: item.cover,
            classifyId: item.classifyId,
            industryId: item.industryId,
            ifEmpower: item.ifEmpower,
            coin: item.coin,
            worksDesc: item.worksDesc,
          });
          setifEmpower(parseInt(item.ifEmpower))
          item.cover &&
            setFileList([
              { uid: -100, name: "xxx.png", status: "done", url: item.cover },
            ]);
          if (item.preview) {
            setPreview([item.preview]);
            setFileList3([
              { uid: -100, name: "xxx.png", status: "done", url: item.preview },
            ]);
          }
          if (item.list && item.list.length) {
            let newArr = [];
            item.list.forEach((e, index) => {
              let p = { uid: -100 + index, name: e.fileName || '文件', status: "done", url: e.filePath }
              newArr.push(p)
            })

            setFileList21(newArr);
            setFileList2(item.list);
          }
        }
      });
  };
  const getFileList = (list) => {
    let photoUrlsArr = [];
    let fileName = [];
    list.forEach((item) => {
      if (item.status === "done") {
        if (item.response) {
          photoUrlsArr.push(item.response.path);
          fileName.push(item.response.path);
        }
      }
    });

    setFileList(list);
    form.setFieldsValue({
      cover: photoUrlsArr.join(","),
    });
  };
  const postSubmit = (parame) => {
    fileList2.forEach(item => {
      item.coin = item.coin ? item.coin : 100
    })

    form.validateFields().then((values) => {

      if (cover=='') {
        notification['error']({
          message: '应急科普共享平台提示',
          description: "请选择封面"
        })
        return;
      }
      if (parame.status == 1) {

        if (fileList4.length == 0) {
          notification['error']({
            message: '应急科普共享平台提示',
            description: "请选择作品"
          })
          return;
        }

        if (ifEmpower == 1 && !projectFile) {
          notification['error']({
            message: '应急科普共享平台提示',
            description: "请选择工程文件"
          })
          return;
        }


      }

      const SAVE_PARAMS = {
        id,
        ...values,
        ...parame,
        ifEmpower,
        files: JSON.stringify(fileList4),
        preview: preview[0],
        cover: cover,
        worksType: type,
        projectFile,
        projectFileSize,
      };
      (!id || parseInt(id) === 0) && Reflect.deleteProperty(SAVE_PARAMS, 'id');
      axios
        .post(
          "/works/saveWorks",
          qs.stringify(SAVE_PARAMS),
          {
            headers: {
              token: token,
            },
          }
        )
        .then((ret) => {
          if (ret.data.code !== 200) {
            notification['error']({
              message: '应急科普共享平台提示',
              description: ret.data.msg
            })
            return
          }
          notification['info']({
            message: '应急科普共享平台提示',
            description: parame.status === '0' ? '操作成功，已保存至您的草稿箱中' : '上传成功'
          })
          props.history.goBack();
        });
    });
  };
  const checkTitle=(rule, value,callback)=>{
  console.log("title*******",value)
    const SAVE_PARAMS = {
      id,
      title:value
    };
    axios.post(
            "/works/checkWorks",
            qs.stringify(SAVE_PARAMS),
            {
              headers: {
                token: token,
              },
            }
        )
        .then(res=>{
          console.log("rsp*****",res)
          if(res.data.code === 500){
            notification['error']({
              message: '应急科普共享平台提示',
              description: res.data.msg
            })
            return
          }else{
            callback()
          }
        })
  };




  const save = () => {
    setIsRelease(false);
    setTimeout(() => {
      postSubmit({ status: "0" });
    }, 200);
  };
  const submit = () => {
    setIsRelease(true);
    setTimeout(() => {
      postSubmit({ status: "1" });
    }, 200);
  };
  const close = () => {
    props.history.goBack();
  };
  const uploadprops = {
    name: "file",
    multiple: false,
    directory: false,
    headers: {
      token: token,
    },
    fileList: fileList21,
    customRequest,
    onChange(info) {
      console.log('工程文件change=>', info);
      let photoUrlsArr = [];
      info.fileList.forEach((item) => {
        if (item.status === "done" && item.response) {
          const { name: workName, path: filePath, fileName, size: fileSize } = item.response;
          let p = {
            workName,
            filePath,
            fileName,
            fileSize,
          };
          photoUrlsArr.push(p);
          setProjectFile(filePath)
          setProjectFileSize(fileSize)
        }
      });
      setFileList21(info.fileList);
      setFileList2(photoUrlsArr);
    },
  };
  const uploadprops3 = {
    name: "file",
    customRequest,
    listType: "picture-card",
    fileList: fileList3,
    beforeUpload: file => {
      return new Promise((resolve, reject) => {
        const fileTypeArr = [
          'image/jpg',
          'image/jpeg',
          'image/png',
          'application/msword', // doc
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
          'application/vnd.openxmlformats-officedocument.presentationml.presentation', // ppt
          'application/pdf' // pdf

        ];
        if (type == 1 && !fileTypeArr.includes(file.type)) {
          notification['error']({
            message: '应急科普共享平台提示',
            description: "请上传jpg、png、doc、ppt、pdf格式文件"
          })
          return reject(Upload.LIST_IGNORE);
        }
        return resolve(true);
      });
    },
    headers: {
      token: token,
    },
    onChange(info) {
      // console.log('info', info)

      // 如果当前作品是图片 则封面直接取当前这张图作为封面

      info.file.status === 'done'
        &&
        /image/ig.test(info.file.type)
        &&
        setFileList([
          { uid: -100, name: "xxx.png", status: "done", url: info.file.response.path },
        ]);

      let photoUrlsArr = [];
      info.fileList.forEach((item) => {
        if (item.status === "done") {
          if (item.response) {
            const { name: workName, path: filePath, fileName, size: fileSize } = item.response;
            let p = {
              workName,
              filePath,
              fileName,
              fileSize,
            };
            photoUrlsArr.push(p);
          }
        }
      });
      setPreview(photoUrlsArr.map(item => item.filePath));
      setFileList4(photoUrlsArr)
      setFileList3(info.fileList);
    },
  };

  const validateMessages = {
    required: '请输入${label}!'
  };
  return (
    <div className="itCon">
      <GoBack />
      <Form form={form} validateMessages={validateMessages}>
        <Row>
          <Col span={24}>
            <FormItem {...formItemLayout} label="名称" name="title" rules={[
              {
                required: true,
              },{
                validator: checkTitle
              }
            ]}
            >
              <Input placeholder="请输入名称" />
            </FormItem>
          </Col>
          <FormItem name="cover">
            <Input type="hidden"></Input>
          </FormItem>
          <Col span={24}>
            <FormItem {...formItemLayout} label="上传作品">
              <Upload
                {...uploadprops3}

              // onPreview={this.handlePreview}
              // onChange={this.handleChange}
              >
                {fileList3.length >= 1 ? null : uploadButton}
              </Upload>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              {...formItemLayout}
              label="简介"
              name="worksDesc"
              rules={[{
                required: isRelease
              }]}
            >
              <TextArea rows={4} placeholder="请输入简介" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              {...formItemLayout}
              label="形式"
              name="classifyId"
              rules={[{
                required: isRelease
              }]}
            >
              <Select
                placeholder="请选择"
                allowClear
                style={{ width: "200px" }}
              >
                {classifyList.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.classifyName}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              {...formItemLayout}
              label="领域"
              name="industryId"
              rules={[{
                required: isRelease
              }]}
            >
              <TreeSelect
                style={{ width: "200px" }}
                // value={this.state.value}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={filterList}
                placeholder="请选择"
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              {...formItemLayout}
              label="设置科普力"
              name="coin"
              initialValue={100}
              rules={[{
                required: isRelease
              }]}
            >
              <InputNumber min={10} max={1000} />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              {...formItemLayout}
              label="授权"
              name="ifEmpower"
              initialValue={1}
              rules={[{
                required: isRelease
              }]}
            >
              <Radio.Group value={ifEmpower} onChange={e => {
                if (e.target.value) {
                  Modal.confirm({
                    title: '授权提示',
                    content: '完全授权是指上传者对上传的作品拥有全部版权，作品被他人下载后，可以用于二次创作等商业行为；',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                      setifEmpower(e.target.value)
                    }
                  })
                  return
                }
                setifEmpower(e.target.value)
              }} >
                <Radio value={1}>完全授权</Radio>
                <Radio value={0}>未完全授权</Radio>
              </Radio.Group>
              (完全授权可额外获取{type == 1 ? 30 : 50}科普力)
            </FormItem>
          </Col>
          {
            parseInt(ifEmpower)
              ?
              <Col span={24}>
                <FormItem {...formItemLayout} label="工程文件">
                  <Upload {...uploadprops}>
                    <Button icon={<UploadOutlined />}>上传文件</Button>
                    <span style={{ marginLeft: 20 }} >(上传工程文件可额外获取{type == 1 ? 30 : 50}科普力)</span>
                  </Upload>
                </FormItem>
              </Col>
              :
              null
          }

          <Col span={24}>
            <FormItem {...formItemLayout} label="封面">
              <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={customRequest}
                  beforeUpload={file => {
                    return new Promise((resolve, reject) => {
                      const fileTypeArr = [
                        'image/jpg',
                        'image/jpeg',
                        'image/png',
                        'image/gif'
                      ];
                      if (!fileTypeArr.includes(file.type)) {
                        notification['error']({
                          message: '应急科普共享平台提示',
                          description: "请上传jpg、png格式文件"
                        })
                        return reject(Upload.LIST_IGNORE);
                      }
                      setLoading(true)
                      return resolve(true);
                    });
                  }}
                  onChange={info => {

                    if (info.file.status === 'done') {
                      setLoading(false)
                      setCover(info.file.response.path)
                      form.setFieldsValue({
                        cover: info.file.response.path,
                      });
                    }
                  }}
              >
                {/*<span style={{ color: 'gray', fontSize: '14px' }}>自动获取封面不满意可以更换</span>*/}
                {cover ? <img src={cover} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
             {/* <Uploadx
                fileList={fileList}
                getFileList={(list) => {
                  getFileList(list);
                }}
                length={1}
              />*/}
              {/*<span style={{ color: 'gray', fontSize: '14px' }}>必须上传封面</span>*/}
            </FormItem>
          </Col>
        </Row>
        <div className="xbottom">
          <div className="xsave" onClick={save}>
            保存
          </div>
          <div className="xpublic" onClick={submit}>
            发布
          </div>
          <div className="xclose" onClick={close}>
            取消
          </div>
        </div>
      </Form>
    </div>
  );
};
