import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import moment from 'moment';
import './index.less';
import { Classify, NotFound, WorkCard } from '../../../compoents/components';
import { GET_MY_COLL, HANDLE_COLL_DELETE } from '../../../utils/api';
import { message, Pagination } from 'antd';
import { WROKS_TYPE } from '../../../utils/config';

class MyColl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collList: [],
            tabIndex: 0,
            total: 1,
            pageNum: 1,
            collTab: [
                {
                    title: '全部',
                    type: 0
                },
                {
                    title: '已购买',
                    type: 1
                },
                {
                    title: '未购买',
                    type: 2
                }
            ]
        }
    }

    componentDidMount() {
        this.getMyCollList()
    }

    // 我的收藏
    async getMyCollList(type = 0, pageNum = 1, wtype = null) {
        const { data: ret } = await axios.get(GET_MY_COLL, {
            params: {
                type,
                pageNum,
                pageSize: 6,
                wtype
            }
        })

        this.setState({
            collList: ret.data,
            total: ret.total,
            pageNum
        })
    }

    // 作品收藏 / 取消收藏
    async handleCollWorksAction(id) {
        const { collTab, tabIndex } = this.state;
        const { data: ret } = await axios.post(HANDLE_COLL_DELETE, qs.stringify({
            id
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        message.success('取消收藏成功');
        this.getMyCollList(collTab[tabIndex].type)
    }

    // 获取Tab
    handleChangeTab(index) {
        const { collTab } = this.state;
        this.getMyCollList(collTab[index].type)
        this.setState({
            tabIndex: index
        })
    }

    render() {

        const { collTab, tabIndex, collList, total, pageNum } = this.state;

        return (
            <section id="MyColl">
                <ul className="my_coll_tab flex flex-align-item-center " >
                    {
                        collTab.map((item, index) => {
                            return <li onClick={this.handleChangeTab.bind(this, index)} className={`${tabIndex === index ? 'active' : ''}`} key={index} >{item.title}</li>
                        })
                    }
                </ul>

                <Classify onChange={e => this.getMyCollList(collTab[tabIndex].type, 1, e === 'null' ? null : parseInt(e))} />

                {
                    collList.length > 0
                        ?
                        <section className="coll_list_container flex flex-wrap">
                            {
                                collList.map(item => {
                                    return (
                                        <WorkCard
                                            isColl={true}
                                            key={item.id}
                                            id={item.pathId}
                                            wId={item.id}
                                            listType={WROKS_TYPE[item.wtype]}
                                            title={item.title}
                                            coverImg={item.cover}
                                            avatar={item.headUrl}
                                            dateTime={moment(item.createTime).format('YYYY/MM/DD')}
                                            integral={item.coin}
                                            author={item.userName}
                                            isDel={item.isDel}
                                            fileSize={item.fileSizeB}
                                            btnType={item.status === 1 || tabIndex === 1 ? 'get' : 'down'}
                                            // 1 收藏 2 下载 3 作品中心 ？
                                            confirmDelType={1}
                                            detail={item}
                                            onClick={() => {
                                                this.props.history.push(`/detail/${WROKS_TYPE[item.wtype]}/${item.id}`)
                                            }}
                                            onCollWorks={this.handleCollWorksAction.bind(this, item.id)}
                                            onConfirm={e => {
                                                this.getMyCollList(collTab[tabIndex].type, pageNum)
                                            }}
                                        />
                                    )
                                })
                            }
                        </section>
                        :
                        <NotFound text="暂未找到相关收藏" />
                }

                <Pagination
                    style={{ textAlign: 'right' }}
                    defaultCurrent={1}
                    pageSize={6}
                    total={total}
                    onChange={p => {
                        this.getMyCollList(collTab[tabIndex].type, p)
                    }}
                />
            </section>
        )
    }
}

export default MyColl;