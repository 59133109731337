import React, { useState, useEffect, useRef } from "react";
import { Spin,notification } from "antd";
import axios from 'axios';
import qs from 'querystring';
import moment from 'moment';

import Content from "./components/Content";
import { GET_WORKS_DETAIL ,GET_ACTIVEITY_WORKS_DETAIL,HANDLE_WORKS_INTE,GET_WORKS_NUM} from '../../utils/api';

import "./index.less";

const openDescLen = 40; // 简介折叠字数显示

const ShareDetail = props => {

	const [isActivePage, setIsActivePage] = useState(false)
	
	const [isLoad, setIsLoad] = useState(false);
	const [worksDetail, setWorksDetail] = useState({});

	const [viewInfo, setViewInfo] = useState({})

	const [isOpen, setIsOpen] = useState(false);
	const [curEpisode, setCurEpisode] = useState(0); //当前集

	// 文件类型 video[视频]| html-[ppt,doc等文件]| img[图片]|other[其他文件-展示封面]
	const [fileObj, setFileObj] = useState({
	});

	const videoRef = useRef(null)


	
	useEffect(() => {
		const { id } = props.match.params;
		if(isPc() === true){
			let url = null
			if(window.location.href.search("adetail") != -1){
				url = window.location.href.replace("/share/adetail", "/adetail");
				setIsActivePage(true)
			}else{
				url = window.location.href.replace("/share/detail", "/detail");
				setIsActivePage(false)
			}
			console.log('window.location.href',window.location.href)
			window.location.href=url
		}
		getWorksDetail(id);
	}, [])

	//投票事件

	const hanldeVoteEvent = async () => {
		const { id } = props.match.params;
        const { data: ret } = await axios.post(HANDLE_WORKS_INTE, qs.stringify({
            id
        }))


        if (ret.code !== 200) return notification['error']({
            message: ret.msg
        })

        const { data: nums } = await axios(GET_WORKS_NUM + '?id=' + id);
        // this.setState({
        //     viewInfo: {
        //         views: nums.data.worksHot,
        //         votes: nums.data.votes
        //     }
        // })
		setViewInfo({
			worksHot: nums.data.worksHot,
			votes: nums.data.votes
		})

        notification['success']({
            message: '投票成功'
        })
	}

	const isPc = () => {
		var userAgentInfo = navigator.userAgent;
		var Agents = ["Android", "iPhone",
			"SymbianOS", "Windows Phone",
			"iPad", "iPod"];
		var flag = true;
		for (var v = 0; v < Agents.length; v++) {
			if (userAgentInfo.indexOf(Agents[v]) > 0) {
				flag = false;
				break;
			}
		}
		return flag;
	}

	let ret = null
	// 获取作品详情
	const getWorksDetail = async (id) => {
		console.log(window.location.href.search("adetail") != -1)
		if(window.location.href.search("adetail") != -1){
			setIsActivePage(true)
			let data = await axios.get(GET_ACTIVEITY_WORKS_DETAIL,{ params: { id, source: 2 } });
			ret = data.data
		}else{
			setIsActivePage(false)
			let data = await axios.post(GET_WORKS_DETAIL, qs.stringify({
				id,
			}));
			ret = data.data
		}
		console.log(ret)
		//paramsData === 'detail' ?  urls = GET_WORKS_DETAIL : urls = GET_ACTIVEITY_WORKS_DETAIL
		setIsLoad(true);
		
		if (ret.data == null) {
			alert('空');
			return;
		}
		setWorksDetail(ret.data);
		if(ret.data.worksHot && ret.data.votes){
			setViewInfo({
				worksHot:ret.data.worksHot,
				votes:ret.data.votes
			})
		}

		console.log('后缀=>', pathSuffix);
		setIsLoad(false);

		if (ret.data.htmlPath) {
			console.log('html类型哦');
			setFileObj({
				type: 'html',
				html: ret.data.htmlPath,
				previewUrl: ret.data.fullHtmlPath
			})
			return;
		}
		console.log(ret.data)
		let fileItem = []
		if( ret.data.fileList){
			fileItem = ret.data.fileList.length != 0 ? ret.data.fileList[0] : {};
		}else{
			fileItem = ret.data.list.length != 0 ? ret.data.list[0] : {};
		}
		const pathStr = fileItem.path || ret.data.filePath || fileItem.filePath;
		const pathStrArr = pathStr ? pathStr.split('.') : [''];
		const pathSuffix = pathStrArr[pathStrArr.length - 1].toLowerCase();
		console.log(pathSuffix)
		if (['mp4', 'flv'].includes(pathSuffix)) {
			setFileObj({
				type: 'video',
				video: {
					hd: {
						play_url: pathStr,
						bitrate: 0,
						duration: 0,
						format: '',
						height: 426,
						size: 0,
						width: 758
					},
				},
				cover: ret.data.cover
			})
		} else if (['jpg', 'png', 'gif', 'jpeg'].includes(pathSuffix)) {
			setFileObj({
				type: 'image',
				image: pathStr
			})
		} else if (['mp3'].includes(pathSuffix)) {
			setFileObj({
				type: 'audio',
				cover: ret.data.cover,
				audio: pathStr
			})
		} else {
			setFileObj({
				type: 'other',
				cover: ret.data.cover
			})
		}
	}

	// 换集
	const episodeChange = (index, item) => {
		setCurEpisode(index);

		videoRef.current.changeEpisode(item);
	}

	return (
		<Spin spinning={isLoad} tip="加载中...">
			<div className="video-container">
				{fileObj.type ? <Content onRef={videoRef} data={fileObj}></Content> : ''}
			</div>
			<div className="video-infos">
				<div className="title title-text">{worksDetail.title}</div>
				<div className="desc">
					{
						worksDetail.worksDesc && worksDetail.worksDesc.length > openDescLen
							? isOpen ? worksDetail.worksDesc : worksDetail.worksDesc.substring(0, openDescLen)
							: worksDetail.worksDesc
					}
					{
						worksDetail.worksDesc && worksDetail.worksDesc.length > openDescLen
							? <span onClick={() => setIsOpen(!isOpen)}>{isOpen ? '收起' : '展开'}</span> : ''
					}
				</div>
				<div className="share-detail-operate">
					<div>已有 <b>{worksDetail['authCount'] || 0}</b> 名专家认证</div>
					<div>{moment(worksDetail.createTime).format('YYYY-MM-DD')}</div>
				</div>
				{
					fileObj.type == 'video' ? <div className="episode-container">
						<div className="title">集数</div>
						<div className="episode-list">
						{
								worksDetail['fileList'] && worksDetail['fileList'].map((item, index) => (
									<div
										className={curEpisode == index ? 'episode-active episode-view' : 'episode-view'}
										key={item.id}
										onClick={episodeChange.bind(this, index, item)}
									>{index + 1}</div>
								))
						}
						{
							worksDetail['list'] && worksDetail['list'].map((item, index) => (
								<div
									className={curEpisode == index ? 'episode-active episode-view' : 'episode-view'}
									key={item.id}
									onClick={episodeChange.bind(this, index, item)}
								>{index + 1}</div>
							))
						}
						</div>
					</div> : ''
				}
				<div className="share-detail-tips">
					形式：{worksDetail.clsList && worksDetail.clsList[0] && worksDetail.clsList[0].name}
				</div>
				<div className="share-detail-tips">
					文档格式：{/\.[\w+\d+|\w+]+$/.exec(worksDetail.filePath) && /\.[\w+\d+|\w+]+$/.exec(worksDetail.filePath)[0].replace(/\./, '').toLocaleUpperCase()}
				</div>
				
				{
					worksDetail.worksHot  ? <div className="share-detail-tips">浏览：{worksDetail  && viewInfo.worksHot}</div> : ''
				}

				{
					worksDetail.votes  ? <div className="share-detail-tips">投票：{worksDetail  && viewInfo.votes} 票</div> : ''
				}
				{/* {
					isActivePage === true ? 
					<section className="ad_right" onClick={hanldeVoteEvent}>
						<span>投票</span>
					</section>
					: null
				} */}
			
			</div>
		</Spin>
	)
}

export default ShareDetail;