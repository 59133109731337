import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import qs from 'querystring';
import './index.less';
import { GET_HOT_RANK } from '../../../utils/api';
import { WROKS_TYPE } from '../../../utils/config';

const Rank = props => {

    const [rankList, setRankList] = useState([])

    const { getRankList } = {
        async getRankList() {
            const { data: ret } = await axios.post(GET_HOT_RANK, qs.stringify({
                limit: 11
            }))
            setRankList(ret.data || [])
        }
    }

    useEffect(() => {
        getRankList()
    }, [])

    return (
        <div id="rank_container">
            <section className="w">
                <section className="rank_title">
                    作品排行
                </section>


                {/* 前三名 */}
                <ul className="rank_top_three flex flex-align-items-center">
                    {
                        rankList.slice(0, 3).map((item, index) => {
                            return (
                                <li key={item.id} onClick={e => props.history.push(`/detail/${WROKS_TYPE[item.works_type]}/${item.id}`)}  >
                                    <section className="top flex flex-align-items-end">
                                        <section className="left">
                                            {index + 1}
                                        </section>
                                        <section className="right">
                                            <img src={item.cover} alt={item.title} />
                                        </section>
                                    </section>
                                    <section className="bottom">
                                        <section className="odd-overflow">{item.title}</section>
                                        <p className="odd-overflow">{item.works_desc}</p>
                                    </section>
                                </li>
                            )
                        })
                    }
                </ul>

                {/* 剩余排名 */}
                <ul className="rank_last_list flex flex-wrap">
                    {
                        rankList.slice(3).map((item, index) => {
                            return (
                                <li key={item.id} onClick={e => props.history.push(`/detail/${WROKS_TYPE[item.works_type]}/${item.id}`)} >
                                    <section className="top">
                                        <img src={item.cover} alt={item.title} />
                                    </section>

                                    <section className="bottom flex flex-align-items-center">
                                        <span>{index + 4}</span>
                                        <section>
                                            <section className="odd-overflow">{item.title}</section>
                                            <p className="odd-overflow" >{item.works_desc}</p>
                                        </section>
                                    </section>
                                </li>
                            )
                        })
                    }
                </ul>
            </section>

        </div>
    )
}

export default withRouter(Rank);
