import React, { Component } from 'react';
import { Select, Form } from 'antd';
import './index.less';

const { Option } = Select;

class Classify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            typeList: [
                {
                    title: '全部',
                    type: null
                },
                {
                    title: '视频',
                    type: 2
                },
                {
                    title: '图文',
                    type: 1
                },
                {
                    title: '其他',
                    type: 3
                },
                {
                    title: '科普展馆',
                    type: 4
                }
            ]
        }
    }

    render() {
        const { typeList } = this.state;
        const { margin = { margin: '30px 0 26px' } } = this.props
        return (
            // 分类筛选
            <Form style={margin}>
                <Form.Item label="所属分类" style={{ margin: 0 }} >
                    <Select style={{ width: 205 }} onChange={v => this.props.onChange(v)} defaultValue="null"  >
                        {
                            typeList.map(item => {
                                return (
                                    <Option
                                        key={item.type}
                                        value={`${item.type}`}
                                    >
                                        {item.title}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
            </Form>
        )
    }
}

export default Classify;