import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import './index.less';
import { GET_NOTICE_DETAIL } from '../../../utils/api';
import { GoBack } from '../../../compoents/components';

class NoticeDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            noticeContent: {}
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params
        window.scrollTo(0, 0)
        this.getNoticeContent(id)
    }

    // 获取公告详情
    async getNoticeContent(Id) {
        const { data: ret } = await axios.post(GET_NOTICE_DETAIL, qs.stringify({
            Id
        }))
        console.log(ret)
        this.setState({
            noticeContent: ret.data
        })
    }

    render() {
        const { noticeContent } = this.state;
        return (
            <section id="NoticeDetail" className="w" >
                <GoBack />
                <h2>{noticeContent.title}</h2>
                <section className="notice_infos">
                    <span>发布：应急管理部宣教中心</span>
                    <span>时间：{noticeContent.createTime}</span>
                </section>
                <p dangerouslySetInnerHTML={{ __html: noticeContent.content }} ></p>
            </section>
        )
    }
}

export default NoticeDetail;