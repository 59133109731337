import React, { Component } from 'react';
import axios from 'axios';
import Player from 'griffith';
import qs from 'querystring';
import moment from 'moment';
import './index.less';
import QRCode from 'qrcode.react'
import {
    CHECK_DOWN,
    SEND_COMMENT_ITEM,
    DELETE_COMMENT_ITEM,
    EXPERT_WORKS_AUTH,
    GET_ACTIVEITY_WORKS_DETAIL,
    EXPERT_SAVE_SOURCE,
    HANDLE_WORKS_INTE,
    GET_PAGE_QR_CODE,
    GET_WORKS_NUM,
    GET_FILE_LIST
} from '../../utils/api';
// import { ExclamationCircleOutlined } from '@ant-design/icons'
import { BASE_URL } from '../../utils/config';
import { GoBack, ApplyExpert, Loading, Progress } from '../../compoents/components';
import { getStorage, isLoginEvent } from '../../utils/tools';
import { message, notification, Input, Button, Modal, Popconfirm, Empty } from 'antd';
import { inject, observer } from 'mobx-react';
import {ExclamationCircleOutlined} from "@ant-design/icons";
// import AUTH_EXPERT from '../../assets/images/yes.png';
// import QuickReply from '../../compoents/QuickReply';

const Axios = axios.create()

// import { COS_CONFIG } from "../../utils/config";

// import Cos from 'cos-js-sdk-v5';

// const cos = new Cos({
//     SecretId: COS_CONFIG.SecretId,
//     SecretKey: COS_CONFIG.SecretKey
// })

// import { getRouteParams } from '../../utils/tools';

@inject('store') @observer
class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            worksDetail: {},
            sources: {
                hd: {
                    play_url: null,
                    bitrate: 0,
                    duration: 0,
                    format: '',
                    height: 426,
                    size: 0,
                    width: 758
                },
            },
            targetId: '',
            replyId: '',
            replyVisible: false,
            replyValue: '',
            commentTipVisible: false,
            downListVisible: false,
            isShowModal: false,
            docContent: { html: '', previewUrl: '' },
            isMouseDown: false,
            isMouseMove: false,
            mouseTop: 0,
            mouseLeft: 0,
            commentValue: '',
            fileList: [],
            projectFileInfo: {},
            isShowQuick: true,// 显示快速回复框
            quickReply: {}, // 快速回复框的信息
            commentList: [],
            commentTotal: 1,
            isGrade:0,
            worksIndex: 0,
            offsetTop: 0,
            progressCount: 0,
            progressShow: false,
            isLoading: true,
            score: undefined,
            imageDetail: '', // 图片内容链接
            musicUrl: '', // 音频文件链接
            pageType: '', //页面展示类型 html-ppt,doc等文件 img-jpg图片文件  video-视频 other-其他未知文件展示封面
            viewInfo: {
                views: 0,
                votes: 0
            },
            episodeNum: 1, //当前视频集数
        }
    }
    isPc(){
		var userAgentInfo = navigator.userAgent;
		var Agents = ["Android", "iPhone",
			"SymbianOS", "Windows Phone",
			"iPad", "iPod"];
		var flag = true;
		for (var v = 0; v < Agents.length; v++) {
			if (userAgentInfo.indexOf(Agents[v]) > 0) {
				flag = false;
				break;
			}
		}
		return flag;
	}

    componentDidMount() {
        if(this.isPc() === false){
            const url = window.location.href.replace("/adetail", "/share/adetail");
			window.location.href=url
        }
        // let id = getRouteParams('id')
        window.scrollTo(0, 0)
        let { id } = this.props.match.params;
        console.log('props',this.props.match.params.isDafen === 'true')
        // 获取详情
        this.getWorksDetail(id)


        // const { cid } = getRouteParams()
    }

    // 获取作品详情
    async getWorksDetail(id) {
        const { sources } = this.state;
        const { data: ret } = await axios.get(GET_ACTIVEITY_WORKS_DETAIL, { params: { id, source: 2 } })
        console.log('详情内容=>>>', ret);
        const fileObj = ret.data.list.length != 0 ? ret.data.list[0] : {};
        const pathStrArr = fileObj.filePath ? fileObj.filePath.split('.') : [''];

        const pathSuffix = pathStrArr[pathStrArr.length - 1].toLowerCase();
        console.log('后缀=>', pathSuffix);


        if (fileObj.htmlPath) {
            // this.getDocument(fileObj.htmlPath, /\/\w+\/\w+\/\d+\/\d+\/\d+\//.exec(fileObj.htmlPath));
            this.setState({
                docContent: {
                    html: fileObj.htmlPath,
                    previewUrl: fileObj.fullHtmlPath
                },
                pageType: 'html'
            })
        } else if (['mp4', 'flv'].includes(pathSuffix)) {
            // 视频解析
            sources.hd.play_url = fileObj.filePath;
            this.setState({
                pageType: 'video',
                sources
            })
        } else if (['jpg', 'png', 'gif', 'jpeg'].includes(pathSuffix)) {
            this.setState({
                imageDetail: fileObj.filePath,
                pageType: 'img'
            })
        } else if (['mp3'].includes(pathSuffix)) {
            this.setState({
                imageDetail: ret.data.cover,
                musicUrl: fileObj.filePath,
                pageType: 'audio'
            })
        } else {
            this.setState({
                imageDetail: ret.data.cover,
                pageType: 'other'
            })
        }
        this.setState({
            worksDetail: ret.data,
            viewInfo: {
                views: ret.data.worksHot,
                votes: ret.data.votes
            },
            isLoading: false
        })
        console.log('页面判断类型=>', this.state.pageType);

    }

    // // 获取doc详情 -- 4.8 暂时屏蔽html改为图片链接
    // async getDocument(doc, path) {
    //     console.log(doc);
    //     const { data: ret } = await axios.get(doc);
    //     const filePath = `${BASE_URL}${path}`;
    //     console.log('ret====>', ret);
    //     // <img.*?src="(.*?)".*?\/?>
    //     if (!ret) {
    //         message.error('文件请求失败' + ret);
    //         return
    //     };
    //     // let docContent = ret.replace(/<img.*?src="(.*?)"/ig, `<img src=${filePath}$1`);
    //     let docContent = doc;
    //     this.setState({
    //         docContent
    //     })
    // }

    // 专家评分
    async handleExpertSource() {
        const { score } = this.state;
        let { id: worksId } = this.props.match.params;
        const { data: ret } = await axios.post(EXPERT_SAVE_SOURCE, qs.stringify({
            worksId,
            score
        }))

        if (ret.code !== 200) return notification['error']({
            message: ret.msg,
        })

        notification['success']({
            message: '评分成功'
        })

        this.setState({
            score: undefined,
            isGrade: 1
        })
    }

    // 投票
    async hanldeVoteEvent() {
        let { id } = this.props.match.params;
        const { data: ret } = await axios.post(HANDLE_WORKS_INTE, qs.stringify({
            id
        }))

        if (ret.code !== 200) return notification['error']({
            message: ret.msg
        })

        const { data: nums } = await axios(GET_WORKS_NUM + '?id=' + id);
        this.setState({
            viewInfo: {
                views: nums.data.worksHot,
                votes: nums.data.votes
            }
        })

        notification['success']({
            message: '投票成功'
        })

    }




    doShare = async () => {
        const url = window.location.href.replace("/adetail", "/share/adetail");
        // const { data: ret } = await axios.get(`${GET_PAGE_QR_CODE}?url=${url}`);
        // if (ret.code == 200) {
        console.log(url)
            Modal.info({
                title: '扫描二维码访问',
                content: <QRCode   id='qrcode' value={url}  size={250}
                    fgColor='#515151' style={{cursor:'pointer'}}
                    imageSettings={{   // 二维码中间的图片
                        src: url, //图片路径
                        height: 200,
                        width: 200,
                        excavate: true, // 中间图片所在的位置是否镂空
                    }}
                />,
                okText: '确定',
            })
        // } else {
        //     message.error('分享失败，请联系管理员');
        // }
    }

    // 检测如果用户是否已经选择 如果没选择则不弹出复制的弹窗
    checkSelection() {
        window.getSelection().toString() === '' && this.setState({ isMouseMove: false })
    }

    // 鼠标按下
    handleMouseDown(e) {
        this.checkSelection()
        this.setState({ isMouseDown: true })
    }

    // 鼠标移动
    handleMouseMove(e) {
        if (!this.state.isMouseDown) return;
        this.setState({ isMouseMove: true })
    }

    // 鼠标弹起
    handleMouseUp(e) {
        this.checkSelection()
        this.setState({ isMouseDown: false })
        let timer;
        clearTimeout(timer)
        this.setState({
            mouseLeft: e.nativeEvent.layerX / 2,
            mouseTop: e.nativeEvent.layerY - 50
        })
    }


    // 下载
    async checkDownload(worksId, coin, filePath, fileSize, type = 0) {
        const { progressShow } = this.state;
        if (progressShow) return;
        notification['info']({
            message: '应急科普共享平台提示',
            description: '正在进行安全校验...'
        })
        if (!isLoginEvent()) {
            notification['warning']({
                message: '应急科普共享平台提示',
                description: '检测您还未登录，请先登录进行下载'
            })
            this.props.store.setLogin(true)
            return
        }
        const { data: ret } = await axios.post(CHECK_DOWN, qs.stringify({
            worksId,
            coin,
            type
        }))
        if (ret.code !== 200) return notification['error']({
            message: '应急科普共享平台提示',
            description: ret.msg
        });
        notification['success']({
            message: '应急科普共享平台提示',
            description: '安全校验成功'
        })

        this.downLoadFile(filePath, fileSize)
    }

    // 下载列表弹框
    async downLoadFile(filePath, fileSize) {

        const path = filePath.split('/')

        const { data: ret, status: code } = await Axios.get(filePath, {
            responseType: 'blob',
            onDownloadProgress: progress => {
                // console.log('progress', progress, Math.floor((progress.loaded / fileSize) * 100))
                this.setState({
                    progressCount: Math.floor((progress.loaded / fileSize) * 100),
                    progressShow: true
                })
            }
        })

        if (code !== 200) return notification['error']({
            title: '应急科普共享平台',
            message: '资源加载错误，请重新下载'
        })

        // console.log(ret)

        const downloadUrl = URL.createObjectURL(ret);
        const _link = document.createElement('a');
        _link.download = path[path.length - 1]
        _link.href = downloadUrl;
        _link.style.display = 'none';
        document.body.append(_link);
        _link.click()
        URL.revokeObjectURL(ret)

        this.setState({
            progressShow: false
        })
        notification['success']({
            message: '应急科普共享平台提示',
            description: '资源加载成功，请选择保存文件目录'
        })

        // if (ret.code) {
        //     notification['error']({
        //         message: '应急科普共享平台提示',
        //         description: ret.msg
        //     })
        //     this.setState({
        //         progressShow: false
        //     })
        //     return
        // };
        // let _path = `${BASE_URL}${DOWN_FILE}?id=${id}`;
        // let _link = document.createElement('a');
        // _link.href = _path;
        // _link.style.display = 'none';
        // document.body.append(_link);
        // _link.click()
        // this.setState({
        //     progressShow: false
        // })
        // notification['success']({
        //     message: '应急科普共享平台提示',
        //     description: '资源加载成功，请选择保存文件目录'
        // })


    }

    // 图片加入水印
    // drawImageEvent(perview, logo = 'https://zxpx-1300652598.cos.ap-beijing.myqcloud.com/logo-yj.png') {
    //     return new Promise((resolve, rejects) => {
    //         let canvas = document.createElement('canvas');
    //         let ctx = canvas.getContext('2d');
    //         let img = new Image();
    //         let dataUrl = '';
    //         img.src = perview;
    //         img.setAttribute('crossOrigin', 'anonymous');
    //         img.onload = () => {
    //             canvas.width = img.width
    //             canvas.height = img.height
    //             ctx.drawImage(img, 0, 0)
    //             console.log(img.width, img.height)
    //             const img_2 = new Image();
    //             img_2.src = logo;
    //             img_2.setAttribute('crossOrigin', 'anonymous');
    //             img_2.onload = () => {
    //                 // console.log('img2', img_2.width)
    //                 ctx.drawImage(img_2, 0, 0, img_2.width, img_2.height)
    //                 dataUrl = canvas.toDataURL('image/png')
    //                 // console.log(dataUrl)
    //                 resolve(dataUrl)
    //             }
    //         }
    //     })
    // }


    // 专家作品认证
    async handleExpertWorkAuth(worksId) {
        const { worksDetail } = this.state;
        const { data: ret } = await axios.post(EXPERT_WORKS_AUTH, qs.stringify({ worksId }))
        if (ret.code !== 200) return notification['error']({
            message: ret.msg
        })
        notification['success']({
            message: ret.msg
        })

        if (worksDetail['isAuth'] <= 0) {

            worksDetail['isAuth'] = 1;
            ++worksDetail['authCount'];
        }

        this.setState({
            worksDetail
        })

    }

    // 回复
    async replySure(worksId) {
        // this.setState({
        //     replyVisible: false
        // })
        const { replyValue: content } = this.state;
        if (content.length <= 0 || content === null) return message.warning('回复内容不能为空');
        const { data: ret } = await axios.post(SEND_COMMENT_ITEM, qs.stringify({
            worksId,
            content,
            parentId: this.state.targetId
        }))
        if (ret.code !== 200) return message.error(ret.msg || '回复失败，请联系管理员');
        this.setState({
            commentValue: '',
            targetId: '',
            replyId: '',
            replyValue: '',
            replyVisible: false,
        })
        message.success(ret.msg);
        this.getWorksCommentList(worksId)
    }
// 获取相关附件
    async getFileList(worksId) {
        const { data: ret } = await axios.get(GET_FILE_LIST, { params: { worksId } })
        if (ret== null ) return notification['error']({
            title: '应急科普共享平台',
            message: '无相关资源'
        })
        this.setState({
            isLoading:false,
            projectFileInfo:false
        })
        console.log(ret+"***********")
        const { list: fileList, id, projectFile, projectFileSize } = ret.data[0]
        console.log(ret)
        this.setState({
            fileList,
            projectFileInfo: {
                id,
                projectFile,
                projectFileSize
            }
        })
        console.log('fileList', fileList)
    }
    // 删除回复
    async relayDel(worksId, id, userId) {
        const { id: selfId } = JSON.parse(window.localStorage.getItem('userInfo'))
        if (userId !== selfId) return message.error('不能删除别人的回复！')
        const { data: ret } = await axios.post(DELETE_COMMENT_ITEM, qs.stringify({
            worksId,
            id
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        this.setState({
            commentValue: ''
        })
        message.success(ret.msg);
        this.getWorksCommentList(worksId)
    }

    subCommentList = (item) => {
        let arr = []
        let subId = item.id
        const createSub = (item) => {
            // console.log('comment', item)
            let subdom = item.subComment.map((item1, index1) => {
                let fistname = item1.userName
                let lastname = item1.parentUserName || '用户'
                return (
                    <div key={index1}>
                        <div>
                            <span className="comment_username">{fistname}</span> 回复 <span className="comment_username">{lastname}</span> ：{item1.content} <span className="comment_handle" onClick={() => {
                                this.showReplyContent(subId, item1.id)
                            }}>
                                回复
                            </span>
                            {
                                item1.userId === (window.localStorage.getItem('userInfo') && parseInt(JSON.parse(window.localStorage.getItem('userInfo')).id))

                                    ?
                                    <Popconfirm
                                        title="确定要删除该评论吗？"
                                        onConfirm={() => [
                                            this.relayDel(item.worksId, item1.id, item1.userId)
                                        ]}
                                    >
                                        <span className="comment_handle">
                                            删除
                                        </span>
                                    </Popconfirm>
                                    :
                                    null
                            }

                        </div>
                    </div>
                )
            })

            arr.push(subdom)

            // console.log('item_comment', item, item.subComment)

            if (item.subComment[0].subComment && item.subComment[0].subComment.length > 0) {
                createSub(item.subComment[0])
            }

            // if (item.subComment && item.subComment.length > 0) {
            //     // console.log(item.subComment)
            //     createSub(item.subComment)
            // }

        }

        createSub(item)

        return (
            arr.map(_i => {
                return _i
            })
        )

    }

    // 预览全部
    previewDocFile(url) {
        window.open(url);
    }

    episodeChange(item, index) {
        if (this.state.episodeNum == index + 1) return;
        // let sources = this.state.sources;
        // sources.hd.play_url = item.filePath;
        this.setState({
            // sources,
            episodeNum: index + 1
        })

        document.querySelector('video').src = item.filePath
        document.querySelector('video').load();
        document.querySelector('video').play();
    }

    render() {
        const {
            worksDetail,
            sources,
            docContent,
            mouseTop,
            mouseLeft,
            isMouseMove,
            isMouseDown,
            isShowModal,
            isLoading,
            progressCount,
            progressShow,
            imageDetail,
            viewInfo,
            musicUrl,
            episodeNum,fileList,
        } = this.state;
        console.log(this.props.match.params.isDafen === 'true')
        // const getBreadText = type => {
        //     if (type === 'media') {
        //         return '视频'
        //     } else if (type === 'doc') {
        //         return '图文'
        //     } else {
        //         return '其他'
        //     }
        // }

        const getIcon = type => {
            if (type === 'media' || type === 'rmedia') {
                return <i className="iconfont icon-shipin" style={{ color: '#8C73CE', fontSize: 24 }} ></i>
            } else if (type === 'doc') {
                return <i className="iconfont icon-appmsg" style={{ color: '#8C73CE', fontSize: 24 }} ></i>
            } else {
                return <i className="iconfont icon-qita" style={{ color: '#8C73CE', fontSize: 24 }} ></i>
            }
        }

        const Content = () => {
            const { pageType } = this.state;
            if (pageType == 'video') {
                return (
                    <section className="detail_content_media">
                        <section>
                            {
                                sources.hd.play_url !== null
                                    ?
                                    <Player
                                        id={sources.hd.play_url}
                                        duration={0}
                                        cover={worksDetail.cover || worksDetail.worksUrl}
                                        sources={sources}
                                        initialObjectFit="cover"
                                        locale="zh-Hans"
                                    ></Player>
                                    :
                                    null
                            }
                        </section>
                        <div className="episode">
                            {worksDetail.list && worksDetail.list.map((item, index) => (
                                <div
                                    onClick={this.episodeChange.bind(this, item, index)}
                                    className={episodeNum == index + 1 ? 'selectEpisode' : ''}
                                >第{index + 1}集</div>
                            ))}
                        </div>
                    </section>
                )
            } else if (pageType == 'html' || pageType == 'img') {
                return (
                    // 图文
                    <section className="detail_content_doc"
                        onMouseDown={this.handleMouseDown.bind(this)}
                        onMouseMove={this.handleMouseMove.bind(this)}
                        onMouseUp={this.handleMouseUp.bind(this)}
                    >
                        {
                            pageType == 'html'
                                ?
                                // <div className="htmlContainer" dangerouslySetInnerHTML={{ __html: docContent }}></div>
                                <div className="htmlContainer">
                                    <img
                                        className='htmlContentImg'
                                        src={docContent.html}
                                    />
                                    {
                                        docContent.previewUrl ? <div
                                            className="previewMask"
                                            onClick={this.previewDocFile.bind(this, docContent.previewUrl)}
                                        ><div>查看全部</div></div> : null
                                    }
                                </div>
                                :
                                <div style={{ width: '100%', textAlign: 'center' }} >
                                    <img style={{ width: '100%' }} src={imageDetail} alt="perview" />
                                </div>
                        }
                        <div className="detail_content_select" style={{ top: mouseTop, left: mouseLeft, zIndex: isMouseMove && !isMouseDown ? '1' : '-1' }}>复制</div>
                    </section>
                )
            } else if (pageType == 'audio') {
                return (
                    <section>
                        <div className="audioContainer">
                            <img src={imageDetail} className="audioCover" />
                            <audio src={musicUrl} controls className="audio"></audio>
                        </div>
                    </section>
                )
            } else if (pageType == 'other') {
                return (
                    <section className="detail_content_doc"
                        onMouseDown={this.handleMouseDown.bind(this)}
                        onMouseMove={this.handleMouseMove.bind(this)}
                        onMouseUp={this.handleMouseUp.bind(this)}
                    >
                        {
                            <div style={{ width: '100%', textAlign: 'center' }} >
                                <img style={{ width: '100%' }} src={imageDetail} alt="perview" />
                            </div>
                        }
                        <div className="detail_content_select" style={{ top: mouseTop, left: mouseLeft, zIndex: isMouseMove && !isMouseDown ? '1' : '-1' }}>复制</div>
                    </section>
                )
            }
        }


        return (
            <section id="ADetail">
                <GoBack />
                <Loading isLoading={isLoading} />
                <Progress progress={progressCount} isShow={progressShow} />
                <section className="detail_container w flex flex-justify-content-space-between">
                    <section className="detail_left">
                        {/* 标题信息 */}
                        <section className="detail_title flex flex-align-items-center flex-justify-content-space-between">
                            <section>
                                {
                                    getIcon(this.props.match.params.type)
                                }
                                <span>{worksDetail.title}</span>
                            </section>
                        </section>

                        {/* 内容 */}
                        <section className="detail_content">

                            {
                                Content()
                            }

                        </section>

                        {/* 投票信息 */}
                        <section className="ad_info_container flex flex-align-items-center flex-justify-content-space-between" >
                            <section className="ad_left">
                                <span>
                                    浏览
                                    <i className="iconfont icon-liulan" ></i>
                                    <span>{viewInfo.views}</span>
                                </span>
                                <span>
                                    <i className="iconfont icon-toupiao" ></i>
                                    <span>{viewInfo.votes}票</span>
                                </span>
                            </section>
                            <section className="ad_right flex flex-align-items-center">
                                <section onClick={this.doShare}>
                                    <i className="iconfont icon-fenxiang"></i>
                                    <span>分享</span>
                                </section>
                               {/* {
                                    getStorage('localStorage', 'userInfo')  
                                        ?
                                        <section onClick={this.hanldeVoteEvent.bind(this)}>
                                            <i className="iconfont icon-toupiao"></i>
                                            <span>投票</span>
                                        </section>
                                        :
                                        null
                                }*/}
                               {/* <section style={{marginLeft:'35px'}} onClick={this.doShare}>
                                    <i className="iconfont icon-xiazaiwancheng"></i>
                                    <span>下载</span>
                                </section>*/}
                              {/*  <section style={{marginLeft:'35px'}} onClick={e => {
                                    Modal.confirm({
                                        title: '下载提示',
                                        icon: <ExclamationCircleOutlined />,
                                        content: '下载作品时，请勿离开此页面；否则会停止下载，是否要下载作品？',
                                        onOk: () => {
                                            // worksDetail['fileList'] &&
                                            //     worksDetail['fileList'][worksIndex] &&
                                            // this.checkDownload(
                                            //     worksDetail['fileList'][worksIndex].id,
                                            //     worksDetail['fileList'][worksIndex].coin,
                                            //     worksDetail['fileList'][worksIndex].fileSizeB
                                            // )
                                            this.getFileList(worksDetail.id)

                                            this.setState({
                                                downListVisible: true
                                            })
                                        },
                                        okText: '确定',
                                        cancelText: '取消'
                                    })
                                }
                                } ><i className="iconfont icon-xiazaiwancheng"></i>下载</section>*/}
                                <Modal
                                    visible={this.state.downListVisible}
                                    footer={null}
                                    onCancel={() => {
                                        this.setState({
                                            downListVisible: false
                                        })
                                    }}
                                    width="600px"
                                >
                                <div className="select_file">
                                    <h3 className="select_title">下载作品</h3>
                                    <div className="select_content">
                                        <ul className="select_file_list flex flex-align-items-center flex-wrap" >
                                            {
                                                fileList.map((item, index) => {
                                                    return (
                                                        <li
                                                            key={item.id}
                                                            onClick={() => {
                                                                this.checkDownload(
                                                                    item.id,
                                                                    parseInt(item.coin),
                                                                    item.filePath,
                                                                    item.fileSizeB
                                                                )
                                                                this.setState({
                                                                    downListVisible: false
                                                                })
                                                            }}
                                                        >
                                                            <Button type="primary" >{this.props.match.params.type === 'venue' || this.props.match.params.type === 'media' ? `第${index + 1}集` : '下载'}</Button>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>

                                    </div>
                                </div>

                                </Modal></section>
                        </section>

                        {/* 专家打分 */}
                        {
                            getStorage('localStorage', 'userInfo') && getStorage('localStorage', 'userInfo').userType === 3 && getStorage('localStorage', 'userInfo').isJudges && this.props.match.params.isDafen === 'true' && this.state.isGrade === 0
                                ?
                                <section className="expert_sourse flex flex-align-items-center flex-justify-content-center">
                                    <span>专家评分：</span>
                                    <Input value={this.state.score} onInput={e => this.setState({
                                        score: e.target.value
                                    })} style={{ width: 143, marginRight: 22, height: 40 }} placeholder="分数" type="number" />
                                    <Button onClick={() => {
                                        Modal.confirm({
                                            content: '提交分数后不可修改，是否继续提交？',
                                            cancelText: '取消',
                                            okText: '确定',
                                            onOk: () => {
                                                this.handleExpertSource()
                                            }
                                        })
                                    }} style={{ width: 80, height: 40, background: '#222222', border: 'none', color: '#fff' }} >提交</Button>
                                </section>
                                :
                                null
                        }
                        {
                            getStorage('localStorage', 'userInfo') && getStorage('localStorage', 'userInfo').userType === 3 && getStorage('localStorage', 'userInfo').isJudges && this.state.isGrade === 1
                                ?
                                <section className="expert_sourse flex flex-align-items-center flex-justify-content-center">
                                   已评分
                                </section>
                                :
                                null
                        }
                    </section>


                    <section className="detail_right">
                        <section className="detail_author_container">
                            {/* 标题 */}
                            <section className="detail_title" >{worksDetail.title}</section>
                            {/* 用户信息 */}
                            <section className="author_info flex flex-align-items-center" onClick={e => this.props.history.push(`/user/${worksDetail.userId}`)} >
                                <img src={worksDetail.headUrl} alt="" />
                                <section>{worksDetail.userName}</section>
                            </section>
                            {/* 形式 */}
                           {/* <section className="form_info">
                                形式：{worksDetail.clsList && worksDetail.clsList[0] && worksDetail.clsList[0].name}
                            </section>*/}
                            {/* 分类 */}
                            <section>日期：{moment(worksDetail.createTime).format('YYYY-MM-DD')}</section>
                            <section className="classify_info" >
                                作品类别：{worksDetail.classifyName}
                            </section>
                           {/* <section className="classify_info" >
                                主讲人姓名：{worksDetail.presenter}
                            </section>
                            <section className="classify_info" >
                                主讲人职务：{worksDetail.preTitle}
                            </section>
                            <section className="classify_info" >
                                报送企业：{worksDetail.company}
                            </section>*/}

                            {/* 文档格式 */}
                         {/*   <section>文档格式：{/\.[\w+\d+|\w+]+$/.exec(worksDetail.filePath) && /\.[\w+\d+|\w+]+$/.exec(worksDetail.filePath)[0].replace(/\./, '').toLocaleUpperCase()}</section>
                             平台外链
                            <section>
                                平台外链：
                                <p>
                                    <a
                                        style={{ wordBreak: 'break-word' }}
                                        rel="noopener noreferrer"
                                        target="_bank"
                                        href={worksDetail.worksUrl}
                                    >
                                        {worksDetail.worksUrl}
                                    </a>
                                </p>
                            </section>*/}
                            {docContent.previewUrl ? <div className="previewBtn" onClick={this.previewDocFile.bind(this, docContent.previewUrl)}>查看全部</div> : null}
                            {/* 简介 */}
                            <section className="desc_infos">
                                <span>简介：</span>
                                <p>
                                    {worksDetail.worksDesc}
                                </p>
                            </section>

                        </section>
                    </section>
                </section>

                <ApplyExpert visible={isShowModal} onCancel={e => this.setState({ isShowModal: e })} />

            </section >
        )
    }
}

export default Detail;