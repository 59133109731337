import React, { Component } from 'react';
import './index.less';


class Progress extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        const { progress, isShow, showTop = '83.5px', hideTop = '0px' } = this.props;
        return (
            <section
                id="Progress"
                className="flex flex-align-items-center flex-justify-content-space-between"
                style={{ top: isShow ? showTop : hideTop }}
            >
                <span className="flex-shrink" >正在加载资源</span>
                <section className="progress_line_container" >
                    <section style={{ width: `${progress}%` }} ></section>
                </section>
                <span className="flex-shrink" >{progress}%</span>
            </section>
        )
    }
}

export default Progress;