/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Radio,
  InputNumber,
  TreeSelect,
  Upload,
  notification,
  Modal
} from "antd";
import axios from "axios";
import qs from "querystring";
import { GET_WORKS_CLASSIFY } from "../../../utils/api";
import Uploadx from "../components/Upload";
import { GoBack } from "../../../compoents/components";
import {
  LoadingOutlined, PlusOutlined,
  // LoadingOutlined,
  // PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./index.less";
import { COS_CONFIG } from "../../../utils/config";

import Cos from 'cos-js-sdk-v5';

const cos = new Cos({
  SecretId:  window.atob("QUtJREtUUTdZaWJEWWNQd0JBc0tWUDJPVEhvNDRuRlBJdUZK"),
  SecretKey:  window.atob('b01lZ1J2ODRqOEY1ZXd5aUJPS3pOVUx1Q0R4UmVhRmQ=')
})

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
};
const token = window.localStorage.getItem("token");
export default (props) => {
  const [loading, setLoading] = useState(false);

  const [cover, setCover] = useState('');
  const [fileListG, setFileListG] = useState([]);
  const [videoList, setVideoList] = useState([{}]);
  const [classifyList, setClassifyList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [ifEmpower, setifEmpower] = useState(0);
  const [projectFileSize, setProjectFileSize] = useState(0) // 工程文件大小

  // 判断保存和发布
  const [isRelease, setIsRelease] = useState(false);

  const type = props.match.params.type;
  const id = props.match.params.id;
  const [form] = Form.useForm();

  console.log('视频', id)

  // 获取领域
  // 获取作品形式
  const getClassifyList = async () => {
    const { data: ret } = await axios.post(
      "/openWork/getClassifys",
      qs.stringify({
        type,
      })
    );
    setClassifyList(ret.data);
  };
  // 获取作品分类
  const getWroksClassify = async () => {
    const { data: ret } = await axios.post(
      GET_WORKS_CLASSIFY,
      qs.stringify({
        type,
      })
    );
    let newData = [...ret.data];
    const changeChild = (arr) => {
      for (let i = 0; i < arr.length; i++) {
        let item = { ...arr[i] };
        item.value = item.id;
        item.title = item.industryName || item.name;
        if (item.industryName) {
          item.name = item.industryName;
        }
        if (item.childList && item.childList.length) {
          changeChild(item.childList);
          item.children = item.childList;
        } else {
          item.children = null;
        }
        arr[i] = item;
      }
    };
    changeChild(newData);
    setFilterList(newData);
  };
  const addVideo = () => {
    let newArr = [...videoList];
    newArr.push({});
    setVideoList(newArr);
  };

  // 删除视频
  const removeVideo = (index) => {
    let newArr = [...videoList];
    newArr.splice(index, 1);
    console.log(index, newArr)
    setVideoList(newArr);
  };

  const getFileList = (list) => {
   /* let photoUrlsArr = [];
    list.forEach((item) => {

      if (item.status === "done") {
        if (item.response) {
          photoUrlsArr.push(item.response.path);
        }
      }
    });*/
    setFileList(list);
    console.log('getFileList111111', list, photoUrlsArr);
    // form.setFieldsValue({
    //   cover: photoUrlsArr.join(","),
    // });
  };
  const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>选择封面</div>
      </div>
  )
  // 保存/提交
  const postSubmit = (parame) => {
    form.validateFields().then(values => {
      console.log("submit",values)
      if (cover=='') {
        notification['error']({
          message: '应急科普共享平台提示',
          description: "请选择封面"
        })
        return;
      }
      if (parame.status == 1) {
        if (!(videoList[0] && videoList[0].filePath)) {
          notification['error']({
            message: '应急科普共享平台提示',
            description: "请选择视频"
          })
          return;
        }
        if (ifEmpower == 1 && fileListG.length == 0) {
          notification['error']({
            message: '应急科普共享平台提示',
            description: "请选择工程文件"
          })
          return;
        }

      }


      let photoUrlsArr = [];
      fileListG.forEach((item) => {
        if (item.status === "done") {
          if (item.response) {
            photoUrlsArr.push(item.response.path);
          }
        }
      });
      let projectFile = photoUrlsArr.join(",");
      videoList.forEach(item => {
        item.coin = item.coin ? item.coin : 100
      })
      let vlist = videoList.filter(item => item.filePath)
      const SAVE_PARAMS = {
        id,
        ...values,
        ...parame,
        ifEmpower,
        projectFile,
        projectFileSize,
        files: JSON.stringify(vlist),
        worksType: type,
      };

      (!id || parseInt(id) === 0) && Reflect.deleteProperty(SAVE_PARAMS, 'id');
      axios
        .post(
          "/works/saveWorks",//
          qs.stringify(SAVE_PARAMS),
          {
            headers: {
              token: token,
            },
          }
        )
        .then((ret) => {
          if (ret.data.code !== 200) {
            notification['error']({
              message: '应急科普共享平台提示',
              description: ret.data.msg
            })
            return
          }
          notification['info']({
            message: '应急科普共享平台提示',
            description: parame.status === '0' ? '操作成功，已保存至您的草稿箱中' : '上传成功'
          })
          props.history.goBack();
        });
    });
  };

  const save = () => {
    setIsRelease(false);
    setTimeout(() => {
      postSubmit({ status: "0" });
    }, 200)
  };
  const submit = () => {
    setIsRelease(true);
    setTimeout(() => {
      postSubmit({ status: "1" });
    }, 200)
  };
  const close = () => {
    props.history.goBack();
  };

  // Cos 上传
  const handleCosUploadFile = async (file, onProgress = p => { }) => {
    let   Bucket = window.atob('a2VwdS0xMzA3ODkzNzky');
    let   Region  = window.atob('YXAtYmVpamluZw==');
    const Key = `${new Date().getTime()}-${file.name}`;
    let progress = 0;
    const data = await cos.putObject({
      Bucket,
      Region,
      StorageClass: 'STANDARD',
      Body: file,
      Key, // fileName
      onProgress
    })
    console.log('jml----handleCosUploadFile', data)
    return { ...data, mediaCover: `https://kepu-1300652598.cos.ap-beijing.myqcloud.com/mediaCover/${Key.replace(/\.mp4/, '')}_0.jpg` };
  }

  // 封面测试
  // https://kepu-1300652598.cos.ap-beijing.myqcloud.com/mediaCover/1612776535702-5cceb5ae-3896-4313-8f3f-6b23aac79889_0.jpg
  // https://kepu-1300652598.cos.ap-beijing.myqcloud.com/mediaCover/1612776560103-5cceb5ae-3896-4313-8f3f-6b23aac79889.mp4_0.jpg
  // "https://kepu-1300652598.cos.ap-beijing.myqcloud.com/mediaCover/1612776844327-5cceb5ae-3896-4313-8f3f-6b23aac79889_0.jpg"

  // 自定义上传
  const customRequest = async options => {
    const { file, onSuccess, onProgress } = options;
    const { Location, mediaCover, progress } = await handleCosUploadFile(file, p => {
      let percent = Math.floor((p.loaded / p.total) * 100)
      onProgress({ percent })
    })
    const path = `https://${Location}`;
    const result = {
      path,
      mediaCover,
      size: file.size,
      fileName: file.name
    }
    onSuccess(result)
  }

  // 作品
  const uploadprops = {
    name: "file",
    multiple: false,
    directory: false,
    headers: {
      token: token,
    },
    beforeUpload: file => {
      return new Promise((resolve, reject) => {
        const nameArr = file.name.split('.');
        const suffix = nameArr[nameArr.length - 1];

        if (file.type != "video/mp4" || suffix != 'mp4') {
          notification['error']({
            message: '应急科普共享平台提示',
            description: "请上传mp4格式文件"
          })
          return reject(Upload.LIST_IGNORE);
        }
        return resolve(true);
      });
    },
    customRequest
  };

  // 工程文件
  const uploadpropsG = {
    name: "file",
    headers: {
      token: token,
    },
    fileList: fileListG,
    customRequest,
    onChange(info) {
      console.log(info)
      setFileListG(info.fileList);

      // info.file.status
      // 上传成功后获取工程文件大小

      if (info.file.status === 'done') {
        setProjectFileSize(info.file.size)
      }
    },
  };

  // 作品上传成功后
  const onFilesChange = (info, index) => {
    console.log(info, index)
    const newArr = [...videoList];
    if (info.fileList.length) {
      let item = info.fileList[0];
      if (item.status === "done") {
        if (item.response) {
          newArr[index].filePath = item.response.path;
          newArr[index].fileName = item.response.fileName;
          newArr[index].fileSize = item.response.size;
          setVideoList(newArr);

          if (!form.getFieldValue('title')) {
            form.setFieldsValue({
              title: item.response.fileName,
            });
          }
        }

        // index === 0 && info.file.type === 'video/mp4'
       /* if (index === 0) {
          let cover = item.response.mediaCover;
          console.log('previewImage', cover);
          form.setFieldsValue({
            cover,
          });
          notification['success']({
            message: '正在获取视频封面'
          })
          getPicture(cover, () => {
            setFileList([
              { uid: 1000, name: "mediaCover.jpg", status: "done", url: cover, thumbUrl: cover },
            ]);
          })
        }*/
      }
    } else {
      newArr[index] = {};
      setVideoList(newArr);
    }
  };
  // 获取图片
  const getPicture = (url, cal) => {
    axios.get(url, {}).then((response) => {
      if (!response.data) {
        setTimeout(() => {
          getPicture(url, cal)
        }, 1000)
      } else {
        cal && cal();
      }
    }).catch(function (error) {
      console.log(error)
    });
  }

  const changeCoin = (value, index) => {
    const newArr = [...videoList];
    newArr[index].coin = value || 50;
    setVideoList(newArr);
  };

  const changeName = (e, index) => {
    const newArr = [...videoList];
    newArr[index].workName = e.target.value;
    setVideoList(newArr);
  };

  useEffect(() => {
    if (id > 0) {
      getList();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0)
    if (parseInt(type) !== 4) {
      getClassifyList();
      getWroksClassify();
    }
  }, []);

  // 获取列表
  const getList = () => {
    axios
      .get("/works/getWorksById?id=" + id, {
        headers: {
          token: token,
        },
      })
      .then((ret) => {
        if (ret.data.code === 200) {
          let item = ret.data.data;
          form.setFieldsValue({
            title: item.title,
            cover: item.cover,

            classifyId: item.classifyId,
            industryId: item.industryId,
            ifEmpower: item.ifEmpower,
            coin: item.coin,
            worksDesc: item.worksDesc,
          });
          setCover(item.cover);
          setifEmpower(parseInt(item.ifEmpower))
         /* item.cover &&
            setFileList([
              { uid: -100, name: "xxx.png", status: "done", url: item.cover },
            ]);*/
          if (item.projectFile) {
            let arr = item.projectFile.split(",");
            let newArr = [];
            arr.forEach((e, index) => {
              newArr.push({
                uid: -100 + index,
                name: "xxx.png",
                status: "done",
                url: e,
              });
            });
            setFileList(newArr);
          }
          if (item.list && item.list.length) {
            setVideoList(item.list);
            // let newArr = [];
            // item.list.forEach((e,index)=> {
            //   let p = { uid: -100 + index, name: e.fileName||'文件', status: "done", url: e.filePath }
            //   newArr.push(p)
            // })

            // setFileList21(newArr);
            // setFileList2(item.list);
          }
        }
      });
  };
  const validateMessages = {
    required: '请输入${label}!'
  };
  const checkTitle=(rule, value,callback)=>{
    console.log("title*******",value)
    const SAVE_PARAMS = {
      id,
      title:value
    };
    axios.post(
        "/works/checkWorks",
        qs.stringify(SAVE_PARAMS),
        {
          headers: {
            token: token,
          },
        }
    )
        .then(res=>{
          console.log("rsp*****",res)
          if(res.data.code === 500){
            notification['error']({
              message: '应急科普共享平台提示',
              description: res.data.msg
            })
            return
          }else{
            callback()
          }
        })
  };

  return (
    <div className="itCon">
      <GoBack />
      <Form form={form} validateMessages={validateMessages}>
        <Row>
          <Col span={24}>
            <FormItem {...formItemLayout} label="名称" name="title" rules={[
              {
                required: true,
              },{
                validator: checkTitle
              },
            ]}>
              <Input placeholder="请输入名称" onBlur={e => {
                const arr = [...videoList];
                if (!arr[0].workName) {
                  arr[0].workName = e.target.value
                  setVideoList(arr)
                }
              }} />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem {...formItemLayout} label="视频内容">
              {videoList.map((item, index) => {
                return (
                  <Row key={item.id || index} >
                    <Col span={24}>
                      <FormItem {...formItemLayout} label="视频名称">
                        <Input
                          placeholder="请输入"
                          onChange={(value) => {
                            changeName(value, index);
                          }}
                          style={{ width: "200px" }}
                          value={item.workName}
                        />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem
                        {...formItemLayout}
                        label="设置科普力"
                        initialValue={100}
                      >
                        <InputNumber
                          min={10}
                          max={1000}
                          onChange={(value) => {
                            changeCoin(value, index);
                          }}
                          style={{ width: "200px" }}
                          value={item.coin || 100}
                        />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem {...formItemLayout} label="选择视频">
                        {id > 0 ? (
                          <Upload
                            {...uploadprops}
                            onChange={(info) => {
                              onFilesChange(info, index);
                            }}
                            fileList={
                              item.filePath
                                ? [
                                  {
                                    uid: -100 + index,
                                    name: item.fileName || "文件",
                                    status: "done",
                                    url: item.filePath,
                                  },
                                ]
                                : []
                            }
                          >
                            <Button icon={<UploadOutlined />}>上传视频</Button>
                          </Upload>
                        ) : (
                          <Upload
                            {...uploadprops}
                            onChange={(info) => {
                              onFilesChange(info, index);
                            }}
                          >
                            <Button icon={<UploadOutlined />}>上传视频</Button>
                          </Upload>
                        )}
                      </FormItem>
                    </Col>

                    {videoList.length > 1 && (
                      <div
                        onClick={() => {
                          removeVideo(index);
                        }}
                        className="removeList"
                      >
                        删除
                      </div>
                    )}
                  </Row>
                );
              })}

              <div className="continueAdd" onClick={addVideo}>
                添加本系列续集
              </div>
            </FormItem>
          </Col>
          <FormItem name="cover">
            <Input type="hidden"></Input>
          </FormItem>
          <Col span={24}>
            <FormItem
              {...formItemLayout}
              label="简介"
              name="worksDesc"
              rules={[
                { required: isRelease }
              ]}
            >
              <TextArea rows={4} placeholder="请输入简介" />
            </FormItem>
          </Col>
          {parseInt(type) !== 4 && (
            <Col span={24}>
              <FormItem {...formItemLayout} label="形式" name="classifyId" rules={[
                {
                  required: isRelease,
                },
              ]}>
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "200px" }}
                >
                  {classifyList.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.classifyName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          )}
          {parseInt(type) !== 4 && (
            <Col span={24}>
              <FormItem {...formItemLayout} label="领域" name="industryId" rules={[
                {
                  required: isRelease,
                },
              ]}>
                <TreeSelect
                  style={{ width: "200px" }}
                  // value={this.state.value}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  treeData={filterList}
                  placeholder="请选择"
                />
              </FormItem>
            </Col>
          )}

          <Col span={24}>
            <FormItem
              {...formItemLayout}
              label="授权"
              name="ifEmpower"
              initialValue={1}
              rules={[
                {
                  required: isRelease,
                },
              ]}
            >
              <Radio.Group value={ifEmpower} onChange={e => {
                if (e.target.value) {
                  Modal.confirm({
                    title: '授权提示',
                    content: '完全授权是指上传者对上传的作品拥有全部版权，作品被他人下载后，可以用于二次创作等商业行为；',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                      setifEmpower(e.target.value)
                    }
                  })
                  return
                }
                setifEmpower(e.target.value)
              }} >
                <Radio value={1}>完全授权</Radio>
                <Radio value={0}>未完全授权</Radio>
              </Radio.Group>
              (完全授权可额外获取50科普力)
            </FormItem>
          </Col>
          {
            parseInt(ifEmpower)
              ?
              <Col span={24}>
                <FormItem {...formItemLayout} label="工程文件">
                  <Upload {...uploadpropsG}>
                    <Button icon={<UploadOutlined />}>上传文件</Button>
                    <span style={{ marginLeft: 20 }} >(上传工程文件可额外获取50科普力)</span>
                  </Upload>
                </FormItem>
              </Col>
              :
              null
          }
          <Col span={24}>
            <FormItem {...formItemLayout} label="封面"  rules={[
              {
                required: true,
              },
            ]}>
              <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={customRequest}
                beforeUpload={file => {
                  return new Promise((resolve, reject) => {
                    const fileTypeArr = [
                      'image/jpg',
                      'image/jpeg',
                      'image/png',
                      'image/gif'
                    ];
                    if (!fileTypeArr.includes(file.type)) {
                      notification['error']({
                        message: '应急科普共享平台提示',
                        description: "请上传jpg、png格式文件"
                      })
                      return reject(Upload.LIST_IGNORE);
                    }
                    setLoading(true)
                    return resolve(true);
                  });
                }}
                onChange={info => {

                  if (info.file.status === 'done') {
                    setLoading(false)
                    setCover(info.file.response.path)
                    form.setFieldsValue({
                      cover: info.file.response.path,
                    });
                  }
                }}
              >
              {/*<span style={{ color: 'gray', fontSize: '14px' }}>自动获取封面不满意可以更换</span>*/}
              {cover ? <img src={cover} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </FormItem>
          </Col>

        </Row>
        <div className="xbottom">
          {/* <img src={testUrl} alt="mediaCover.jpg" class="ant-upload-list-item-image" /> */}
          <div className="xsave" onClick={save}>
            保存
          </div>
          <div className="xpublic" onClick={submit}>
            发布
          </div>
          <div className="xclose" onClick={close}>
            取消
          </div>
        </div>
      </Form>
    </div>
  );
};
