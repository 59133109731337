import React, { Component } from 'react';
import { Modal, Form, Input, Select, DatePicker, Button, message, Cascader } from 'antd';
import axios from 'axios';
import qs from 'querystring';
import moment from 'moment';
import { REGISTER_EXPERT, GET_RANK_LISTS } from '../../utils/api';
import ReactSimpleVerify from 'react-simple-verify'; // 引入react滑块验证
import 'react-simple-verify/dist/react-simple-verify.css';
const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

class RegisterExpert extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cityList: [],
            province: [],
            checkVal: false
        }
    }

    componentDidMount() {
        this.getCityList()
    }

    // 获取省市县
    async getCityList() {
        const { data: ret } = await axios.get(GET_RANK_LISTS);
        this.setState({
            cityList: ret.data
        })
    }

    // 提交专家申请
    async handleSubmitExpert(params) {
        if(!this.state.checkVal){
            return false;
        }
        params.birth = params.birth && moment(params.birth).format('YYYY-MM-DD');
        params.values = params.values.join('-');
        params.province = this.state.province.join('-');
        const { data: ret } = await axios.post(REGISTER_EXPERT, qs.stringify(params))
        console.log(ret)
        if (ret.code !== 200) return message.error(ret.msg);
        message.success('提交成功，请等待审核...')
        this.handleCancelEvent()
    }
// 重置
    success(){
        this.setState({
            checkVal: true
        });
    }
    // 关闭modal
    handleCancelEvent() {
        this.props.onCancel(false)
        this.refs.form.resetFields()
    }

    render() {
        const { visible = false } = this.props;
        const { cityList } = this.state;
        return (
            <section id="ApplyExpert">
                <Modal
                    visible={visible}
                    title="专家申请 应急科普专家 （科普专家）"
                    footer={null}
                    width="40%"
                    onCancel={this.handleCancelEvent.bind(this)}
                >
                    <Form
                        labelAlign="right"
                        labelCol={{ span: 7 }}
                        onFinish={this.handleSubmitExpert.bind(this)}
                        ref="form"
                    >
                        <Item name="proName" label="姓名" rules={[
                            {
                                required: true,
                                message: '姓名不能为空'
                            }
                        ]} >
                            <Input placeholder="请输入姓名" />
                        </Item>
                        <Item name="sex" label="性别" rules={[
                            {
                                required: true,
                                message: '请选择性别'
                            }
                        ]} >
                            <Select
                                placeholder="请选择性别"
                            >
                                <Option value="1" >男</Option>
                                <Option value="2" >女</Option>
                            </Select>
                        </Item>

                        <Item name="values" label="地址" rules={[
                            {
                                required: true,
                                message: '请选择地址'
                            }
                        ]}>
                            <Cascader options={cityList} fieldNames={{
                                label: 'name',
                                value: 'id'
                            }} onChange={
                                (e, options) => {
                                    const arr = options.map(item => item.name);
                                    console.log(arr);
                                    this.setState({ province: arr });
                                }
                            } placeholder="请选择区域" />
                        </Item>

                        <Item name="post" label="职务职称" rules={[
                            {
                                required: true,
                                message: '职务职称不能为空'
                            }
                        ]} >
                            <Input placeholder="请输入职务职称" />
                        </Item>
                        <Item name="email" label="邮箱" rules={[
                            {
                                required: true,
                                message: '邮箱不能为空'
                            }
                        ]}>
                            <Input placeholder="请输入邮箱" />
                        </Item>
                        <Item name="birth" label="出生日期" rules={[
                            {
                                required: true,
                                message: '请选择出生日期'
                            }
                        ]}>
                            <DatePicker style={{ width: '100%' }} onChange={e => console.log(e)} />
                        </Item>
                        <Item name="politics" label="政治面貌" rules={[
                            {
                                required: true,
                                message: '政治面貌不能为空'
                            }
                        ]}>
                            <Input placeholder="请输入政治面貌" />
                        </Item>
                        <Item name="mobile" label="手机号" rules={[
                            {
                                required: true,
                                message: '请填写正确格式手机号',
                                pattern: /^1[3456789]\d{9}/ig
                            }
                        ]}>
                            <Input placeholder="请输入手机号" />
                        </Item>
                        <Item name="majorType" label="申报专业类别" rules={[
                            {
                                required: true,
                                message: '申报专业类别不能为空'
                            }
                        ]}>
                            <Input placeholder="请输入申报专业类别" />
                        </Item>
                        <Item name="company" label="工作单位及部门" rules={[
                            {
                                required: true,
                                message: '工作单位及部门不能为空'
                            }
                        ]}>
                            <Input placeholder="请输入工作单位及部门" />
                        </Item>
                        <Item name="companyAddress" label="工作地址" rules={[
                            {
                                required: true,
                                message: '工作地址不能为空'
                            }
                        ]}>
                            <Input placeholder="请输入工作地址" />
                        </Item>
                        <Item name="proDesc" label="工作简介" rules={[
                            {
                                required: true,
                                message: '工作简介不能为空'
                            }
                        ]}>
                            <TextArea placeholder="请输入工作简介" />
                        </Item>

                        <Item>
                            <div style={{float : 'right'}}>
                                <ReactSimpleVerify width={520} ref="verify" success={  this.success.bind(this)  } />
                            </div>
                        </Item>

                        <Item style={{ textAlign: 'center' }} >
                            <Button htmlType="submit" type="primary" >提交申请</Button>
                            <p style={{ color: '#999999', textAlign: 'center', marginTop: 10 }} >
                                提交成功后，审核结果将以邮件的方式通知您，请耐心等待！
                            </p>
                        </Item>
                    </Form>
                </Modal>
            </section>
        )
    }
}

export default RegisterExpert;