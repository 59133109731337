import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUTH_EXPERT from '../../assets/images/yes.png';
import AVATAR_ICON from '../../assets/images/expert_avatar.png';
import './index.less';

class ExpertCard extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { item } = this.props;
        return (
            <section id="ExpertCard" onClick={e => this.props.history.push(`/user/${item.id}`)} >
                <section className="expert_info flex flex-align-items-center flex-justify-content-space-between">
                    <section className="export_info_list" >
                        <section className="flex flex-align-items-center" >
                            <span>{item.userName}</span>
                            <img src={AUTH_EXPERT}  alt="AUTH_EXPERT" />
                        </section>
                        <section>
                            <span>职务/职称：</span>
                            <span>{item.post}</span>
                        </section>
                        <section>
                            <span>单位/部门：</span>
                            <span>{item.company}</span>
                        </section>
                    </section>
                    <span>
                        <img  style={ !item.headUrl ? {width:100} : { width: 84,height:114 }} src={!item.headUrl ? AVATAR_ICON : item.headUrl} alt="avatar" />
                    </span>
                </section>
                <section className="expert_dis more-overflow">
                    {item.userDesc}
                </section>
            </section>
        )
    }
}

export default withRouter(ExpertCard);