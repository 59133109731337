import React, { useEffect } from "react";
import { GoBack } from "../../../compoents/components";
import "./index.less";
import icon from "../img";

export default (props) => {
  const toUpload = (type) => {
    if (type === "2" || type === "4") {
      props.history.push(`/upload/video/${type}/0`);
    } else {
      props.history.push(`/upload/imgText/${type}/0`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="uploadCon">
      <GoBack />
      <div className="utTitle">请选择作品类型</div>
      <div className="utUl">
        <div
          className="utLi"
          onClick={() => {
            toUpload("2");
          }}
        >
          <div className="utLiTop">
            <img src={icon.videoIcon} alt="" className="utImg" />
          </div>
          <span className="utName">视频</span>
          <span className="utText">支持视频类作品的上传MP4、MOV</span>
        </div>
        <div
          className="utLi"
          onClick={() => {
            toUpload("1");
          }}
        >
          <div className="utLiTop">
            <img src={icon.itIcon} alt="" className="utImg1" />
          </div>

          <span className="utName">图文</span>
          <span className="utText">
            支持图文类作品的上传Office文档、工程文件等
          </span>
        </div>
        <div
          className="utLi"
          onClick={() => {
            toUpload("3");
          }}
        >
          <div className="utLiTop">
            <img src={icon.otherIcon} alt="" className="utImg2" />
          </div>
          <span className="utName">其他</span>
          <span className="utText">支持其他类型作品的上传</span>
        </div>
        <div
          className="utLi"
          onClick={() => {
            toUpload("4");
          }}
        >
          <div className="utLiTop">
            <img src={icon.kpcgIcon} alt="" className="utImg3" />
          </div>
          <span className="utName">科普场馆</span>
          <span className="utText">上传科普场馆的视频介绍</span>
        </div>
      </div>
    </div>
  );
};
