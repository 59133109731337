/*
 * @Author: Yoney Y 
 * @Date: 2021-02-20 10:48:13 
 * @Last Modified by: YoneyY
 * @Last Modified time: 2021-03-16 15:11:59
 */

import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import axios from 'axios';
import { getRouteParams, isLoginEvent } from '../../utils/tools';
import { useObserver, useLocalStore } from 'mobx-react';
import store from '../../store/index';

// 导入静态资源
import './index.less';
import Logo from '../../assets/images/logo.png';
import Logo2 from '../../assets/images/logo.png';
import Avatar from '../../assets/images/avatar.png';
import { GET_IS_READ, GET_USER_INFO_TOKEN } from '../../utils/api';
import { withRouter } from 'react-router-dom';


const Header = props => {

    const localStore = useLocalStore(() => store)

    // const [navIndex, setNavIndex] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [userInfo, setUserInfo] = useState({})

    const { push: goTo } = props.history;

    // 导航
    const navList = [
        {
            title: '首页',
            path: '/'
        },
        {
            title: '视频',
            path: '/content/list/media',
            detail: '/detail/media'
        },
        {
            title: '图文',
            path: '/content/list/doc',
            detail: '/detail/doc'
        },
        {
            title: '其他',
            path: '/content/list/other',
            detail: '/detail/other'
        },
        {
            title: '科普专家',
            path: '/expert/list'
        },
        {
            title: '融创团队',
            path: '/team/list'
        },
        {
            title: '科普场馆',
            path: '/content/list/venue',
            detail: '/detail/venue'
        },
        {
            title:'帮助',
            path:'help'
        }
    ]

    // 菜单
    const menuList = [
        {
            title: '个人资料',
            path: '/my'
        },
        {
            title: '作品管理',
            path: '/my/works'
        },
        {
            title: '下载记录',
            path: '/my/down'
        },
        {
            title: '消息通知',
            path: '/my/msg'
        },
        {
            title: '我的收藏',
            path: '/my/coll'
        },
    ]

    const methods = {
        // 获取用户信息
        async getUserInfo() {
            const { data: ret } = await axios.post(GET_USER_INFO_TOKEN)
            if(ret !=null){
            // .match(/.{3}/ig)
            ret.data.province = ret.data.province ? `${ret.data.province.match(/.{3}/ig)[0]}${ret.data.province.match(/.{3}/ig)[1] ? '-' : ''}${ret.data.province.match(/.{3}/ig)[1] || ''}` : ret.data.province
            setUserInfo(ret.data)
            }
        },

        handleChangeNavItem(navIndex, path) {
            console.log(path)
            if(path == 'help'){
                window.open('https://kepu-1307893792.cos.ap-beijing.myqcloud.com//%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97.pdf');
                // window.open('https://kepu-1300652598.cos.ap-beijing.myqcloud.com/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97.pdf');
            }else{
                props.history.push(path)
            }
            // setNavIndex(navIndex)
        },

        handleSetLogin() {
            localStore.setLogin(true)
        },

        // 查询是否有未读消息
        async getIsReadMsg() {
            const { data: ret } = await axios.post(GET_IS_READ)
            if(ret !=null){//jml add
            const allIsRead = ret.data ? [ret.data.isReadExa, ret.data.isReadCom] : []
            allIsRead.filter(item => item === 1).length > 0
                ?
                localStore.setAllIsNotRead(1)
                :
                localStore.setAllIsNotRead(0)
        }
        }
    }

    const menu = (
        <Menu className="header_menu_container">
            {
                menuList.map((item, index) => {
                    return <Menu.Item className={`${localStore.allIsNotRead === 1 && index === 3 ? 'msg_active' : ''}`} key={item.path} onClick={e => {
                        goTo(item.path)
                        // console.log(this.props.location.pathname === item.path, item.path, this.props.location.pathname)
                    }}>{item.title}</Menu.Item>
                })
            }
            <Menu.Item danger onClick={e => {
                window.localStorage.removeItem('token')
                window.localStorage.removeItem('userInfo')
                setTimeout(() => {
                    window.location.reload()
                }, 100)
            }}>退出登录</Menu.Item>
        </Menu>
    )



    useEffect(() => {
        if (isLoginEvent()) {
            methods.getUserInfo()
            methods.getIsReadMsg()
        }
    }, [])

    useEffect(() => {
        if (props.history.location.pathname === '/search') {
            const { key } = getRouteParams()
            setSearchText(decodeURI(key))
        }
        props.history.listen(route => {
            console.log('route', route)
            setSearchText('')
        })
    }, [])


    // useEffect 如果是想componentDidMout的效果，第二个参数设置[]，如果未设置第二个参数，而且在内部改变了状态，就会一直重复渲染

    //https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect

    // https://www.imooc.com/wenda/detail/516463


    return useObserver(() => (
        <header className={`${props.isHome ? 'header_index' : 'header_default'}`}>
            <section className="header_container w flex flex-align-items-center flex-justify-content-space-between">
                <img
                    style={{ width: '220px' }}
                    src={props.isHome ? Logo2 : Logo}
                    title="应急科普共享平台"
                    alt="logo"
                    onClick={() => props.history.push('/')}
                />
                <section className="flex flex-align-items-center">
                    {/* 导航 */}
                    <nav className="header_nav_list flex flex-align-items-center">
                        {
                            navList.map((item, index) => <section key={item.path} onClick={() => methods.handleChangeNavItem(index, item.path)} className={`${props.location.pathname === item.path || props.location.pathname.replace(/\/\d+/, '') === item.detail ? 'header_nav_active' : ''}`} >{item.title}</section>)
                        }
                    </nav>
                    {/* 搜索 */}
                    <section className="header_search_container">
                        <section>
                            <input
                                type="text"
                                value={searchText}
                                placeholder="请输入搜索关键词"
                                onInput={e => setSearchText(e.target.value)}
                                onChange={e => setSearchText(e.target.value)}
                                onKeyDown={
                                    e => {
                                        if (e.keyCode === 13) {
                                            props.history.push(`/search?key=${e.target.value}`)
                                            window.location.reload()
                                        }
                                    }
                                }
                            />
                            <i className="iconfont icon-juxingcopyx" onClick={e => {
                                props.history.push(`/search?key=${searchText}`)
                                window.location.reload()
                            }} ></i>
                        </section>
                    </section>
                    {/* 登录/用户信息 */}
                    <section className="header_auther_container flex flex-align-items-center" >
                        {
                            isLoginEvent() ?
                                <Dropdown overlay={menu} >
                                    <img style={{ backgroundColor: '#fff', borderRadius: '50%' }} src={
                                        userInfo.headUrl
                                            ?
                                            userInfo.headUrl
                                            :
                                            userInfo.userType === 3
                                                ?
                                                require('../../assets/images/expert_avatar.png')
                                                :
                                                Avatar
                                    } alt="avatar" onClick={() => props.history.push('/my')} />
                                </Dropdown>
                                :
                                <>
                                    <img src={Avatar} alt="avatar" />
                                    <section className="userInfo" onClick={() => methods.handleSetLogin()}  >登录</section>
                                </>
                        }
                    </section>
                </section>
            </section>
        </header>
    ))
}

export default withRouter(Header);