import React, { Component } from 'react';
import { GoBack } from '../../compoents/components';
import { UserWorks, UserInfo } from './component/component';
import './index.less';

class User extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        const { id } = this.props.match.params
        return (
            <section id="User" className="w">
                <GoBack />
                <UserInfo userId={id} />
                <UserWorks userId={id} />
            </section>
        )
    }
}

export default User;