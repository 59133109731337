import { action, observable, makeObservable } from "mobx";

class AdminStore {
    constructor() {
        makeObservable(this, {
            isLogin: observable,
            setLogin: action,
            userInfo: observable,
            setUserInfo: action,
            searchText: observable,
            setSearchText: action,
            allIsNotRead: observable,
            setAllIsNotRead: action
        });
    }

    isLogin = false;
    userInfo = {};
    searchText = '';
    allIsNotRead = 0 // 0 没有 1 有



    setLogin = s => {
        this.isLogin = s
    }

    setUserInfo = u => {
        this.userInfo = u
    }

    setSearchText = t => {
        this.searchText = t
    }

    setAllIsNotRead = n => {
        this.allIsNotRead = n
    }
}

const store = new AdminStore();

export default store;
