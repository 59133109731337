import React, {Component, useState} from 'react';
import './index.less';
import {notification} from "antd";
import { withRouter } from 'react-router';
import axios from "axios";
import {GET_NOTICE, GET_NOTICE_LIST} from "../../../utils/api";
import qs from "querystring";

const Float = props => {
    const [id, setId] = useState(0);
    const hanldeOnClick = () => {

        props.onClick && props.onClick()
    }

    const viewExplain = () => {
        window.open('https://kepu-1300652598.cos.ap-beijing.myqcloud.com/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97.pdf');
    }
    const onKtClick = () => {
        //prod 41
         getNoticeList();

    }

    const { getNoticeList } = {
        // 获取通知
        async getNoticeList() {
            const { data: ret } = await axios.post(GET_NOTICE_LIST, qs.stringify({
                callUp: 3
            }));
            // console.log(ret.data[0].title+"---2--------")
             props.history.push(`/oldActivity/detail/`+ret.data[0].id);
        }

    }

    return (
        <div>
          {/*  { <img
                onClick={() => onKtClick()}
                className="float_icon"
                src={require('../../../assets/images/1.png')}
                title="点击查看征集活动详情"
                alt="征集活动"
            />}
            { <img style={{left:"100px",position:"fixed"}}//http://huodong.anquanyue.org.cn/anquanyue/2023sdtsyl/index.aspx
                onClick={() => window.open("https://zt.mempe.org.cn/topic/2023/jjds/")}
                className="float_icon"
                src={require('../../../assets/images/829.png')}//tsyllogo-1.png
                title="点击查看征集活动详情"
                alt="征集活动"
            />} */}
        {/*
           { <img
                onClick={() => hanldeOnClick()}
                className="float_icon"
                src={require('../../../assets/images/1.png')}
                title="点击查看征集活动详情"
                alt="征集活动"
            />}
         {<img className="float_icon_old"

                 src={require('../../../assets/images/float_icon_old.png')}
                 onClick={onKtClick}
                 title="点击查看征集活动详情"
                 alt="征集活动"/> }*/}
            {/* <div className="float_left_icon" onClick={viewExplain}>
                <div>应急科普共享平台</div>
                <div>使用指南</div>
            </div> */}
        </div>


    )

}

export default withRouter(Float);