import React, { Component } from 'react';
import { Pagination } from 'antd';
import axios from 'axios';
import qs from 'querystring';
import './index.less';
import { GET_INTE_LIST } from '../../../utils/api';

class MyMsg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 0,
            msgTab: [
                {
                    title: '我的获取',
                    type: 1
                },
                {
                    title: '我的消耗',
                    type: 2
                },
                {
                    title: '如何获取',
                    type: 0
                }
            ],
            msgList: [],
            total: 1,
            allIsRead: []
        }
    }

    componentDidMount() {
        this.getInteList()
    }



    async getInteList(type = 1, pageNum = 1) {
        const { data: ret } = await axios.post(GET_INTE_LIST, qs.stringify({
            type,
            pageNum,
            pageSize: 6
        }))

        console.log(ret)
        this.setState({
            msgList: ret.data,
            total: ret.total
        })
    }

    handleChangeTab(index) {
        const { msgTab } = this.state;
        if (msgTab[index].type > 0) {
            this.getInteList(msgTab[index].type)
        }
        this.setState({
            tabIndex: index
        })
    }

    render() {
        const { msgList, msgTab, tabIndex, total, allIsRead } = this.state;
        return (
            <section id="MyInte">
                <section className="msg_tab flex flex-align-items-center flex-justify-content-space-between">
                    <section className="msg_tab_list flex flex-align-items-center " >
                        {
                            msgTab.map((item, index) => {
                                return (
                                    <section
                                        key={index}
                                        className={`${tabIndex === index ? 'active' : ''}`}
                                        onClick={this.handleChangeTab.bind(this, index)}
                                    >
                                        {item.title}
                                        {
                                            allIsRead[index] === 1
                                                ?
                                                <section className="msg_active"></section>
                                                :
                                                null
                                        }
                                    </section>
                                )
                            })
                        }
                    </section>
                    {/* <Tooltip title={() => {
                        return (
                            <div>
                                <p>1.上传作品被他人下载时可获得奖励                 10-1000科普力 （奖励数值可以在上传作品时自己设定） </p>
                                <p>
                                    2.作品发布并审核成功                          +100科普力
                                </p>
                                <p>
                                    3.每日登陆平台                                                      +10科普力
                                </p>
                                <p>
                                    4.评论作品不小于10个字符（单个作品只能获取一次，无上限）                    +50科普力
                                </p>
                                <p>
                                    5.点赞作品（每个作品只能获取一次，每日上限5次 ）                            +5科普力
                                </p>
                                <p>
                                    6.上传工程文件                                                     +100科普力
                                </p>
                                <p>
                                    7.勾选完全授权                                                      +50科普力
                                </p>
                                <p>
                                    8.作者删除作品后扣除上传时的奖励，作品被下载所获得的科普力保留。
                                </p>
                            </div>
                        )
                    }}>
                        <a>如何获取？</a>
                    </Tooltip> */}
                    {/* <a onClick={this.handleReadAllMsg.bind(this)} >全部已读</a> */}
                </section>
                {
                    msgTab[tabIndex].type > 0
                        ?
                        <>
                            <ul className="msg_list" >
                                {
                                    msgList.map(item => {
                                        return (
                                            <li
                                                key={item.id}
                                                className={`msg_item flex flex-align-items-center flex-justify-content-space-between ${item.isRead === 1 ? 'msg_not_read' : ''}`}
                                            >
                                                <span>{item.content}</span>

                                                <span>{item.createTime}</span>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <Pagination
                                style={{ textAlign: 'right' }}
                                defaultCurrent={1}
                                pageSize={6}
                                showSizeChanger={false}
                                total={total}
                                onChange={p => {
                                    this.getInteList(msgTab[tabIndex].type, p)
                                }}
                            />
                        </>
                        :
                        <div>
                            <img style={{ width: '100%', marginTop: 5 }} src={require('../../../assets/images/get_coin.png')} alt="how" />
                        </div>
                }
            </section>
        )
    }
}

export default MyMsg;