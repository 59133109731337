import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { MY_ROUTER } from '../../../router';
import axios from 'axios';
import './index.less';
import { isLoginEvent } from '../../../utils/tools';
import { GET_USER_INFO_TOKEN } from '../../../utils/api';
import { inject, observer } from 'mobx-react';


@inject('store') @observer
class My extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuIndex: 0,
            menuList: [
                {
                    title: '个人资料',
                    path: '/my'
                },
                {
                    title: '作品管理',
                    path: '/my/works'
                },
                {
                    title: '下载记录',
                    path: '/my/down'
                },
                {
                    title: '消息通知',
                    path: '/my/msg'
                },
                {
                    title: '我的收藏',
                    path: '/my/coll'
                },
                {
                    title: '我的科普力',
                    path: '/my/inte'
                }
            ],
            pathName: '',
            userInfo: {}
        }
    }

    componentDidMount() {
        console.log(!isLoginEvent())
        if (!isLoginEvent()) return this.props.history.replace('/');
        const { menuList } = this.state;
        this.setState({
            menuIndex: menuList.findIndex(item => item.path === this.props.location.pathname)
        })
        this.props.history.listen(route => {
            console.log(route)
            this.setState({
                pathName: route.pathname,
                menuIndex: menuList.findIndex(item => item.path === route.pathname)
            })
        })
        this.getUserInfo()
    }

    handleChangeMenu(index) {
        console.log(this.props)
        const { menuList } = this.state;
        this.setState({
            menuIndex: index
        })
        this.props.history.push(menuList[index].path)
    }

    // 获取用户信息
    async getUserInfo() {
        const { data: ret } = await axios.post(GET_USER_INFO_TOKEN)
        // .match(/.{3}/ig)
        // ret.data.province = `${ret.data.province.match(/.{3}/ig)[0]}${ret.data.province.match(/.{3}/ig)[1] ? '-' : ''}${ret.data.province.match(/.{3}/ig)[1] || ''}`
        if(ret){//jml add
            if(ret.data.province){
                ret.data.province = ret.data.province.replace(/市辖区/g, '');
                ret.data.province = ret.data.province.replace(/市辖县/g, '');
            }
            this.setState({
                userInfo: ret.data
            })
        }
    }

    render() {
        const { menuList, menuIndex, pathName, userInfo } = this.state;
        // const { userName, province, coin } = JSON.parse(window.localStorage.getItem('userInfo'));
        return (
            <section id="My" className="w flex flex-justify-content-space-between" >
                <section className="my_left_container flex-shrink">
                    {/* 用户基本信息 */}
                    <section className="my_user_info flex flex-align-items-center flex-justify-content-center flex-direction-column">
                        <img src={
                            userInfo.headUrl
                                ?
                                userInfo.headUrl
                                :
                                userInfo.userType === 3
                                    ?
                                    require('../../../assets/images/expert_avatar.png')
                                    :
                                    require('../../../assets/images/avatar.png')
                        } alt="" />
                        <div>{userInfo.userName}</div>
                        <div>{userInfo.province}</div>
                        <div>科普力：{userInfo.coin || '0'}</div>
                    </section>

                    {/* this.props.store.allIsRead(true) */}
                    {/* 个人中心菜单 */}
                    <ul className="my_menu_list" >
                        <li className="move" style={{ top: menuIndex * 61 }} ></li>
                        {
                            menuList.map((item, index) => {
                                return (
                                    <li key={index} onClick={this.handleChangeMenu.bind(this, index)} className={`${this.props.history.location.pathname === item.path || pathName === item.path ? 'active' : ''} ${this.props.store.allIsNotRead === 1 && index === 3 ? 'msg_active' : ''}`} >
                                        {item.title}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </section>

                <section className="my_right_container flex-shrink">
                    <Switch>
                        {
                            MY_ROUTER.map(item => {
                                return <Route key={item.path} path={item.path} exact={item.exact} render={routerProps => <item.component {...routerProps} />} />
                            })
                        }
                    </Switch>
                </section>
            </section>
        )
    }
}

export default My;