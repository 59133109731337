/*
 * @Author: Yoney Y 
 * @Date: 2020-11-16 10:41:19 
 * @Last Modified by: YoneyY
 * @Last Modified time: 2021-03-11 12:08:06
 */
import React, { Component } from 'react';
import axios from 'axios';
import qs from 'querystring';
import moment from 'moment';
import { DatePicker } from 'antd';
import { NotFound, WorkCard, Loading } from '../../compoents/components';
import { Pagination } from 'antd';
import './Content.less';
import { GET_CLASSIFY_LIST, GET_WORKS_LIST, GET_WORKS_CLASSIFY } from '../../utils/api';
import { WROKS_TYPE } from '../../utils/config';
import JOIN_EXPERT from '../../assets/images/banner01.png';

const { RangePicker } = DatePicker;

let filterObj = {}; // 筛选请求参数

class Content extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listType: null,
            filterList: [],
            worksList: [],
            classifyList: [],
            classifyIndex: -1,
            total: 1,
            pageNum: 1,
            filterIndex: -1, // 第几项筛选-筛选的种类 0-自然灾害 1-生活安全 2-事故灾难
            filterChildIndex: -1, // 某项下面一级菜单选中的下表
            filterThreeIndex: -1, // 一级菜单下的二级菜单
            filterClassifyIndex: -1,
            filterTime: [],

        }
    }


    componentDidMount() {
        const { type: listType } = this.props.match.params;
        const TYPE = WROKS_TYPE[listType]
        window.scrollTo(0, 0)
        this.props.history.listen(route => {
            window.scrollTo(0, 0)
            console.log(this.props.match.params.type)
            window.location.reload()
            this.setState({
                listType: this.props.match.params.type
            })
        })
        this.getWroksList({
            worksType: TYPE
        })
        this.getClassifyList(TYPE)
        this.getWroksClassify(TYPE)
        this.setState({
            listType
        })
    }

    // 获取作品列表
    async getWroksList(options) {
        filterObj = options;
        const filterObjStr = window.localStorage.getItem('filterObjStr');
        const filterInfoStr = window.localStorage.getItem('filterInfo');
        if (filterObjStr) {
            const _filterObj = JSON.parse(filterObjStr);
            options = _filterObj;
            filterObj = _filterObj;
            window.localStorage.setItem('filterObjStr', '');
        }
        if (filterInfoStr) {
            const filterInfo = JSON.parse(filterInfoStr)
            this.setState({
                ...filterInfo
            });
            window.localStorage.setItem('filterInfo', '');
        }

        this.setState({ isLoading: true })
        const { worksType, startTime, endTime, classifyId, industryIds, pageNum = 1, pageSize = 16 } = options;
        const { data: ret } = await axios.get(GET_WORKS_LIST, {
            params: {
                worksType,
                startTime,
                endTime,
                classifyId,
                industryIds,
                pageNum,
                pageSize
            }
        })
        this.setState({
            worksList: ret.data || [],
            pageNum,
            total: ret.total,
            isLoading: false
        })
    }

    // 获取作品形式
    async getClassifyList(type) {
        const { data: ret } = await axios.post(GET_CLASSIFY_LIST, qs.stringify({
            type
        }))

        this.setState({
            classifyList: ret.data
        })
    }

    // 获取作品分类
    async getWroksClassify(type) {
        const { data: ret } = await axios.post(GET_WORKS_CLASSIFY, qs.stringify({
            type
        }))

        this.setState({
            filterList: ret.data
        })
    }

    handleChangeFilter(parent, child) {
        const { filterList } = this.state;
        this.setState({
            filterIndex: parent,
            filterChildIndex: child,
            filterThreeIndex: -1
        });

        this.getWroksList({
            worksType: WROKS_TYPE[this.props.match.params.type],
            industryIds: child === -1 ? filterList[parent].id : filterList[parent].childList[child].id
        })
    }

    render() {
        const {
            filterList,
            total,
            worksList,
            filterIndex,
            filterChildIndex,
            filterThreeIndex,
            classifyList,
            filterTime,
            filterClassifyIndex,
            isLoading,
            pageNum
        } = this.state;

        const height = {
            height: 0
        }

        const minHeight = {
            minHeight: 30,
            paddingTop: 10
        }

        return (
            <section id="Content">
                <Loading isLoading={isLoading} />
                {
                    this.state.listType !== 'venue'

                    &&

                    <section className="content_filter_container">
                        <section className="filter_container w">
                            {/* 时间筛选 */}
                            <section className="filter_date flex flex-align-items-center">
                                <span className="flex-shrink" style={{ marginRight: 18 }} >时间：</span>
                                <section className="flex flex-justify-content-space-between flex-align-items-center" >
                                    <section>
                                        <span onClick={e => {
                                            this.getWroksList({ worksType: WROKS_TYPE[this.props.match.params.type] })
                                            this.setState({
                                                filterTime: [],
                                                filterIndex: -1,
                                                filterChildIndex: -1,
                                                filterClassifyIndex: -1
                                            })
                                        }} >全部</span>
                                        <RangePicker
                                            style={{ marginLeft: 10, border: 'none', backgroundColor: '#F0F2F7' }}
                                            defaultValue=""
                                            value={filterTime}
                                            format="YYYY-MM-DD"
                                            onChange={e => {
                                                this.setState({
                                                    filterTime: e
                                                })
                                                this.getWroksList({
                                                    startTime: e[0].format('YYYY-MM-DD'),
                                                    endTime: e[1].format('YYYY-MM-DD')
                                                })
                                            }}
                                        />
                                    </section>
                                    <section><i className="iconfont icon-liebiao" ></i>总数量: <span style={{ color: '#154CCC' }} >{total}</span></section>
                                </section>
                            </section>

                            {/* 形式筛选 */}
                            <section className="filter_shape filter_base flex flex-align-items-center" >
                                <span>形式：</span>
                                <ul className="flex flex-align-items-center flex-wrap">
                                    <li
                                        className={`${filterClassifyIndex === -1 ? 'active' : ''}`}
                                        onClick={e => {
                                            this.setState({
                                                filterClassifyIndex: -1
                                            })
                                            this.getWroksList({
                                                worksType: WROKS_TYPE[this.props.match.params.type]
                                            })
                                        }}
                                    >
                                        全部
                                    </li>
                                    {
                                        classifyList.map((item, index) => {
                                            return (
                                                <li
                                                    key={item.id}
                                                    className={`${filterClassifyIndex === index ? 'active' : ''}`}
                                                    onClick={e => {
                                                        this.setState({
                                                            filterClassifyIndex: index
                                                        })
                                                        this.getWroksList({
                                                            worksType: WROKS_TYPE[this.props.match.params.type],
                                                            classifyId: classifyList[index].id
                                                        })
                                                    }}
                                                >
                                                    {item.classifyName}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </section>
                            {/* 分类动态筛选 */}
                            {
                                filterList.map((item, index) => {
                                    return (
                                        <section key={index} className="filter_base" >
                                            <section className="flex flex-align-items-center" >
                                                <span>{item.industryName}：</span>
                                                <ul className="flex flex-align-items-center flex-wrap">
                                                    <li
                                                        onClick={this.handleChangeFilter.bind(this, index, -1)}
                                                        className={`${filterIndex === index && filterChildIndex === -1 ? 'active' : ''}`}
                                                    >
                                                        全部
                                                </li>
                                                    {
                                                        item.childList.map((_item, _index) => {
                                                            return (
                                                                <li
                                                                    onClick={this.handleChangeFilter.bind(this, index, _index)}
                                                                    key={_index}
                                                                    className={`${filterIndex === index && filterChildIndex === _index ? 'active' : ''}`}
                                                                >
                                                                    {_item.name}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </section>
                                            {/* 三级分类 */}
                                            {
                                                filterIndex === index && filterChildIndex > -1
                                                    ?
                                                    <ul
                                                        className="filter_three flex flex-wrap"
                                                        style={filterChildIndex > -1 ? minHeight : height}
                                                    >
                                                        <li
                                                            className={`works_filter_child_all ${filterThreeIndex === -1 ? 'active' : ''}`}
                                                            onClick={e => {
                                                                console.log("filterThreeIndex", -1);
                                                                this.setState({ filterThreeIndex: -1 })
                                                                this.getWroksList({
                                                                    worksType: WROKS_TYPE[this.props.match.params.type],
                                                                    industryIds: filterList[filterIndex].childList[filterChildIndex].id
                                                                })
                                                            }}
                                                        >
                                                            全部
                                                        </li>
                                                        {
                                                            filterIndex > -1 &&
                                                            item.childList[filterChildIndex] &&
                                                            item.childList[filterChildIndex].childList.map((_val, _i) => {
                                                                return (
                                                                    <li
                                                                        className={`${filterThreeIndex === _i ? 'active' : ''}`}
                                                                        key={item.id}
                                                                        onClick={e => {
                                                                            console.log("filterThreeIndex", _i);
                                                                            this.setState({
                                                                                filterThreeIndex: _i
                                                                            })
                                                                            this.getWroksList({
                                                                                worksType: WROKS_TYPE[this.props.match.params.type],
                                                                                industryIds: _val.id
                                                                            })
                                                                        }}
                                                                    >
                                                                        {_val.name}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    :
                                                    null
                                            }
                                        </section>
                                    )
                                })
                            }
                        </section>
                    </section>
                }

                {
                    this.state.listType === 'venue'

                    && 
                    <section style={{backgroundColor: '#ffffff'}}>
                        <section className="w">
                        <img className="w" style={{cursor:'pointer'}} src={JOIN_EXPERT} alt="join" useMap="#JoinMap" onClick={e => {
                            window.open('http://www.anquanyue.org.cn/zt/2021aqtyg/')
                        }} />
                            {/* <div onClick={this.gokpcg}>123</div> */}
                        </section>
                    </section>
                }    

                {/* 作品展示 */}
                <section className="works_container">
                    <section className="w">
                        {
                            worksList.length > 0
                                ?
                                <section className="works_item_container flex flex-wrap">
                                    {
                                        worksList.map(item => {
                                            return (
                                                <WorkCard
                                                    key={item.id}
                                                    id={item.pathId}
                                                    listType={this.props.match.params.type}
                                                    title={item.title}
                                                    coverImg={item.cover}
                                                    avatar={item.headUrl}
                                                    dateTime={moment(item.createTime).format('YYYY/MM/DD')}
                                                    integral={item.coin}
                                                    author={item.userName}
                                                    btnType={item.status === 1 ? 'get' : 'down'}
                                                    fileSize={item.fileSizeB}
                                                    detail={item}
                                                    onClick={() => {
                                                        // 当前状态保存
                                                        window.localStorage.setItem('filterInfo', JSON.stringify(this.state));
                                                        console.log(this.state);
                                                        // 当前筛选请求参数保存
                                                        window.localStorage.setItem('filterObjStr', JSON.stringify(filterObj));
                                                        // console.log('点击跳转=>', filterObj);
                                                        this.props.history.push(`/detail/${this.props.match.params.type}/${item.id}`)
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </section>
                                :
                                <NotFound text={'暂无作品列表，为平台增加优质内容吧'} />
                        }

                        <Pagination
                            defaultCurrent={pageNum}
                            current={pageNum}
                            pageSize={16}
                            total={total}
                            showSizeChanger={false}
                            style={{ textAlign: 'right', margin: '50px 0 68px' }}
                            onChange={pageNum => {
                                let reqIndustryIds = null;
                                if (filterIndex != -1) {
                                    reqIndustryIds = filterList[filterIndex].childList[filterChildIndex].id;
                                    console.log('filterIndex',filterList,filterIndex,filterChildIndex,filterThreeIndex)
                                    console.log(filterThreeIndex != -1)
                                    if (filterThreeIndex !== -1) {
                                        console.log("6666")
                                        reqIndustryIds = filterList[filterIndex].childList[filterChildIndex].childList[filterThreeIndex].id
                                        console.log('reqIndustryIds',reqIndustryIds)
                                    }
                                }
                                this.getWroksList({
                                    worksType: WROKS_TYPE[this.props.match.params.type],
                                    classifyId: filterClassifyIndex === -1 ? null : classifyList[filterClassifyIndex].id,
                                    industryIds: reqIndustryIds,
                                    pageNum
                                })
                            }}
                        />
                    </section>
                </section>
            </section >
        )
    }
}

export default Content;