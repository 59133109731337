import React, { Component } from 'react';
import { message, Pagination, Popconfirm } from 'antd';
import axios from 'axios';
import qs from 'querystring';
import moment from 'moment';
import './index.less';
import { Classify, WorkCard, NotFound } from '../../../compoents/components';
import { DEL_WORKS_DRAFT, GET_MY_WORKS } from '../../../utils/api';
import { WROKS_TYPE } from '../../../utils/config';

class MyWorks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            worksIndex: 0,
            worksTab: [
                {
                    title: '审核通过',
                    status: 2
                },
                {
                    title: '待审核',
                    status: 1
                },
                {
                    title: '未通过',
                    status: 3
                },
                {
                    title: '草稿箱',
                    status: 0
                }
            ],
            worksType: null,
            worksList: [],
            pageNum: 1,
            total: 1
        }
    }

    componentDidMount() {
        this.getMyWorksList(2)
    }

    // 获取作品列表
    async getMyWorksList(status, worksType = null, pageNum = 1) {
        const { data: ret } = await axios.get(GET_MY_WORKS, { params: { status, worksType, pageNum, pageSize: status === 0 ? 3 : 6 } })
        this.setState({
            worksList: ret.data,
            worksType,
            pageNum,
            total: ret.total
        })
    }

    async handleDelWroksDraft(id) {
        const { worksTab, worksIndex, worksType } = this.state;
        const { data: ret } = await axios.post(DEL_WORKS_DRAFT, qs.stringify({
            id
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        message.success('删除成功')
        this.getMyWorksList(worksTab[worksIndex].status, worksType)
    }

    handleChangeTab(index, status) {
        const { worksIndex } = this.state;
        if (worksIndex === index) return;
        this.getMyWorksList(status)
        this.setState({
            worksIndex: index,
        })

    }

    render() {
        const { worksTab, worksIndex, worksList, total, worksType, pageNum } = this.state;
        const btnType = () => {
            if (worksIndex === 0) return 'del';
            if (worksIndex === 1) return 'exam';
            if (worksIndex === 2) return 'edit';
        }
        return (
            <section id="MyWorks">
                <section className="works_upload" onClick={() => this.props.history.push('/upload/type')} >
                    <i className="iconfont icon-fasong" ></i>
                    <span>上传作品</span>
                </section>

                {/* 分类切换 */}
                <section className="works_tab_container" >
                    <ul className="flex flex-align-items-center" >
                        {
                            worksTab.map((item, index) => {
                                return (
                                    <li
                                        className={`works_tab_item ${worksIndex === index ? 'active' : ''}`}
                                        onClick={this.handleChangeTab.bind(this, index, item.status)}
                                        key={index}
                                    >
                                        {item.title}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </section>

                {/* 所属分类 */}
                <Classify onChange={v => this.getMyWorksList(worksTab[worksIndex].status, v === 'null' || v === null ? null : parseInt(v))} />

                <section className="works_list flex flex-wrap" >
                    {
                        worksIndex < 3 ?

                            worksList.length > 0
                                ?
                                worksList.map(item => {
                                    return (
                                        <WorkCard
                                            key={item.id}
                                            id={item.id}
                                            wId={item.id}
                                            listType={WROKS_TYPE[item.worksType]}
                                            title={item.title}
                                            coverImg={item.cover}
                                            avatar={item.headUrl}
                                            dateTime={moment(item.createTime).format('YYYY/MM/DD')}
                                            integral={item.coin}
                                            author={item.userName}
                                            btnType={btnType()}
                                            isDel={item.isDel}
                                            confirmDelType={3}
                                            detail={item}
                                            onClick={() => {
                                                this.props.history.push(`/detail/${WROKS_TYPE[item.worksType]}/${item.id}`)
                                            }}
                                            onDelete={e => {
                                                this.getMyWorksList(worksTab[worksIndex].status, worksType, pageNum)
                                            }}
                                            onConfirm={
                                                e => {
                                                    this.getMyWorksList(worksTab[worksIndex].status, worksType, pageNum)
                                                }
                                            }
                                        />
                                    )
                                })
                                :
                                <NotFound text="暂未找到" />
                            :
                            <section className="works_draft_container" >
                                {
                                    worksList.map(item => {
                                        return (
                                            <section key={item.id} className="flex"  >
                                                {/* 封面图 */}
                                                <section className="cover_img flex-shrink ">
                                                    <img src={item.cover} alt="coverImg" />
                                                    {
                                                        item.worksType === 2 || item.worksType === 4
                                                            ?
                                                            <i className="iconfont icon-bofang" ></i>
                                                            :
                                                            null
                                                    }
                                                </section>

                                                {/* 信息 */}
                                                <section className="works_infos flex flex-direction-column flex-justify-content-space-between">
                                                    <section>
                                                        <h2>{item.title}</h2>
                                                        <span>{item.createTime}</span>
                                                    </section>
                                                    <section className="flex flex-align-items-center flex-justify-content-space-between" >
                                                        <span>形式：{item.classifyName}</span>

                                                        <ul className="flex flex-align-items-center" >
                                                            <li onClick={e => {
                                                                this.props.history.push(`/upload/${item.worksType === 2 || item.worksType === 3 ? 'video' : 'imgText'}/${WROKS_TYPE[WROKS_TYPE[item.worksType]]}/${item.id}`)
                                                            }} >
                                                                <i className="iconfont icon-bianji" ></i> 编辑
                                                            </li>
                                                            <li>
                                                                <Popconfirm
                                                                    title={`确定要删除草稿箱中的《${item.title}》作品吗？`}
                                                                    onConfirm={this.handleDelWroksDraft.bind(this, item.id)}
                                                                >
                                                                    <i className="iconfont icon-shanchu1" ></i> 删除
                                                                </Popconfirm>
                                                            </li>
                                                        </ul>
                                                    </section>
                                                </section>
                                            </section>
                                        )
                                    })
                                }
                            </section>
                    }

                </section>
                <Pagination
                    style={{ textAlign: 'right' }}
                    defaultCurrent={1}
                    pageSize={worksTab[worksIndex].status === 0 ? 3 : 6}
                    total={total}
                    showSizeChanger={false}
                    onChange={p => {
                        this.getMyWorksList(worksTab[worksIndex].status, worksType, p)
                    }}
                />
            </section>
        )
    }
}

export default MyWorks;