import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import Upload from '../Upload';
import './index.less';

class Guide extends Component {


    constructor(props) {
        super(props)
        this.state = {
            swiperIndex: 0,
            swiperList: [
                {
                    title: `恭喜您成为本平台会员，本次登录获得 ${window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo')).userType === 3 ? '100' : '30'} 科普力，可用于下载作品，大量科普资源等您下载！`,
                    img: 'https://kepu-1300652598.cos.ap-beijing.myqcloud.com/guide1.png'
                },
                {
                    title: '科普力获取方式',
                    img: 'https://kepu-1300652598.cos.ap-beijing.myqcloud.com/guide2.png'
                }
            ]
        }
    }

    render() {
        const { isShow, onChangeUserInfo } = this.props;
        let { swiperList, swiperIndex } = this.state;
        return (
            <section className="guide_container" style={{ display: isShow ? 'block' : 'none' }}>
                <section>
                    <section className="guide_skip" >
                        <span onClick={e => onChangeUserInfo()} >跳过</span>
                    </section>
                    <ul style={{ transform: `translateX(-${swiperIndex * 1200}px)` }} className="swiper_container flex flex-align-items-center" >
                        {
                            swiperList.map((item, index) => {
                                return (
                                    <li className="flex-shrink" key={index}>
                                        <section>{item.title}</section>
                                        <img src={item.img} alt={item.title} />
                                    </li>
                                )
                            })
                        }
                    </ul>

                    <ul className="swiper_slide flex flex-align-items-center flex-justify-content-center">
                        {
                            swiperList.map((_, index) => {
                                return (
                                    <li key={index} className={`flex-shrink ${swiperIndex === index ? 'active' : ''}`}></li>
                                )
                            })
                        }
                    </ul>

                    <section className="guide_action flex flex-align-items-center flex-justify-content-center">
                        <section onClick={e => {
                            this.setState({
                                swiperIndex: ++swiperIndex >= swiperList.length ? 0 : swiperIndex
                            })
                        }} >{
                                swiperIndex > 0
                                    ?
                                    '上一步'
                                    :
                                    '下一步'
                            }</section>
                        {
                            swiperIndex === swiperList.length - 1
                                ?
                                <section className="guide_i_know" onClick={e => {
                                    this.props.history.push('/upload/type')
                                    onChangeUserInfo()
                                }} >上传作品</section>
                                :
                                null
                        }
                    </section>
                </section>

                {/* <Upload className="upload_container" />
                <section className="guide_text">
                    <section className="guide_text_top">
                        <span onClick={e => {
                            onChangeUserInfo()
                        }}>我知道了</span>
                    </section>
                    <p>
                        <img style={{ width: '100%' }} src="https://kepu-1300652598.cos.ap-beijing.myqcloud.com/assets/how.png" alt="" />
                    </p>
                </section> */}
            </section>
        )
    }
}

export default withRouter(Guide);