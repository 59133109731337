/*
 * @Author: Yoney Y 
 * @Date: 2020-11-16 15:41:04 
 * @Last Modified by: YoneyY
 * @Last Modified time: 2020-11-26 18:08:38
 */
import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { WROKS_TYPE } from '../../utils/config';
import { WorkCard } from '../components';
import './index.less';

class TeamCrad extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { item } = this.props;
        return (
            <section id="TeamCard" className="w" >
                <section className="team_info flex" onClick={e => this.props.history.push(`/user/${item.id}`)}>
                    <img src={item.headUrl || require('../../assets/images/avatar.png')} alt="avatar" />
                    <section>
                        <span>{item.userName}</span>
                        <section className="flex" >
                            <span className="flex-shrink">公司简介：</span>
                            <p className="flex-shrink more-overflow">{item.userDesc}</p>
                        </section>
                    </section>
                </section>

                {
                    item.works && item.works.length > 0
                        ?
                        <section className="team_works_container flex" >
                            {
                                item.works && item.works.slice(0, 3).map(_item => {
                                    return (
                                        <WorkCard
                                            key={_item.id}
                                            id={_item.pathId}
                                            listType={WROKS_TYPE[_item.works_type]}
                                            title={_item.title}
                                            coverImg={_item.cover}
                                            avatar={item.headUrl}
                                            dateTime={moment(_item.create_time).format('YYYY/MM/DD')}
                                            integral={_item.coin}
                                            author={_item.userName}
                                            fileSize={_item.fileSizeB}
                                            btnType={_item.isDown > 0 ? 'get' : 'down'}
                                            onClick={e => {
                                                this.props.history.push(`/detail/${WROKS_TYPE[_item.works_type]}/${_item.id}`)
                                            }}
                                        />
                                    )
                                })
                            }
                            <section onClick={e => this.props.history.push(`/user/${item.id}`)} className="team_works_more flex flex-align-items-center flex-justify-content-center" >
                                <section >查看更多</section>
                                <i className="iconfont icon-double-arrow-left" ></i>
                            </section>
                        </section>
                        :
                        null
                }

            </section>
        )
    }
}

export default withRouter(TeamCrad);