import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'querystring';
import Cos from 'cos-js-sdk-v5';
import { COS_CONFIG } from "../../../../utils/config";
import { UploadOutlined } from '@ant-design/icons';
import { GoBack } from '../../../../compoents/components.js';
import { Form, Button, Select, Input, Upload, notification, Modal } from 'antd';
import { GET_WORKS_X, UPLOAD_ACTIVITY_ITEM } from '../../../../utils/api.js';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './index.less';

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
};
const cos = new Cos({
  SecretId:  window.atob("QUtJREtUUTdZaWJEWWNQd0JBc0tWUDJPVEhvNDRuRlBJdUZK"),
  SecretKey:  window.atob('b01lZ1J2ODRqOEY1ZXd5aUJPS3pOVUx1Q0R4UmVhRmQ=')
})
const { Item, useForm } = Form;
const { Option } = Select;
const { TextArea } = Input;

const AUpload = props => {

  const [xList, setXList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]); // 作品
  const [imageUrl, setImageUrl] = useState(undefined); // 封面图片
  const [recoFileList, setRecoFileList] = useState([]); // 作品推荐表
  const [form] = useForm();


  const [videoList, setVideoList] = useState([{}]);
  const id = props.match.params.id;
  // 作品类型4微视频5图书6创意设计7融
  const UPLOAD_TYPE = [
    {
      title: '微视频',
      type: 4
    },
    {
      title: '图书',
      type: 5
    },
    {
      title: '创意设计',
      type: 6
    },
    {
      title: '融媒体产品',
      type: 7
    },
  ]

  // Cos 上传
  const handleCosUploadFile = async (file, onProgress = p => { }) => {
    let   Bucket = window.atob('a2VwdS0xMzA3ODkzNzky');
    let   Region  = window.atob('YXAtYmVpamluZw==');
    const Key = `${new Date().getTime()}-${file.name}`;
    let progress = 0;
    const data = await cos.putObject({
      Bucket,
      Region,
      StorageClass: 'STANDARD',
      Body: file,
      Key, // fileName
      onProgress
    })
    // if(tabIndex===0||tabIndex===3){
    //     return { ...data, mediaCover: `https://kepu-1300652598.cos.ap-beijing.myqcloud.com/mediaCover/${Key.replace(/\.mp4/, '')}_0.jpg` };
    // }
    // console.log('handleCosUploadFile', data)
    return data;
  }

  // 自定义上传
  const customRequest = async options => {
    const { file, onSuccess, onProgress } = options;
    console.log('customRequest', options)
    const { Location, mediaCover, progress } = await handleCosUploadFile(file, p => {
      let percent = Math.floor((p.loaded / p.total) * 100)
      console.log('progress', percent)
      onProgress({ percent })
    })
    const path = `https://${Location}`;
    const result = {
      path,
      mediaCover,
      size: file.size,
      fileName: file.name
    }
    onSuccess(result)
  }

  // 上传作品文件处理
  const processFileList = (type = 'works', info) => {

    let fileList = [...info.fileList];

    fileList = fileList.slice(-1);

    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.path
      }

      return file
    })

    type === 'works' ? setFileList(fileList) : setRecoFileList(fileList);

    // if (type === 'works' && info.file.status === 'done' && (tabIndex === 0 || tabIndex === 3)) {
    //   setImageUrl(`https://kepu-1300652598.cos.ap-beijing.myqcloud.com/mediaCover/${info.file.response.fileName.replace(/\.mp4/, '')}_0.jpg`);
    // }
  }

  // 获取作品形式
  const getWorksXList = async (type) => {
    const { data: ret } = await axios.post(GET_WORKS_X, qs.stringify({ type }));
    console.log('作品形式=>', ret);
    setXList(ret.data)
  }

  // 上传作品
  const handleUploadWorksEvent = async (item) => {
    item.noticeId = props.match.params.id;
    item.worksType = UPLOAD_TYPE[tabIndex].type;
    item.cover = item.cover.file.response.path;
    item.worksTable = item.worksTable.file.response.path;

    let vlist = videoList.filter(item => item.filePath)
    if (tabIndex === 0 || tabIndex === 3) {
      item.files = JSON.stringify(vlist)
    } else {
      item.files = JSON.stringify([{ filePath: item.files.file.response.path, fileSize: item.files.file.size }]);
    }
    const { data: ret } = await axios.post(UPLOAD_ACTIVITY_ITEM, qs.stringify(item))
    console.log(ret)

    if (ret.code !== 200) return notification['error']({ message: ret.msg });

    notification['success']({
      message: '上传成功'
    })

    cancelEvent()

  }

  // resetFields
  const cancelEvent = () => {
    form.resetFields();
    setImageUrl(undefined);
    setFileList([]);
    setRecoFileList([]);
    setVideoList([{}])
  }


  useEffect(() => {
    getWorksXList(UPLOAD_TYPE[tabIndex].type);
  }, [tabIndex])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>选择封面</div>
    </div>
  )
  const changeName = (e, index) => {
    const newArr = [...videoList];
    newArr[index].workName = e.target.value;
    setVideoList(newArr);
  };
  // 作品
  let uploadprops = {
    // name: "file2",
    // multiple: false,
    // directory: false,
    // headers: {
    //   token: window.localStorage.getItem("token"),
    // },
    customRequest,
  };
  // 作品上传成功后
  const onFilesChange = (info, index) => {
    console.log(info, index)
    const newArr = [...videoList];
    if (info.fileList.length) {
      let item = info.fileList[0];
      if (item.status === "done") {
        if (item.response) {
          newArr[index].filePath = item.response.path;
          newArr[index].fileName = item.response.fileName;
          newArr[index].fileSize = item.response.size;
          setVideoList(newArr);

          if (!form.getFieldValue('title')) {
            form.setFieldsValue({
              title: item.response.fileName,
            });
          }
        }

        // index === 0 && info.file.type === 'video/mp4'
        // if (index === 0) {
        //   let cover = item.response.mediaCover;
        //   console.log('previewImage', cover);
        //   form.setFieldsValue({
        //     cover,
        //   });
        //   notification['success']({
        //     message: '正在获取视频封面'
        //   })
        //   getPicture(cover, () => {
        //     setFileList([
        //       { uid: 1000, name: "mediaCover.jpg", status: "done", url: cover, thumbUrl: cover },
        //     ]);
        //   })
        // }
      }
    } else {
      newArr[index] = {};
      setVideoList(newArr);
    }
  };
  // 获取图片
  const getPicture = (url, cal) => {
    axios.get(url, {}).then((response) => {
      if (!response.data) {
        setTimeout(() => {
          getPicture(url, cal)
        }, 1000)
      } else {
        cal && cal();
      }
    }).catch(function (error) {
      console.log(error)
    });
  }
  const addVideo = () => {
    let newArr = [...videoList];
    newArr.push({});
    setVideoList(newArr);
  };

  // 删除视频
  const removeVideo = (index) => {
    let newArr = [...videoList];
    newArr.splice(index, 1);
    console.log(index, newArr)
    setVideoList(newArr);
  };

  // 格式限制
  // 微视频
  const miniVideoUpload = file => {
    return new Promise((resolve, reject) => {
      const nameArr = file.name.split('.');
      const suffix = nameArr[nameArr.length - 1];
      if (file.type != "video/mp4" || suffix != 'mp4') {
        notification['error']({
          message: '应急科普共享平台提示',
          description: "请上传mp4格式视频文件"
        })
        return reject(Upload.LIST_IGNORE);
      }
      return resolve(true);
    });
  }

  // 融媒体产品
  const productUpload = file => {
    return new Promise((resolve, reject) => {
      const fileTypeArr = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'video/mp4',
        'audio/mpeg', //mp3
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // ppt
        'application/pdf' // pdf
      ];
      const nameArr = file.name.split('.');
      const suffix = nameArr[nameArr.length - 1];
      if (!fileTypeArr.includes(file.type) || suffix == 'm4v') {
        notification['error']({
          message: '应急科普共享平台提示',
          description: "请上传jpg、png、mp4、mp3、doc、ppt、pdf格式文件"
        })
        return reject(Upload.LIST_IGNORE);
      }
      return resolve(true);
    });
  }

  // 图书
  const booksUpload = file => {
    return new Promise((resolve, reject) => {
      const fileTypeArr = [
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/pdf' // pdf
      ];
      if (!fileTypeArr.includes(file.type)) {
        notification['error']({
          message: '应急科普共享平台提示',
          description: "请上传pdf、doc格式文件"
        })
        return reject(Upload.LIST_IGNORE);
      }
      return resolve(true);
    });
  }

  // 创意设计
  const creativeUpload = file => {
    return new Promise((resolve, reject) => {
      const fileTypeArr = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // ppt
        'application/pdf' // pdf
      ];
      if (!fileTypeArr.includes(file.type)) {
        notification['error']({
          message: '应急科普共享平台提示',
          description: "请上传jpg、png、doc、pdf、ppt格式文件"
        })
        return reject(Upload.LIST_IGNORE);
      }
      return resolve(true);
    });
  }

  // 作品推荐表
  const opusUpload = file => {
    return new Promise((resolve, reject) => {
      if (file.type != 'image/jpg' && file.type != 'image/jpeg') {
        notification['error']({
          message: '应急科普共享平台提示',
          description: "请上传jpg格式文件"
        })
        return reject(Upload.LIST_IGNORE);
      }
      return resolve(true);
    });
  }

  return (
    <section id="AUpload">
      <GoBack customGoBack={e => {
        Modal.confirm({
          content: '您尚有信息未提交，是否返回上级？',
          cancelText: '取消',
          okText: "返回上一级",
          onOk() {
            props.history.goBack()
          }
        })
      }} />

      <section className="w aupload_container">
        {/* 上传Tab */}
        <ul className="upload_tab_container flex flex-align-items-center">
          {
            UPLOAD_TYPE.map((item, index) => {
              return (
                <li className={`upload_tab_item ${tabIndex === index ? 'active' : ''}`} onClick={setTabIndex.bind(this, index)} key={index}>
                  {item.title}
                </li>
              )
            })
          }
        </ul>

        {/* 上传内容ƒ */}
        <Form
          form={form}
          onFinish={handleUploadWorksEvent.bind(this)}
          labelAlign="right"
          style={{ width: 628, margin: '0 auto' }}
        >
          <Item label="作品名称" style={{ width: 320 }} name="title" rules={[
            {
              required: true,
              message: '请输入作品名称'
            }
          ]} >
            <Input placeholder="请输入作品名称" ></Input>
          </Item>
          <Item label="&nbsp;&nbsp;&nbsp;&nbsp;联系人" style={{ width: 320 }} name="contact" rules={[
            {
              required: true,
              message: '请输入联系人'
            }
          ]} >
            <Input placeholder="请输入联系人" ></Input>
          </Item>
          <Item    label="联系电话" style={{ width: 320 }} name="contactPhone" rules={[
            {
              required: true,
              message: '请填写正确格式联系电话',
              pattern: /^1[3456789]\d{9}/ig
            }
          ]} >
            <Input placeholder="请输入联系电话" ></Input>
          </Item>
          <Item label="作品形式" style={{ width: 320 }} name='classify' rules={[
            {
              required: true,
              message: '请选择作品形式'
            }
          ]}>
            <Select placeholder="请选择作品形式">
              {
                xList.map(item => <Option key={item.id} value={item.id}>{item.classifyName}</Option>)
              }
            </Select>
          </Item>
          {
            tabIndex === 0 || tabIndex === 3 ? <div>
              <Item label={tabIndex == 3 ? '文件上传' : '视频上传'}>
                {videoList.map((item, index) => {
                  return (
                    <div key={item.id || index} >
                      <Item {...formItemLayout} label={tabIndex == 3 ? '作品名称' : '视频名称'} >
                        <Input
                          placeholder="请输入"
                          onChange={(value) => {
                            changeName(value, index);
                          }}
                          style={{ width: "200px" }}
                          value={item.workName}
                        />
                      </Item>

                      <Item {...formItemLayout} label={tabIndex == 3 ? '选择文件' : '选择视频'}>
                        {id > 0 ? (
                          <Upload
                            {...uploadprops}
                            beforeUpload={tabIndex == 0 ? miniVideoUpload : productUpload}
                            onChange={(info) => {
                              onFilesChange(info, index);
                            }}
                            fileList={
                              item.filePath
                                ? [
                                  {
                                    uid: -100 + index,
                                    name: item.fileName || "文件",
                                    status: "done",
                                    url: item.filePath,
                                  },
                                ]
                                // : []
                                : null
                            }
                          >
                            <Button icon={<UploadOutlined />}>{tabIndex == 3 ? '上传作品' : '上传视频'}</Button>
                          </Upload>
                        ) : (
                          <Upload
                            {...uploadprops}
                            onChange={(info) => {
                              onFilesChange(info, index);
                            }}
                          >
                            <Button icon={<UploadOutlined />}>上传视频</Button>
                          </Upload>
                        )}
                      </Item>

                      {videoList.length > 1 && (
                        <div
                          onClick={() => {
                            removeVideo(index);
                          }}
                          className="removeList"
                        >
                          删除
                        </div>
                      )}
                    </div>
                  );
                })}

                <div className="continueAdd" onClick={addVideo}>
                  添加本系列续集
                </div>
                {tabIndex == 3 ? <span className="continueTips">仅支持mp4格式分集上传</span> : ''}
              </Item>
            </div> : <Item label="作品上传" name="files" rules={[
              {
                required: true,
                message: '请选择作品'
              }
            ]} >
              <Upload
                customRequest={customRequest}
                beforeUpload={tabIndex == 1 ? booksUpload : creativeUpload}
                onChange={processFileList.bind(this, 'works')}
                fileList={fileList}
              >
                <Button icon={<UploadOutlined />} type="primary" >上传文件</Button>
              </Upload>
            </Item>
          }

          <Item label="选择封面" name="cover" rules={[
            {
              required: true,
              message: '请选择封面上传'
            }
          ]}>
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={file => {
                return new Promise((resolve, reject) => {
                  const fileTypeArr = [
                    'image/jpg',
                    'image/jpeg',
                    'image/png'
                  ];
                  if (!fileTypeArr.includes(file.type)) {
                    notification['error']({
                      message: '应急科普共享平台提示',
                      description: "请上传jpg、png格式文件"
                    })
                    return reject(Upload.LIST_IGNORE);
                  }
                  setLoading(true)
                  return resolve(true);
                });
              }}
              onChange={info => {
                if (info.file.status === 'done') {
                  setLoading(false)
                  setImageUrl(info.file.response.path)
                }
              }}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Item>

          <Item label="作品简介" name="worksDesc" rules={[
            {
              required: true,
              message: '请填写作品简介'
            }
          ]}>
            <TextArea placeholder="请填写作品简介" style={{ minHeight: 166 }} />
          </Item>

         {/* <Item name="worksUrl" style={{ width: 320 }} label="首发网址" rules={[
            {
              required: true,
              message: '请填写首发网址'
            }
          ]}>
            <Input placeholder="请填写首发网址" ></Input>
          </Item>*/}

          <Item label="作品推荐表" name="worksTable" rules={[
            {
              required: true,
              message: '请选择作品推荐表'
            }
          ]}>
            <Upload
              customRequest={customRequest}
              fileList={recoFileList}
              onChange={processFileList.bind(this, 'reco')}
              beforeUpload={opusUpload}
            >
              <Button icon={<UploadOutlined />} type="primary" >上传文件 </Button>
              <span style={{ color: 'gray', fontSize: '14px' }}> 需上传jpg格式的作品推荐表</span>
            </Upload>
          </Item>

          <Item style={{ textAlign: 'center' }}>
            <Button style={{ width: 140, height: 50, borderRadius: 25, background: '#222222', border: 'none' }} htmlType="submit" type="primary" >提交</Button>
          </Item>
        </Form>
      </section>

    </section>
  )

}

export default AUpload;
