import React, { Component } from 'react';
import { Form, Input, Upload, Button, message, Modal, Row, Col, notification } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './index.less';
import axios from 'axios';
import qs from 'querystring';
import { UPLOAD } from '../../../utils/config';
import { EDIT_USER_INFO, GET_MOBILE_CODE, GET_USER_INFO_TOKEN, UPDATE_MOBILE_NAME } from '../../../utils/api';
import { inject, observer } from 'mobx-react';
import { isLoginEvent } from '../../../utils/tools';
import CryptoJS from "crypto-js";

const { Item } = Form;
const { TextArea } = Input;
let timer;

@inject('store')
@observer
class MyInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            imageUrl: null,
            userInfo: {},
            userDesc: undefined,
            isShowModal: false, // 弹出框
            isShowEditName: false, // 更改名称
            mobile: undefined,
            s: 60,
        }
    }

    beforeUpload(e) {
        console.log(e);
        this.setState({
            loading: true
        })
    }

    uploadChange(e) {
        console.log('uploadChange', e);
        if (e.file.status === 'done') {
            console.log('上传成功')

            this.setState({
                loading: false,
                imageUrl: e.file.response.path
            })
        }
    }

    componentDidMount() {
        this.getUserInfo()
    }

    // 获取用户信息
    async getUserInfo() {
        if (!isLoginEvent()) return this.props.history.replace('/');
        const { data: ret } = await axios.post(GET_USER_INFO_TOKEN);
        if(ret){//jml add
            if (ret.data.province) {
                ret.data.province = ret.data.province.replace(/市辖区/g, '');
                ret.data.province = ret.data.province.replace(/市辖县/g, '');
            }
            // .match(/.{3}/ig)
            // ret.data.province = `${ret.data.province.match(/.{3}/ig)[0]}${ret.data.province.match(/.{3}/ig)[1] ? '-' : ''}${ret.data.province.match(/.{3}/ig)[1] || ''}`
            this.setState({
                userInfo: ret.data,
                imageUrl: ret.data.headUrl,
                userDesc: ret.data.userDesc
            })
        }
    }

    // 保存用户资料
    async handleEditUserInfo() {
        const { imageUrl, userInfo, userDesc } = this.state;
        const { data: ret } = await axios.post(EDIT_USER_INFO, qs.stringify({
            userName: userInfo.userName,
            headUrl: imageUrl,
            userDesc
        }))
        console.log(ret)
        if (ret.code !== 200) return message.error(ret.msg);
        message.success('保存成功')
        this.getUserInfo()
        window.location.reload()
    }

    async handleGetMobileCode(phoneNumber) {
        const { mobile } = this.state;
        if (mobile === undefined || mobile === '') return notification['warning']({
            message: '手机号不能为空'
        })
        const key = parseInt(phoneNumber.slice(0,5))+parseInt(phoneNumber.slice(6,11));

        const baseKey=  CryptoJS.enc.Utf8.parse(key+"yUmENGyUmEN");
        const keySec = CryptoJS.AES.encrypt( CryptoJS.enc.Utf8.parse(phoneNumber+Date.parse(new Date())),baseKey,{
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
        const { data: ret } = await axios.post(GET_MOBILE_CODE, { "phoneNumber": phoneNumber ,"keySec":keySec ,type:"chgpsd" })
        console.log(ret)

        if (ret.code !== 200) {
            return notification['warning']({
                message: ret.msg
            })
        }else {
            notification['success']({
                message: '验证码以短信的形式已发送至您的手机，请注意查收'
            })
        }

        clearInterval(timer)

        timer = setInterval(() => {
            let { s } = this.state;
            if (s <= 0) {
                clearInterval(timer)
                this.setState({
                    s: 60
                })
            } else {
                this.setState({
                    s: --s
                })
            }
        }, 1000)
    }

    // 编辑用户手机号/登录名
    async hanldeUpdateUserMobileOrName(e) {
        try {
            const { data: ret } = await axios.post(UPDATE_MOBILE_NAME, qs.stringify(e))
            if (ret.code !== 200) return notification['error']({ message: ret.msg });
            notification['success']({
                message: '修改成功'
            })

            // this.getUserInfo()
            window.location.reload()
        } catch (error) {
            notification['error']({
                message: error
            })
        }
    }


    handleCancleEvent() {
        this.setState({
            isShowEditName: false,
            isShowModal: false,
        })

        // this.formRef.current
        this.refs.formRef.resetFields()
    }



    render() {
        const { loading, imageUrl, userInfo, userDesc, s, mobile, isShowModal, isShowEditName } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <section id="MyInfo">
                {/* 
                    userType 1-科普工作者  2-融创团队  3-科普专家  4-管理员 5-民众
                */}
                <Form>
                    <Item label="名称" >
                        {/* <Input style={{ width: 169 }} placeholder="请输入名称" value={userInfo.userName} /> */}
                        <span>
                            {userInfo.userName}
                        </span>
                        {/* <a style={{ marginLeft: 10 }} onClick={e => this.setState({ isShowEditName: true })}>更改名称</a> */}
                    </Item>
                    {
                        userInfo.userType === 1 || userInfo.userType === 2 || userInfo.userType === 3
                            ?
                            <Item label="省份" >
                                <span style={{ fontSize: 14, color: '#666666' }} >{userInfo.province}</span>
                            </Item>
                            :
                            null
                    }
                    <Item label="手机号" >
                        <span>{userInfo.mobile}</span>
                        <a style={{ marginLeft: 10 }} onClick={e => this.setState({ isShowModal: true })} >更换手机号</a>
                    </Item>
                    <Item label="头像" >
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            action={UPLOAD}
                            headers={
                                {
                                    token: window.localStorage.getItem('token')
                                }
                            }
                            beforeUpload={this.beforeUpload.bind(this)}
                            onChange={this.uploadChange.bind(this)}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
                        </Upload>
                        <span style={{ fontSize: 14, color: '#999999' }} >建议尺寸：500px*500px 点击头像即可更换头像</span>
                    </Item>
                    <Item label="简介" >
                        <TextArea
                            placeholder="请输入个人简介"
                            style={{ width: 696, minHeight: 200, resize: 'none' }}
                            value={userDesc}
                            onInput={e => this.setState({ userDesc: e.target.value })}
                        />
                    </Item>
                    <Item style={{ textAlign: 'center' }} >
                        <Button type="primary" onClick={this.handleEditUserInfo.bind(this)} >保存</Button>
                    </Item>
                </Form>

                <Modal
                    title="更改手机号"
                    visible={isShowModal}
                    onCancel={this.handleCancleEvent.bind(this)}
                    footer={null}
                >
                    <Form
                        ref="formRef"
                        onFinish={this.hanldeUpdateUserMobileOrName.bind(this)}
                    >
                        <Row>
                            <Col span={16}>
                                <Item label="手机号" name="mobile" rules={[
                                    {
                                        required: true,
                                        message: '请填写正确格式手机号',
                                        pattern: /^1[3456789]\d{9}/ig
                                    }
                                ]} >
                                    <Input placeholder="请输入手机号" onInput={e => this.setState({ mobile: e.target.value })} ></Input>
                                </Item>
                            </Col>
                            <Col>
                                <Item style={{ marginLeft: 10 }}>
                                    <Button disabled={s < 60} onClick={this.handleGetMobileCode.bind(this, mobile)}>
                                        {
                                            s < 60
                                                ?
                                                `${s}s后重新获取`
                                                :
                                                '获取验证码'
                                        }
                                    </Button>
                                </Item>
                            </Col>
                        </Row>
                        <Item label="验证码" name="vCode" style={{ width: 200 }} rules={[
                            {
                                required: true,
                                message: '请输入手机验证码'
                            }
                        ]} >
                            <Input placeholder="请输入验证码" ></Input>
                        </Item>
                        <Item style={{ textAlign: 'center' }} >
                            <Button type="primary" htmlType="submit" >保存并修改</Button>
                        </Item>
                    </Form>
                </Modal>


                <Modal
                    title="更改名称"
                    visible={isShowEditName}
                    onCancel={this.handleCancleEvent.bind(this)}
                    footer={null}
                >
                    <Form
                        ref="formRef"
                        onFinish={this.hanldeUpdateUserMobileOrName.bind(this)}
                    >
                        <Item label="登录名" name="loginName" rules={[
                            {
                                required: true,
                                message: '请输入登录名'
                            }
                        ]} >
                            <Input placeholder="请输入登录名"  ></Input>
                        </Item>
                        <Item style={{ textAlign: 'center' }} >
                            <Button type="primary" htmlType="submit" >保存并修改</Button>
                        </Item>
                    </Form>
                </Modal>
            </section>
        )
    }
}

export default MyInfo;