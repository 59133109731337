import React, { useState } from 'react';
import { Checkbox, notification } from 'antd';
import './index.less';
import RegisterExpert from '../RegisterExpert';
import RegisterPeople from '../RegisterPeople';
import RegisterTeam from '../RegisterTeam';
import RegisterKepu from '../RegisterKepu'

const Register = props => {

    const [isShowExpert, setShowExpert] = useState(false);
    const [isShowPeople, setShowPeople] = useState(false);
    const [isShowkepu, setShowKepu] = useState(false);
    const [isShowTeam, setShowTeam] = useState(false);
    const [isChecked, setChecked] = useState(false);

    const TYPE_LIST = [
        {
            title: '应急管理系统机构',
            desic: '科普工作者',
            icon: require('../../assets/images/register_kepu.png'),
            onClick: e => {
                setShowKepu(true)
            }
        },
        {
            title: '应急科普专家',
            desic: '科普专家',
            icon: require('../../assets/images/register_zhuanjia.png'),
            onClick: e => {
                setShowExpert(true)
            }
        },
        {
            title: '企事业单位/公众',
            desic: '',
            icon: require('../../assets/images/register_minzhong.png'),
            onClick: e => {
                setShowPeople(true)
            }
        },
        {
            title: '科普产品供应方',
            desic: '融创团队',
            icon: require('../../assets/images/register_gongying.png'),
            onClick: e => {
                setShowTeam(true)
            }
        },
    ]

    const onProtocolChange = e => {
        setChecked(e.target.checked);
    }

    const viewProtocol = (mode, e) => {
        e.preventDefault();

        const obj = {
            // user: 'https://kepu-1300652598.cos.ap-beijing.myqcloud.com/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E2%80%9D%E7%94%A8%E6%88%B7%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE(1).doc?ci-process=doc-preview&dstType=html&copyable=0',
            // privacy: 'https://kepu-1300652598.cos.ap-beijing.myqcloud.com/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E2%80%9D%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96(1).doc?ci-process=doc-preview&dstType=html&copyable=0'
            user: 'https://kepu-1307893792.cos.ap-beijing.myqcloud.com/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E2%80%9D%E7%94%A8%E6%88%B7%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE(1).doc?ci-process=doc-preview&dstType=html&copyable=0',
            privacy: 'https://kepu-1307893792.cos.ap-beijing.myqcloud.com/%E5%BA%94%E6%80%A5%E7%A7%91%E6%99%AE%E4%BA%91%E5%B9%B3%E5%8F%B0%E2%80%9D%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96(1).doc?ci-process=doc-preview&dstType=html&copyable=0'

        }
        window.open(obj[mode]);

    }

    return (
        <section className="register_container flex-shrink">
            <section className="top flex flex-align-items-center flex-justify-content-space-between ">
                <h2>注册</h2>
                <span onClick={e => props.onBackLogin(0)} style={{ cursor: 'pointer' }} >返回登录</span>
            </section>

            <ul className="register_list flex flex-align-items-center flex-justify-content-space-between" >
                {
                    TYPE_LIST.map((item, index) => {
                        return (
                            <li
                                className="register_item flex-align-items-center flex-justify-content-center flex-direction-column"
                                onClick={() => {
                                    if (isChecked) {
                                        item.onClick()
                                    } else {
                                        notification['info']({
                                            message: '请先同意用户、隐私协议',
                                        })
                                    }
                                }}
                                key={index}
                            >
                                <img src={item.icon} alt={item.title} />
                                <section>{item.title}</section>
                                <span>{item.desic ? `（ ${item.desic} ）` : ''}</span>
                            </li>
                        )
                    })
                }
            </ul>

            <div className="checkboxContainer">
                <Checkbox className="checkbox" onChange={onProtocolChange}>
                    我已阅读并接受
                    <span
                        className="name"
                        onClick={viewProtocol.bind(this, 'user')}
                    >用户协议</span>
                    和
                    <span
                        className="name"
                        onClick={viewProtocol.bind(this, 'privacy')}
                    >隐私协议</span>
                </Checkbox>
            </div>

            {/* 专家申请 */}
            <RegisterExpert visible={isShowExpert} onCancel={e => setShowExpert(e)} />
            {/* 普通用户 */}
            <RegisterPeople visible={isShowPeople} onCancel={e => setShowPeople(e)} />
            {/* 融创团队 */}
            <RegisterTeam visible={isShowTeam} onCancel={e => setShowTeam(e)} />
            {/* 科普 */}
            <RegisterKepu visible={isShowkepu} onCancel={e => setShowKepu(e)} />

        </section>
    )
}

export default Register;