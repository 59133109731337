import React, {Component, useState} from 'react';
import { Modal, Form, Input, Button, notification, Row, Col, Cascader } from 'antd';
import axios from 'axios';
import qs from 'querystring';
import { GET_MOBILE_CODE, REGISTER_SUBMIT, GET_RANK_LISTS } from '../../utils/api';
import './index.less';
import ReactSimpleVerify from 'react-simple-verify'; // 引入react滑块验证
import 'react-simple-verify/dist/react-simple-verify.css';
import model from "echarts/src/model/Model";
import CryptoJS from 'crypto-js';
const { Item } = Form;
let timer;

class ApplePeople extends Component {

    constructor(props) {
        super(props)
        this.state = {
            s: 60,
            mobile: undefined,
            cityList: [],
            province: [],
            checkVal: false,
            isShow: false
        }
    }

    componentDidMount() {
        this.getCityList()
    }

    // 获取省市县
    async getCityList() {
        const { data: ret } = await axios.get(GET_RANK_LISTS);
        this.setState({
            cityList: ret.data
        })
    }

    // 提交注册
    async handleSubmitPeople(params) {
        console.log(params)
        /*if(!this.state.checkVal){
            return false;
        }*/
        console.log(params.values)
        params.userType = 5
        if( params.values){
            params.values = params.values.join('-');
        }

        params.province = this.state.province.join('-');

        const { data: ret } = await axios.post(REGISTER_SUBMIT, qs.stringify(params))
        console.log(ret)
        if (ret.code !== 200) return notification['error']({
            message: ret.msg
        })
        notification['success']({
            title: '应急科普共享平台',
            message: '注册成功'
        })
        this.handleCancelEvent()
    }

    // 获取手机验证码之前行为
     handleGetMobileCode(phoneNumber) {
          Modal.warning({
            title: '请完成验证',
            content:     <div>
                <ReactSimpleVerify width={300}    success={ this.success.bind(this) } />
            </div>,
            okText: '发送短信',
            onOk: (e)=>{
                console.log("this.state.checkVal",this.state.checkVal)

                if(this.state.checkVal){
                    this.setState({
                        checkVal: false

                    })
                    this.handleGetMobileCodeOK(phoneNumber);
                }
                Modal.destroyAll();
            }
        })
    }
    // 获取手机验证码
    async handleGetMobileCodeOK(phoneNumber) {
        console.log("handleGetMobileCodeOK")
        const { mobile } = this.state;

        if (mobile === undefined || mobile === '') return notification['warn']({
            message: '手机号不能为空'
        })
        const key = parseInt(phoneNumber.slice(0,5))+parseInt(phoneNumber.slice(6,11));

        const baseKey=  CryptoJS.enc.Utf8.parse(key+"yUmENGyUmEN");

        const keySec = CryptoJS.AES.encrypt( CryptoJS.enc.Utf8.parse(phoneNumber+Date.parse(new Date())),baseKey,{
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

        console.log("str",phoneNumber+Date.parse(new Date()));
        console.log("keySec",keySec);
        const { data: ret } = await axios.post(GET_MOBILE_CODE, { "phoneNumber": phoneNumber ,"keySec":keySec })

        console.log(ret)

        if (ret.code !== 200) {
            return notification['warning']({
                message: ret.msg
            })
        }else {
            notification['success']({
                message: '验证码以短信的形式已发送至您的手机，请注意查收'
            })
        }
        clearInterval(timer)

        timer = setInterval(() => {
            let { s } = this.state;
            if (s <= 0) {
                clearInterval(timer)
                this.setState({
                    s: 60
                })
            } else {
                this.setState({
                    s: --s
                })
            }
        }, 1000)


    }

    // 关闭modal
    handleCancelEvent() {
        this.props.onCancel(false)
        this.refs.form.resetFields()
    }
    // 重置
    success(){
        this.setState({
            checkVal: true
        });
    }
    // 重置
    reset=()=>{
        const $verify = this.refs.verify
        $verify.reset()
        this.setState({
            checkVal: false
        })
    }
    chgButton(){
        console.log("mobile",this.state.mobile)
        if(this.state.mobile && this.state.mobile.length==11){
            this.setState({
                isShow: true
            });
        }else{
            this.setState({
                isShow: false
            });
        }
    }
    render() {
        const { visible = false } = this.props;
        const { s, mobile, cityList } = this.state;

        return (
            <section id="RegisterBase">
                <Modal
                    visible={visible}
                    title="企事业单位（公众）"
                    footer={null}
                    width="40%"
                    onCancel={this.handleCancelEvent.bind(this)}
                    className="register_model"
                >
                    <Form
                        labelAlign="right"
                        labelCol={{ span: 4 }}
                        onFinish={this.handleSubmitPeople.bind(this)}
                        ref="form"
                    >
                        <Row>
                            <Col>
                                <Item name="userName" hasFeedback label="昵称" rules={[
                                    {
                                        required: true,
                                        message: '昵称不能为空'
                                    }
                                ]} >
                                    <Input placeholder="请输入昵称" />
                                </Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Item name="mobile" hasFeedback onChange={this.chgButton.bind(this)}   label="手机号" extra="该手机号将作为您登录本平台账号" rules={[
                                    {
                                        required: true,
                                        message: '请填写正确格式手机号',
                                        pattern: /^1[3456789]\d{9}/ig,

                                    }
                                ]}>
                                    <Input placeholder="请输入手机号" value={mobile} onInput={e => this.setState({ mobile: e.target.value })} onChange={e => this.setState({ mobile: e.target.value })} />
                                </Item>
                            </Col>

                            {/* 
                            <Row>
                                <Col style={{ textAlign: 'left', color: '#666' }}>
                                    
                                </Col>
                            </Row> */}
                        </Row>

                        <Row>
                            <Col span={20}>
                                <Item name="vCode" onClick={this.chgButton.bind(this)}  labelCol={{ span: 5 }} hasFeedback label="验证码"    rules={[
                                    {
                                        required: true,
                                        message: '验证码不能为空'
                                    }
                                ]} >
                                    <Input type="number" placeholder="点击此处可获取验证码" />
                                </Item>
                            </Col>
                            <Col span={4}>
                                <Item>
                                    <Button     style={this.state.isShow?{ marginLeft: 20 } :{ marginLeft: 20 ,display:'none'}} disabled={s < 60} onClick={this.handleGetMobileCode.bind(this, mobile)} >
                                        {
                                            s < 60
                                                ?
                                                `${s}s后重新获取`
                                                :
                                                '获取验证码'
                                        }
                                    </Button>
                                </Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Item name="values" label="地址">
                                    <Cascader options={cityList} fieldNames={{
                                        label: 'name',
                                        value: 'id'
                                    }} onChange={
                                        (e, options) => {
                                            const arr = options.map(item => item.name);
                                            console.log(arr);
                                            this.setState({ province: arr });
                                        }
                                    } placeholder="请选择区域" />
                                </Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Item name="textPwd" hasFeedback label="输入密码" rules={[
                                    {
                                        required: true,
                                        message: '密码不能为空,必须包含字母数字以及特殊字符并且至少8位',
                                        pattern: /(?=.*[a-z_])(?=.*\d)(?=.*[^a-z0-9_])[\S]{8,}/ig
                                    }
                                ]} >
                                    <Input type="password" placeholder="请输入密码" />
                                </Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Item name="confirmpassword" hasFeedback dependencies={['textPwd']} label="确认密码" rules={[
                                    {
                                        required: true,
                                        message: '确认密码不能为空'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('textPwd') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('确认密码与您输入的密码不一致');
                                        },
                                    }),
                                ]} >
                                    <Input type="password" placeholder="请确认密码" />
                                </Item>
                            </Col>
                        </Row>
                       {/* <Row>
                            <Col>
                                <Item>
                                   <div style={{float : 'left',marginLeft: '120px'}}>
                                    <ReactSimpleVerify width={600} ref="verify" success={  this.success.bind(this)  } />
                                   </div>
                                </Item>
                            </Col>
                        </Row>*/}
                        <Item style={{ textAlign: 'center' }} >
                            <Button htmlType="submit" type="primary" >提交</Button>
                        </Item>
                    </Form>
                </Modal>
            </section>
        )
    }
}

export default ApplePeople;