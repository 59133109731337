import React, { Component } from 'react';
import './index.less';

class Loading extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {
        const { isLoading } = this.props;
        return (
            <section style={{ zIndex: isLoading ? 2 : -1 }} className="loading flex flex-align-items-center flex-justify-content-center">
                <div></div>
                <div></div>
                <div></div>
            </section>
        )
    }
}

export default Loading;