import React, { useState, useEffect } from 'react';
import ActivityCard from '../../../../compoents/ActivityCard';
import axios from 'axios';
import './index.less';
import { Pagination } from 'antd';
import { GET_ACTIVITE_CLASSIFY, GET_WORKS_A_LIST,GET_WORKS_A_NO_LIST } from '../../../../utils/api';
import { withRouter } from 'react-router';
import { ACTIVITY_WORKS_TYPE, WROKS_TYPE } from '../../../../utils/config';

const Excellent = props => {
    const [total, setTotal] = useState(1);
    const [classifyList, setClassifyList] = useState([]);
    const [classifyIndex, setClassifyIndex] = useState(window.localStorage.getItem('ac_excellent_id') || 0);
    // const [classifyIndex, setClassifyIndex] = useState(0);
    const [excellentList, setExcellentList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [isDaFen, setisDaFen] = useState(false)  //是否未打分
    // const { userType } = JSON.parse(window.localStorage.getItem('userInfo'))

    // 获取分类
    const getWorksClassify = async () => {
        const { data: ret } = await axios.get(GET_ACTIVITE_CLASSIFY)
        // console.log(userType)
        // const { userType } = JSON.parse(window.localStorage.getItem('userInfo'))
        ret.data.unshift({
            differ: 1,
            id: 0,
            type: "全部"
        })
        if(JSON.parse(window.localStorage.getItem('userInfo')) && JSON.parse(window.localStorage.getItem('userInfo')).userType === 3 && JSON.parse(window.localStorage.getItem('userInfo')).isJudges){
                ret.data.push({
                differ: 1,
                id: 8,
                type: "未评分"
            })
        }
        // if(userType === 3){
        //     ret.data.push({
        //         differ: 1,
        //         id: 8,
        //         type: "未评分"
        //     })
        // }
        setClassifyList(ret.data);
    }

    // 获取优秀作品
    const getExcellentList = async (type = 3, worksType = undefined, pageNum = window.localStorage.getItem('ac_page_num') || 1, pageSize = 50) => {
        console.log('获取优秀作品****************'+type)
        if (window.localStorage.getItem('ac_page_num')) {
            setPageNum(window.localStorage.getItem('ac_page_num'));
        }
        const { data: ret } = await axios.get(GET_WORKS_A_LIST, {
            params: {
                type,
                worksType,
                pageNum,
                pageSize,
                noticeId: props.match.params.id
            }
        })

        console.log('获取优秀作品****************',ret.data[0])
        setExcellentList([]);
        setExcellentList(ret.data);
        setTotal(ret.total)

        console.log('worksType',worksType)
       /* setisDaFen(false)
        if(worksType === 8){
            setisDaFen(true)
            getWorksNotScore()
        }*/
    }

    const getWorksNotScore = async (type = 1, worksType = undefined, pageNum = window.localStorage.getItem('ac_page_num') || 1, pageSize = 50) => {
        if (window.localStorage.getItem('ac_page_num')) {
            setPageNum(window.localStorage.getItem('ac_page_num'));
        }
        const { data: ret } = await axios.get(GET_WORKS_A_NO_LIST, {
            params: {
                // type,
                // worksType,
                pageNum,
                pageSize,
                noticeId: props.match.params.id
            }
        })
        console.log( ret+"***********jml")
        setExcellentList([]);
        //原来是5
        if(ret.code ==200){
            ret.data.worksType = 14
            setExcellentList(ret.data);
            setTotal(ret.total)
            setisDaFen(true)
        }
        // ret.data.worksType = 14
        // setExcellentList(ret.data);
        // setTotal(ret.total)
        // setisDaFen(true)
    }

/*    useEffect(() => {
        console.log('classifyList',classifyList)
        if (classifyList.length != 0 && classifyIndex != 0) {
            getExcellentList(1, classifyList[classifyIndex].id);
        }
    }, [classifyList])*/

    useEffect(() => {
        // getWorksClassify()
        getExcellentList()
        // getWorksNotScore()
    }, [])

   /* const classifyChange = (index) => {
        console.log('index*****',index)
        /!*if(index === 5){
            setisDaFen(true)
            setClassifyIndex(index);
            console.log('1*************')
            window.localStorage.setItem('ac_excellent_id', index);
            window.localStorage.setItem('ac_page_num', 1);
            if (index === 0) return getExcellentList();
            // getExcellentList(1, classifyList[index].id)
            getWorksNotScore(1,classifyList[index].id)
        }else{*!/

            setisDaFen(false)
            setClassifyIndex(index);
            window.localStorage.setItem('ac_excellent_id', index);
            window.localStorage.setItem('ac_page_num', 1);
            if (index === 0) return getExcellentList();
            getExcellentList(1, classifyList[index].id)
        // }

    }*/

    return (
        <section id="Excellent">
           {/* <section className="work_list_filter flex flex-align-items-center">
                <ul className="w flex flex-align-items-center">
                    {
                        classifyList.map((item, index) => {

                            return (
                                <li
                                    className={`${classifyIndex == index ? 'active' : ''}`}
                                    key={index}
                                    onClick={classifyChange.bind(this, index)}
                                >
                                    {item.type}
                                </li>
                            )
                        })
                    }
                </ul>

            </section>*/}

            <section className="work_list_container w flex flex-align-items-center flex-wrap">
                <ul>
                {
                    excellentList.map((item,index) => {

                        return (
                            <li className="paihang_list">
                            <section className="flex flex-align-items-center flex-justify-content-space-between">
                                <span className="paihang_list_paiming">第<span>{(pageNum-1)*50 +index +1}</span>名</span>

                                <span className="paihang_list_title" title={item.title}  onClick={e => {
                                    window.localStorage.setItem("ac_page_num", pageNum);
                                    props.history.push({pathname:`/adetail/${ACTIVITY_WORKS_TYPE[item.worksType]}/${item.id}/${isDaFen}`})
                                }}>{item.title}</span>
                           {/*     <span>
                        <i style={{ marginRight: 6 }} className="iconfont icon-liulan"></i>
                                    {item.worksHot ?? 0}
                    </span>*/}
                                <span className="paihang_list_gs_title" title={item.company}>{item.company}</span>
                                <span className="paihang_list_toupiao">
                        <i style={{ marginRight: 6 }} className="iconfont icon-toupiao"></i>
                                    投票：{item.votes ?? 0}
                    </span>
                            </section>
                            </li>
                           /* <ActivityCard
                                className="work_list_items"
                                info={item}
                                key={item.id}
                                onClick={e => {
                                    window.localStorage.setItem("ac_page_num", pageNum);
                                    props.history.push({pathname:`/adetail/${ACTIVITY_WORKS_TYPE[item.worksType]}/${item.id}/${isDaFen}`})
                                }}
                            />*/
                        )
                    })

                }
                </ul>
            </section>
            <section className="w" style={{ textAlign: 'center', paddingBottom: '40px',marginTop:'30px'  }}>
                <Pagination total={total} pageSize={50} defaultCurrent={1} current={Number(pageNum)} onChange={pageNum => {
                    setPageNum(pageNum);
                    window.localStorage.setItem('ac_page_num', pageNum);
                    // const worksType = classifyList[classifyIndex].id == 0 ? undefined : classifyList[classifyIndex].id
                    getExcellentList(3,  undefined, pageNum);
                }} />
            </section>
        </section>
    )
}

export default withRouter(Excellent);