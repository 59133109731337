import {
  Content,
  Expert,
  Home,
  Team,
  Detail,
  My,
  MyInfo,
  MyWorks,
  MyDown,
  MyMsg,
  MyColl,
  MyInte,
  User,
  Rank,
  NoticeList,
  NoticeDetial,
  UploadType,
  UploadVideo,
  UploadImgText,
  Search,
  RankChild,
  Activity,
  AUpload,
  ADetail,
  ShareDetail
} from "../pages/index";
import OldActivity from "../pages/OldActivity";
import OldAUpload from "../pages/OldActivity/components/AUpload";

export const ROUTE_LIST = [
  {
    path: "/",
    excat: true,
    component: Home,
  },
  {
    path: "/content/list/:type",
    excat: true,
    component: Content,
  },
  {
    path: "/team/list",
    exact: true,
    component: Team,
  },
  {
    path: "/expert/list",
    exact: true,
    component: Expert,
  },
  {
    path: "/detail/:type/:id",
    exact: true,
    component: Detail,
  },
  {
    path: "/share/detail/:type/:id",
    exact: true,
    component: ShareDetail,
  },
  {
    path: "/my",
    exact: false,
    component: My,
  },
  {
    path: "/user/:id",
    exact: true,
    component: User,
  },
  {
    path: "/notice/list",
    excat: true,
    component: NoticeList,
  },
  {
    path: "/notice/content/:id",
    exact: true,
    component: NoticeDetial,
  },
  {
    path: '/rank/list',
    exact: true,
    component: Rank
  },
  {
    path: '/rank/child/list',
    exect: true,
    component: RankChild
  },
  {
    path: '/search',
    exact: true,
    component: Search
  },
  {
    path: "/upload/type",
    exact: true,
    component: UploadType,
  },
  {
    path: "/upload/video/:type/:id",
    exact: true,
    component: UploadVideo,
  },
  {
    path: "/upload/imgText/:type/:id",
    exact: true,
    component: UploadImgText,
  },
  {
    path: '/activity/detail/:id',
    exact: true,
    component: Activity
  },
  {
    path: '/oldActivity/detail/:id',
    exact: true,
    component: OldActivity
  },
  {
    path: '/activity/upload/:id',
    exact: true,
    component: AUpload
  },
  {
    path: '/oldActivity/upload/:id',
    exact: true,
    component: OldAUpload
  },
  {
    path: "/adetail/:type/:id/:isDafen",
    exact: true,
    component: ADetail,
  },
  {
    path: "/share/adetail/:type/:id",
    exact: true,
    component: ShareDetail,
  },
];

export const MY_ROUTER = [
  {
    path: "/my",
    exact: true,
    component: MyInfo,
  },
  {
    path: "/my/works",
    exact: true,
    component: MyWorks,
  },
  {
    path: "/my/down",
    exact: true,
    component: MyDown,
  },
  {
    path: "/my/msg",
    exact: true,
    component: MyMsg,
  },
  {
    path: "/my/coll",
    exact: true,
    component: MyColl,
  },
  {
    path: "/my/inte",
    exact: true,
    component: MyInte,
  }
];
