import React, { Component } from 'react';
import { message, notification } from 'antd';
import axios from 'axios';
import qs from 'querystring';
import { inject, observer } from 'mobx-react';

// import assets
import './Frame.less';
import Record from '../../assets/images/record_icon.png';
import { withRouter } from 'react-router-dom';
import { GET_FRIEND_LINK, GET_USER_INFO_TOKEN, LOGIN } from '../../utils/api';
import { isLoginEvent } from '../../utils/tools';
import Header from '../Header';
import Login from '../Login';

function closeAll() {
    // 禁止右键菜单
    document.addEventListener('contextmenu', event => {
        if (window.event) {
            event = window.event; //处理兼容性，获得事件对象
            event.returnValue = false;
        } else {
            event.preventDefault()
        }

    })

    let element = document.createElement('checkDevTools');

    Object.defineProperty(element, 'id', {
        get: function () {
            /* TODO */
            return window.location.href = 'about:blank'
        }
    });

    // 禁止F12
    document.addEventListener('keydown', event => {
        if (window.event && window.event.keyCode === 123) {
            event = window.event; //处理兼容性，获得事件对象
            event.returnValue = false;
            event.preventDefault()
        }
    })
}

// process.env.NODE_ENV === 'production' ? closeAll() : null;
closeAll()

@inject('store')
@observer
class Frame extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navIndex: 0,
            navList: [
                {
                    title: '首页',
                    path: '/'
                },
                {
                    title: '视频',
                    path: '/content/list/media',
                    detail: '/detail/media'
                },
                {
                    title: '图文',
                    path: '/content/list/doc',
                    detail: '/detail/doc'
                },
                {
                    title: '其他',
                    path: '/content/list/other',
                    detail: '/detail/other'
                },
                {
                    title: '科普专家',
                    path: '/expert/list'
                },
                {
                    title: '融创团队',
                    path: '/team/list'
                },
                {
                    title: '科普场馆',
                    path: '/content/list/venue',
                    detail: '/detail/venue'
                }
            ],
            userName: null,
            passwrod: null,
            isLogin: false,
            menuList: [
                {
                    title: '个人资料',
                    path: '/my'
                },
                {
                    title: '作品管理',
                    path: '/my/works'
                },
                {
                    title: '下载记录',
                    path: '/my/down'
                },
                {
                    title: '消息通知',
                    path: '/my/msg'
                },
                {
                    title: '我的收藏',
                    path: '/my/coll'
                }
            ],
            linkList: [],
            searchText: '',
            userInfo: {},
            routePath: '/'
        }
    }

    componentDidMount() {
        this.getFriendLink()

        if (isLoginEvent()) {
            this.getUserInfo()
        }

        this.setState({
            routePath: this.props.history.location.pathname
        })

        this.props.history.listen(route => {
            this.setState({
                routePath: route.pathname
            })
            // window.scrollTo(0, 0)
            // console.log(this.props.match.params.type)
            // window.location.reload()
            // this.setState({
            //     listType: this.props.match.params.type
            // })
        })
    }

    handleChangeNavItem(navIndex, path) {
        this.setState({
            navIndex
        })
        this.props.history.push(path)
    }

    handleSetLogin() {
        this.props.store.setLogin(true)
        // this.setState({
        //     isLogin: this.props.store.isLogin
        // })
    }

    // 登录
    async handleLoginEvent() {
        const { userName: loginName, passwrod: pwd } = this.state;
        if (loginName === null || loginName === '') return notification['warning']({
            title: '应急科普共享平台提示',
            message: '请输入用户名'
        })
        if (pwd === null || pwd === '') return notification['warning']({
            title: '应急科普共享平台提示',
            message: '请输入密码'
        })
        const { data: ret } = await axios.post(LOGIN, qs.stringify({
            loginName,
            pwd
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        message.success('登录成功')
        window.localStorage.setItem('token', ret.data.token)
        window.localStorage.setItem('userInfo', JSON.stringify(ret.data.user))
        setTimeout(() => {
            window.location.reload()
        }, 100)
    }

    // 获取用户信息
    async getUserInfo() {
        const { data: ret } = await axios.post(GET_USER_INFO_TOKEN)
        // .match(/.{3}/ig)
        if(ret !=null){//jml add
            ret.data.province = ret.data.province ? `${ret.data.province.match(/.{3}/ig)[0]}${ret.data.province.match(/.{3}/ig)[1] ? '-' : ''}${ret.data.province.match(/.{3}/ig)[1] || ''}` : ret.data.province
            this.setState({
                userInfo: ret.data,
            })
        }

    }

    // 获取友情链接
    async getFriendLink() {
        const { data: ret } = await axios.get(GET_FRIEND_LINK)
        // console.log(ret)
        this.setState({
            linkList: ret.data
        })
    }

    render() {
        const { className, children } = this.props;
        const { linkList, routePath } = this.state;
        return (
            <section id="Frame" className={className}>
                {
                    window.location.href.includes('/share/detail') || window.location.href.includes('/share/adetail') ?
                        '' : <Header isHome={routePath === '/'} />
                }

                <main>
                    {
                        children
                    }
                </main>
                {
                    window.location.href.includes('/share/detail') || window.location.href.includes('/share/adetail') ?
                        '' : <footer>
                            <section className="w footer_container">
                                <section className="footer_firend_link" >
                                    <section>友情链接</section>
                                    <ul className="flex flex-wrap">
                                        {
                                            linkList.map(item => {
                                                return (
                                                    <li key={item.id} ><a style={{ color: '#999999' }} rel="noopener noreferrer" target="_bank" href={item.linkUrl}>{item.linkName}</a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </section>
                                <section className="footer_copy_container flex flex-align-items-center flex-justify-content-space-between">
                                    <span>Copyright © 2020-现在 ECIFANG. All rights reserved. <a style={{ color: '#999999' }} rel="noopener noreferrer" target="_bank" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备11011520号-5</a></span>
                                    <span className="flex flex-align-items-center">
                                        {/* 
                                        <img src={Record} alt="Record" />
                                        <section>
                                            <a style={{ color: '#999999' }} rel="noopener noreferrer" target="_bank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102003861">京公网安备 11010102003861号</a> 科普共建科技集团有限公司 版权所有
                                        </section> 
                                    */}
                                        <section>
                                            推荐使用360（极速模式）、搜狗（高速模式）、谷歌浏览器、火狐浏览器、Edge访问。
                                    </section>
                                    </span>
                                </section>
                            </section>
                        </footer>
                }

                <Login />
            </section>
        )
    }
}

export default withRouter(Frame);