import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'querystring';
import moment from 'moment';
import { GET_NOTICE_LIST } from '../../../utils/api';
import './index.less';
import { withRouter } from 'react-router-dom';

const Notice = props => {


    const [noticeList, setNoticeList] = useState([])

    const { push: goTo } = props.history;


    const { getNoticeList } = {

        // 获取通知
        async getNoticeList() {
            const { data: ret } = await axios.post(GET_NOTICE_LIST, qs.stringify({
                limit: 4,
                status: 2
            }));
            console.log(ret.data)
            setNoticeList(ret.data || [])
        }
    }

    useEffect(() => {
        getNoticeList()
    }, [])


    return (
        <div id="notice_container">
            <section className="w">
                <section className="notice_title flex flex-align-items-center flex-justify-content-space-between">
                    <h2>公告通知</h2>
                    <section style={{ cursor: 'pointer' }} onClick={(e) => props.history.push("/notice/list")}>
                        <span>
                            查看更多
                        <i className="iconfont icon-double-arrow-left" ></i>
                        </span>
                    </section>
                </section>

                <ul className="notice_list_container flex flex-wrap" >
                    {
                        noticeList.map(item => {
                            return (
                                <li onClick={e => goTo(`/notice/content/${item.id}`)} className="notice_list_item" key={item.id}>
                                    <section className="flex flex-align-items-center flex-justify-content-space-between">
                                        <section className="flex flex-align-items-center" >
                                            {
                                                item.callUp
                                                    ?
                                                    <span className="notice_item_tag">征集</span>
                                                    :
                                                    null
                                            }
                                            <span className="odd-overflow" numberOfLines={1}  ellipsizeMode = 'tail'  style={{"width":400}}>{item.title}</span>
                                        </section>
                                        <span>{moment(item.createTime).format("YYYY/MM/DD")}</span>
                                    </section>
                                    <p className="odd-overflow">
                                        {item.content && item.content.replace(/<.*?>/gi, "")}
                                    </p>
                                </li>
                            )
                        })
                    }
                </ul>
            </section>
        </div>
    )
}


export default withRouter(Notice);