import React, { Component } from 'react';
import axios from 'axios';
import echarts from 'echarts';
import "echarts/map/js/china";
import './index.less';
import { GET_INTEGRAL_RANK, GET_RANK_LISTS } from '../../../utils/api';
import { withRouter } from 'react-router-dom';
// import Modal from 'antd/lib/modal/Modal';

class MapRank extends Component {


    constructor(props) {
        super(props)

        this.state = {
            mapDataList: [],
            tableList: [],
            childList: [],
            isShowModal: false,
            tableIndex: -1,
            tableChildIndex: -1
        }
    }

    componentDidMount() {
        this.getInteList()
        this.getRightTableList()
    }

    initChinaMap() {
        const { mapDataList, tableList } = this.state;
        
        const valueList = mapDataList.map(item => item.value);
        const maxVal = Math.max(...valueList);


        const charts = echarts.init(this.refs.rankMap);
        window.onresize = charts.resize;
        charts.on("click", (params) => {
            console.log(params);
        });
        let option = {
            tooltip: {
                formatter: function (params, ticket, callback) {
                    if (!params.value) {
                        params.value = 0;
                    }
                    return (
                        `${params.name}${/北京|天津|上海|重庆/.test(params.name)
                            ? '市'
                            : /南海诸岛/.test(params.name)
                                ? ''
                                : '省'} <br /> ${/南海诸岛/.test(params.name)
                                    ? ''
                                    : `${params.name} : ${params.value}`}`
                    );
                }, //数据格式化
            },
            visualMap: {
                min: 0,
                max: maxVal,
                left: "left",
                top: "bottom",
                text: ["高", "低"], //取值范围的文字
                inRange: {
                    color: ["#5fd4df", "#1d59ec"], //取值范围的颜色
                },
                show: true, //图注
            },
            geo: {
                map: "china",
                roam: false, //不开启缩放和平移
                zoom: 1.23, //视角缩放比例
                label: {
                    normal: {
                        show: true,
                        fontSize: "10",
                        color: "rgba(0,0,0,0.7)",
                    },
                },
                itemStyle: {
                    normal: {
                        borderColor: "rgba(0, 0, 0, 0.2)",
                    },
                    emphasis: {
                        areaColor: "#5ecac1", //鼠标选择区域颜色
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        shadowBlur: 20,
                        borderWidth: 4,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                        borderColor: "#0848FF",
                        // color: '#fff'
                    },
                },
            },
            series: [
                {
                    name: '科普力',
                    type: "map",
                    geoIndex: 0,
                    data: mapDataList,
                },
            ],
        };
        charts.setOption(option);
    }

    // 获取科普力排行
    async getInteList() {
        try {
            const { data: ret } = await axios.post(GET_INTEGRAL_RANK);
            console.log('排行榜=>', ret);
            this.setState({
                mapDataList: ret.data,
            });
            // 初始化地图
            this.initChinaMap();
        } catch (error) {
            console.log(error)
        }
    }

    // 获取右侧科普力排行榜
    async getRightTableList() {
        const { data: ret } = await axios.get(GET_RANK_LISTS);
        this.setState({
            tableList: ret.data || []
        })
    }


    render() {
        const { tableList } = this.state;
        return (
            <div id="maprank_container">
                <section className="w">
                    <section className="rank_count_container">
                        <section className="rank_count_title">科普力排行</section>
                        <section className="flex flex-align-items-center flex-justify-content-space-between">
                            <section ref="rankMap" className="rank_count_map"></section>
                            <section className="rank_count_list">
                                <section className="rank_count_thead">
                                    <span>省份</span>
                                    <span>科普力</span>
                                </section>
                                <ul>
                                    {tableList.slice(0, 10).map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="flex flex-align-items-center flex-justify-content-space-between"
                                                onClick={e => {
                                                    this.props.history.push(`/rank/child/list?city=${item.name}&id=${item.id}`)
                                                }}
                                            >
                                                <section className="flex flex-align-items-center">
                                                    <span style={{ marginRight: 19 }}>{index + 1}</span>
                                                    <span>{item.name}</span>
                                                </section>
                                                <span>{item.value}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <section className="rank_more" onClick={e => this.props.history.push('/rank/list')}>
                                    查看更多 <i className="iconfont icon-double-arrow-left"></i>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </div>
        )
    }
}


export default withRouter(MapRank);
