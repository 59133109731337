import React, { Component } from 'react';
import axios from 'axios';
// import qs from 'querystring'
import './index.less';
import { GoBack } from '../../../compoents/components';
import { GET_NOTICE_LIST } from '../../../utils/api';


class NoticeList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            noticeList: []
        }
    }

    componentDidMount() {
        this.getNoticeList()
        window.scrollTo(0, 0)
    }


    async getNoticeList() {
        const { data: ret } = await axios.post(GET_NOTICE_LIST)
        console.log(ret)
        this.setState({
            noticeList: ret.data
        })
    }


    render() {
        const { noticeList } = this.state;
        return (
            <section id="NoticeList" className="w" >
                <GoBack />
                <h2>公告列表</h2>
                <ul className="notice_list" >
                    {
                        noticeList.map(item => {
                            return (
                                <li
                                    key={item.id}
                                    className="notice_list_item flex flex-direction-column flex-justify-content-center"
                                    onClick={e => {
                                        this.props.history.push(`/notice/content/${item.id}`)
                                    }}
                                >
                                    <section className="notice_title flex flex-align-items-center flex-justify-content-space-between ">
                                        <span className="odd-overflow" >{item.title}</span>
                                        <i className="iconfont icon-double-arrow-left" ></i>
                                    </section>
                                    <section className="notice_content flex flex-align-items-center flex-justify-content-space-between">
                                        <p className="odd-overflow" >{item.content && item.content.replace(/<.*?>/ig, '')}</p>
                                        <span>{item.createTime}</span>
                                    </section>
                                </li>
                            )
                        })
                    }
                </ul>
            </section>
        )
    }
}

export default NoticeList;