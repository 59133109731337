import React from 'react';
import './index.less';

const ActivityCard = props => {


    const { info } = props;

    return (
        <section id="ActivityCard" className={props.className} onClick={e => props.onClick && props.onClick()}>
            {/* 封面 */}
            <section className="card_cover_image" >
                <img src={info.cover || require('../../assets/images/activity_cover.png')} alt="" />
                <span>{info.title}</span>
            </section>
            {/* 作品信息 */}
            <section className="card_info_container">
                {/* 用户信息 */}
                <section className="card_user_info">
                    <img src={info.headUrl || require('../../assets/images/activity_cover.png')} alt="" />
                    <span title={info.userName || '获取中...'} className="card_user_info_userName">{info.userName || '获取中...'}</span>
                </section>
                {/* 日期 浏览量 票数 */}
                <section className="flex flex-align-items-center flex-justify-content-space-between">
                    <span>{info.createTime.replace(/-/ig, '/')}</span>
                   { <span>
                        <i style={{ marginRight: 6 }} className="iconfont icon-liulan"></i>
                        {info.worksHot ?? 0}
                    </span>}

                    {<span>
                        <i style={{ marginRight: 6 }} className="iconfont icon-toupiao"></i>
                        {info.votes ?? 0}
                    </span>}
                    {/*<span>
                        <i style={{ marginRight: 6 }} className="iconfont icon-xiazaiwancheng"></i>
                        {info.downCount ?? 0}
                    </span>*/}
                </section>
            </section>
        </section>
    )
}

export default ActivityCard;

