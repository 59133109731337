import React, { Component } from 'react';
import axios from 'axios';
import Player from 'griffith';
import qs from 'querystring';
import moment from 'moment';
import './index.less';
import { CHECK_DOWN, GET_COMMENT_LIST, GET_WORKS_DETAIL, HANDLE_COLL_DELETE, HANDLE_COLL_WORKS, HANDLE_DELETE_LIKE, HANDLE_LIKE_WORKS, SEND_COMMENT_ITEM, DELETE_COMMENT_ITEM, GET_FILE_LIST, EXPERT_WORKS_AUTH, GET_QUICK_REPLY, EXPERT_COMMENT, GET_PAGE_QR_CODE } from '../../utils/api';
import { MessageOutlined, LikeOutlined, HeartOutlined, LikeFilled, HeartFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { BASE_URL } from '../../utils/config';
import { GoBack, ApplyExpert, NotFound, Loading, Progress } from '../../compoents/components';
import { debounce, getRouteParams, isLoginEvent, getStorage } from '../../utils/tools';
import { message, notification, Pagination, Modal, Button, Tooltip, Popconfirm } from 'antd';
import { inject, observer } from 'mobx-react';
import AUTH_EXPERT from '../../assets/images/yes.png';
import QuickReply from '../../compoents/QuickReply';
import QRCode from "qrcode.react";


const Axios = axios.create()

// import { COS_CONFIG } from "../../utils/config";

// import Cos from 'cos-js-sdk-v5';

// const cos = new Cos({
//     SecretId: COS_CONFIG.SecretId,
//     SecretKey: COS_CONFIG.SecretKey
// })

// import { getRouteParams } from '../../utils/tools';

@inject('store') @observer
class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            worksDetail: {},
            previewFileObj: {}, //预览文件内容
            sources: {
                hd: {
                    play_url: null,
                    bitrate: 0,
                    duration: 0,
                    format: '',
                    height: 426,
                    size: 0,
                    width: 758
                },
            },
            targetId: '',
            replyId: '',
            replyVisible: false,
            replyValue: '',
            commentTipVisible: false,
            downListVisible: false,
            isShowModal: false,
            docContent: {
                html: '',
                previewUrl: ''
            },
            isMouseDown: false,
            isMouseMove: false,
            mouseTop: 0,
            mouseLeft: 0,
            commentValue: '',
            fileList: [],
            projectFileInfo: {},
            isShowQuick: true,// 显示快速回复框
            quickReply: {}, // 快速回复框的信息
            commentList: [],
            commentTotal: 1,
            worksIndex: 0,
            offsetTop: 0,
            progressCount: 0,
            progressShow: false,
            isLoading: true,
            imageDetail: '', //图片类型的详情内容
        }
    }
    isPc(){
		var userAgentInfo = navigator.userAgent;
		var Agents = ["Android", "iPhone",
			"SymbianOS", "Windows Phone",
			"iPad", "iPod"];
		var flag = true;
		for (var v = 0; v < Agents.length; v++) {
			if (userAgentInfo.indexOf(Agents[v]) > 0) {
				flag = false;
				break;
			}
		}
		return flag;
	}

    componentDidMount() {
        if(this.isPc() === false){
            const url = window.location.href.replace("/detail", "/share/detail");
			window.location.href=url
        }
        console.log(this.isPc())


        // let id = getRouteParams('id')
        window.scrollTo(0, 0)
        let { id } = this.props.match.params;

        console.log(this.props)

        // 获取详情
        this.getWorksDetail(id)
        // 获取评论
        this.getWorksCommentList(id)

        const { cid } = getRouteParams()

        // 如果路由链接有cid 则获取快速回复的内容
        if (cid) {
            this.getQuickReply(parseInt(cid))
        }

        this.setState({
            offsetTop: document.querySelector('#comment').offsetTop,
            isShowQuick: cid ? true : false
        })

        // this.drawImageEvent('https://zxpx-1300652598.cos.ap-beijing.myqcloud.com/zxpx/2020/11/26/2eee288e-81b7-47d8-9435-ee4d8482dc01.jpg')
    }

    // 获取作品详情
    async getWorksDetail(id) {
        const { data: ret } = await axios.post(GET_WORKS_DETAIL, qs.stringify({
            id
        }));

        this.setState({
            worksDetail: ret.data
        });

        let fileInfo = {};

        const fileItem = ret.data.fileList.length != 0 ? ret.data.fileList[0] : {};
        const pathStr = fileItem.path || ret.data.filePath;
        const pathStrArr = pathStr ? pathStr.split('.') : [''];
        const pathSuffix = pathStrArr[pathStrArr.length - 1].toLowerCase();

        if (ret.data.htmlPath) {
            console.log('html类型哦');
            fileInfo = {
                type: 'html',
                html: ret.data.htmlPath,
                previewUrl: ret.data.fullHtmlPath
            }
        } else if (['mp4', 'flv'].includes(pathSuffix)) {
            fileInfo = {
                type: 'video',
                video: {
                    hd: {
                        play_url: pathStr,
                        bitrate: 0,
                        duration: 0,
                        format: '',
                        height: 426,
                        size: 0,
                        width: 758
                    },
                },
                cover: ret.data.cover
            }
        } else if (['jpg', 'png', 'gif', 'jpeg'].includes(pathSuffix)) {
            fileInfo = {
                type: 'image',
                image: pathStr
            }
        } else if (['mp3'].includes(pathSuffix)) {
            fileInfo = {
                type: 'audio',
                cover: ret.data.cover,
                audio: pathStr
            }
        } else {
            fileInfo = {
                type: 'other',
                cover: ret.data.cover
            }
        }

        this.setState({
            previewFileObj: fileInfo,
            isLoading: false
        })

    }

    // 获取doc详情
    // async getDocument(doc, path) {
    //     console.log(doc, path)
    //     const { data: ret } = await axios.get(doc);
    //     console.log(ret)
    //     const filePath = `${BASE_URL}${path}`;
    //     // <img.*?src="(.*?)".*?\/?>
    //     if (!ret) return;
    //     let docContent = ret.replace(/<img.*?src="(.*?)"/ig, `<img src=${filePath}$1`)
    //     this.setState({
    //         docContent
    //     })
    // }

    // 作品点赞 / 取消作品
    async handleLikeWorksAction(worksId) {

        if (!isLoginEvent()) {
            message.warning('请先登录')
            this.props.store.setLogin(true)
            return
        }
        const { worksDetail } = this.state;
        const isPraise = worksDetail.isPraise;
        // 点赞 or 取消
        const { data: ret } = await axios.post(isPraise === 0 ? HANDLE_LIKE_WORKS : HANDLE_DELETE_LIKE, qs.stringify({
            worksId
        }))
        console.log(ret)
        worksDetail['isPraise'] = isPraise === 0 ? 1 : 0;
        worksDetail['praiseCount'] = isPraise === 0 ? parseInt(worksDetail['praiseCount']) + 1 : parseInt(worksDetail['praiseCount']) - 1;
        isPraise === 0 && notification['success']({
            message: ret.msg
        })
        this.setState({
            worksDetail
        })

    }

    // 获取相关附件
    async getFileList(worksId) {
        const { data: ret } = await axios.get(GET_FILE_LIST, { params: { worksId } })
        console.log(ret)
        const { list: fileList, id, projectFile, projectFileSize } = ret.data[0]
        this.setState({
            fileList,
            projectFileInfo: {
                id,
                projectFile,
                projectFileSize
            }
        })
        console.log('fileList', fileList)
    }

    // 作品收藏 / 取消收藏
    async handleCollWorksAction(id) {
        if (!isLoginEvent()) {
            message.warning('请先登录')
            this.props.store.setLogin(true)
            return
        }
        const { worksDetail } = this.state;
        const isCollect = worksDetail.isCollect;
        await axios.post(isCollect === 0 ? HANDLE_COLL_WORKS : HANDLE_COLL_DELETE, qs.stringify({
            id
        }))
        worksDetail['isCollect'] = isCollect === 0 ? 1 : 0;
        worksDetail['collectCount'] = isCollect === 0 ? parseInt(worksDetail['collectCount']) + 1 : parseInt(worksDetail['collectCount']) - 1;
        this.setState({
            worksDetail
        })
        // console.log(ret)
    }

    // 获取评论列表
    async getWorksCommentList(worksId, pageNum = 1) {
        const { data: ret } = await axios.post(GET_COMMENT_LIST, qs.stringify({
            worksId,
            pageNum,
            pageSize: 10
        }))
        this.setState({
            commentList: ret.data,
            pageNum,
            commentTotal: ret.total
        })
    }

    // 添加评论
    async sendWorksCommentItem(worksId) {
        if (!isLoginEvent()) {
            message.warning('请先登录')
            this.props.store.setLogin(true)
            return
        }
        const { userType } = JSON.parse(window.localStorage.getItem('userInfo'))

        if (userType !== 3) {
            message.warning('您还不是专家，成为专家后才可以发表评论。')
            this.setState({
                isShowModal: true
            })
            return;
        }

        const { commentValue: content } = this.state;
        if (content.length <= 0 || content === null) return message.warning('评论内容不能为空');
        const { data: ret } = await axios.post(SEND_COMMENT_ITEM, qs.stringify({
            worksId,
            content,
            parentId: 0
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        this.setState({
            commentValue: ''
        })
        // message.success('点评成功');
        notification['success']({
            message: '回复成功'
        })
        this.getWorksCommentList(worksId)
    }

    // 专家点评
    async handleExpertCommentItem() {
        const { userType } = JSON.parse(window.localStorage.getItem('userInfo'));
        if (userType != 3) {
            notification['error']({
                message: '成为专家后才能点评'
            })
            return;
        };
        const { worksDetail, commentValue } = this.state;

        try {
            const { data: ret } = await axios.post(EXPERT_COMMENT, qs.stringify({
                worksId: worksDetail.id,
                parentId: 0,
                content: commentValue
            }))

            if (!ret || ret.code !== 200) return notification['error']({
                message: ret.msg || '点评失败，请联系管理员'
            })

            notification['success']({
                message: '点评成功'
            })

            this.setState({
                commentValue: ''
            })

            this.getWorksCommentList(worksDetail.id)
        } catch (error) {
            console.log(error)
        }
    }

    // 检测如果用户是否已经选择 如果没选择则不弹出复制的弹窗
    checkSelection() {
        window.getSelection().toString() === '' && this.setState({ isMouseMove: false })
    }

    // 鼠标按下
    handleMouseDown(e) {
        this.checkSelection()
        this.setState({ isMouseDown: true })
        console.log(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
    }

    // 鼠标移动
    handleMouseMove(e) {
        if (!this.state.isMouseDown) return;
        this.setState({ isMouseMove: true })
        console.log(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
    }

    // 鼠标弹起
    handleMouseUp(e) {
        this.checkSelection()
        this.setState({ isMouseDown: false })
        let timer;
        clearTimeout(timer)
        console.log(e.nativeEvent)
        this.setState({
            mouseLeft: e.nativeEvent.layerX / 2,
            mouseTop: e.nativeEvent.layerY - 50
        })
        // timer = setTimeout(() => {
        //     this.setState({
        //         isMouseMove: false
        //     })
        // }, 5000)
    }


    // 下载
    async checkDownload(worksId, coin, filePath, fileSize, type = 0) {
        const { progressShow } = this.state;
        if (progressShow) return;
        notification['info']({
            message: '应急科普共享平台提示',
            description: '正在进行安全校验...'
        })
        if (!isLoginEvent()) {
            notification['warning']({
                message: '应急科普共享平台提示',
                description: '检测您还未登录，请先登录进行下载'
            })
            this.props.store.setLogin(true)
            return
        }
        const { data: ret } = await axios.post(CHECK_DOWN, qs.stringify({
            worksId,
            coin,
            type
        }))
        if (ret.code !== 200) return notification['error']({
            message: '应急科普共享平台提示',
            description: ret.msg
        });
        notification['success']({
            message: '应急科普共享平台提示',
            description: '安全校验成功'
        })

        this.downLoadFile(filePath, fileSize)
    }

    // 下载列表弹框
    async downLoadFile(filePath, fileSize) {

        console.log(filePath)

        const path = filePath.split('/')

        const { data: ret, status: code } = await Axios.get(filePath, {
            responseType: 'blob',
            onDownloadProgress: progress => {
                // console.log('progress', progress, Math.floor((progress.loaded / fileSize) * 100))
                this.setState({
                    progressCount: Math.floor((progress.loaded / fileSize) * 100),
                    progressShow: true
                })
            }
        })

        if (code !== 200) return notification['error']({
            title: '应急科普共享平台',
            message: '资源加载错误，请重新下载'
        })

        // console.log(ret)

        const downloadUrl = URL.createObjectURL(ret);
        const _link = document.createElement('a');
        _link.download = path[path.length - 1]
        _link.href = downloadUrl;
        _link.style.display = 'none';
        document.body.append(_link);
        _link.click()
        URL.revokeObjectURL(ret)

        this.setState({
            progressShow: false
        })
        notification['success']({
            message: '应急科普共享平台提示',
            description: '资源加载成功，请选择保存文件目录'
        })

        // if (ret.code) {
        //     notification['error']({
        //         message: '应急科普共享平台提示',
        //         description: ret.msg
        //     })
        //     this.setState({
        //         progressShow: false
        //     })
        //     return
        // };
        // let _path = `${BASE_URL}${DOWN_FILE}?id=${id}`;
        // let _link = document.createElement('a');
        // _link.href = _path;
        // _link.style.display = 'none';
        // document.body.append(_link);
        // _link.click()
        // this.setState({
        //     progressShow: false
        // })
        // notification['success']({
        //     message: '应急科普共享平台提示',
        //     description: '资源加载成功，请选择保存文件目录'
        // })


    }

    // 图片加入水印
    // drawImageEvent(perview, logo = 'https://zxpx-1300652598.cos.ap-beijing.myqcloud.com/logo-yj.png') {
    //     return new Promise((resolve, rejects) => {
    //         let canvas = document.createElement('canvas');
    //         let ctx = canvas.getContext('2d');
    //         let img = new Image();
    //         let dataUrl = '';
    //         img.src = perview;
    //         img.setAttribute('crossOrigin', 'anonymous');
    //         img.onload = () => {
    //             canvas.width = img.width
    //             canvas.height = img.height
    //             ctx.drawImage(img, 0, 0)
    //             console.log(img.width, img.height)
    //             const img_2 = new Image();
    //             img_2.src = logo;
    //             img_2.setAttribute('crossOrigin', 'anonymous');
    //             img_2.onload = () => {
    //                 // console.log('img2', img_2.width)
    //                 ctx.drawImage(img_2, 0, 0, img_2.width, img_2.height)
    //                 dataUrl = canvas.toDataURL('image/png')
    //                 // console.log(dataUrl)
    //                 resolve(dataUrl)
    //             }
    //         }
    //     })
    // }

    // 是否专家才能点评
    commentTip() {
        const { userType } = JSON.parse(window.localStorage.getItem('userInfo'))
        if (userType !== 3) {
            this.setState({
                commentTipVisible: true
            })
        }
    }

    // 打开回复框
    showReplyContent(replyId, targetId) {
        this.setState({
            targetId: targetId,
            replyId: replyId,
            replyVisible: true
        })
        console.log(replyId, targetId)
    }

    // 取消回复
    replyCancel() {
        this.setState({
            targetId: '',
            replyId: '',
            replyValue: '',
            replyVisible: false
        })
    }

    // 专家作品认证
    async handleExpertWorkAuth(worksId) {
        const { worksDetail } = this.state;
        const { data: ret } = await axios.post(EXPERT_WORKS_AUTH, qs.stringify({ worksId }))
        if (ret.code !== 200) return notification['error']({
            message: ret.msg
        })
        notification['success']({
            message: ret.msg
        })

        if (worksDetail['isAuth'] <= 0) {

            worksDetail['isAuth'] = 1;
            ++worksDetail['authCount'];
        }

        console.log(worksDetail.authCount)

        this.setState({
            worksDetail
        })

    }

    // 获取快速回复内容
    async getQuickReply(id) {
        try {
            const { data: ret } = await axios.post(GET_QUICK_REPLY, qs.stringify({ id }))
            console.log('quick reply', ret.data)
            this.setState({
                quickReply: ret.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    // 回复
    async replySure(worksId) {
        // this.setState({
        //     replyVisible: false
        // })
        const { replyValue: content } = this.state;
        if (content.length <= 0 || content === null) return message.warning('回复内容不能为空');
        const { data: ret } = await axios.post(SEND_COMMENT_ITEM, qs.stringify({
            worksId,
            content,
            parentId: this.state.targetId
        }))
        if (ret.code !== 200) return message.error(ret.msg || '回复失败，请联系管理员');
        this.setState({
            commentValue: '',
            targetId: '',
            replyId: '',
            replyValue: '',
            replyVisible: false,
        })
        message.success(ret.msg);
        this.getWorksCommentList(worksId)
    }

    // 删除回复
    async relayDel(worksId, id, userId) {
        const { id: selfId } = JSON.parse(window.localStorage.getItem('userInfo'))
        if (userId !== selfId) return message.error('不能删除别人的回复！')
        const { data: ret } = await axios.post(DELETE_COMMENT_ITEM, qs.stringify({
            worksId,
            id
        }))
        if (ret.code !== 200) return message.error(ret.msg);
        this.setState({
            commentValue: ''
        })
        message.success(ret.msg);
        this.getWorksCommentList(worksId)
    }

    // 分享
    async doShare() {

        // const url = window.location.href;
        const url = window.location.href.replace("/detail", "/share/detail");

        // const { data: ret } = await axios.get(`${GET_PAGE_QR_CODE}?url=${url}`);
        // if (ret.code == 200) {
            Modal.info({
                title: '扫描二维码访问',
                content: <QRCode   id='qrcode' value={url}  size={250}
                                   fgColor='#515151' style={{cursor:'pointer'}}
                                   imageSettings={{   // 二维码中间的图片
                                       src: url, //图片路径
                                       height: 200,
                                       width: 200,
                                       excavate: true, // 中间图片所在的位置是否镂空
                                   }}
                />,
                okText: '确定',
            })
        // } else {
        //     message.error('分享失败，请联系管理员');
        // }
    }

    subCommentList = (item) => {
        console.log('comment', item)
        let arr = []
        let subId = item.id
        const createSub = (item) => {
            // console.log('comment', item)
            let subdom = item.subComment.map((item1, index1) => {
                let fistname = item1.userName
                let lastname = item1.parentUserName || '用户'
                return (
                    <div key={index1}>
                        <div>
                            <span className="comment_username">{fistname}</span> 回复 <span className="comment_username">{lastname}</span> ：{item1.content} <span className="comment_handle" onClick={() => {
                                this.showReplyContent(subId, item1.id)
                            }}>
                                回复
                            </span>
                            {
                                item1.userId === (window.localStorage.getItem('userInfo') && parseInt(JSON.parse(window.localStorage.getItem('userInfo')).id))

                                    ?
                                    <Popconfirm
                                        title="确定要删除该评论吗？"
                                        onConfirm={() => [
                                            this.relayDel(item.worksId, item1.id, item1.userId)
                                        ]}
                                    >
                                        <span className="comment_handle">
                                            删除
                                        </span>
                                    </Popconfirm>
                                    :
                                    null
                            }

                        </div>
                    </div>
                )
            })

            arr.push(subdom)

            // console.log('item_comment', item, item.subComment)

            if (item.subComment[0].subComment && item.subComment[0].subComment.length > 0) {
                createSub(item.subComment[0])
            }

            // if (item.subComment && item.subComment.length > 0) {
            //     // console.log(item.subComment)
            //     createSub(item.subComment)
            // }

        }

        createSub(item)

        return (
            arr.map(_i => {
                return _i
            })
        )

    }

    // 预览全部
    previewDocFile(url) {
        window.open(url);
    }

    render() {
        const {
            worksDetail,
            previewFileObj,
            sources,
            docContent,
            mouseTop,
            mouseLeft,
            isMouseMove,
            isMouseDown,
            commentValue,
            commentList,
            commentTotal,
            worksIndex,
            isShowModal,
            offsetTop,
            isLoading,
            progressCount,
            progressShow,
            replyVisible,
            // targetId,
            replyId,
            replyValue,
            commentTipVisible,
            fileList,
            projectFileInfo,
            isShowQuick,
            quickReply,
            imageDetail
        } = this.state;

        // const getBreadText = type => {
        //     if (type === 'media') {
        //         return '视频'
        //     } else if (type === 'doc') {
        //         return '图文'
        //     } else {
        //         return '其他'
        //     }
        // }

        const getIcon = type => {
            if (type === 'media' || type === 'venue') {
                return <i className="iconfont icon-shipin" style={{ color: '#8C73CE', fontSize: 24 }} ></i>
            } else if (type === 'doc') {
                return <i className="iconfont icon-appmsg" style={{ color: '#8C73CE', fontSize: 24 }} ></i>
            } else {
                return <i className="iconfont icon-qita" style={{ color: '#8C73CE', fontSize: 24 }} ></i>
            }
        }

        const Content = () => {
            const { type } = previewFileObj;


            if (type == 'video') {
                return (
                    // 视频
                    <section className="detail_content_media" >
                        <section>
                            {
                                previewFileObj.video.hd.play_url !== null
                                    ?
                                    <Player
                                        id="media_player"
                                        duration={0}
                                        cover={previewFileObj.cover}
                                        sources={previewFileObj.video}
                                        initialObjectFit="cover"
                                        locale="zh-Hans"
                                    ></Player>
                                    :
                                    null
                            }
                        </section>
                    </section>
                )
            } else if (type === 'html') {
                return (
                    // 图文
                    <section className="detail_content_doc"
                        onMouseDown={this.handleMouseDown.bind(this)}
                        onMouseMove={this.handleMouseMove.bind(this)}
                        onMouseUp={this.handleMouseUp.bind(this)}
                    >
                        {
                            <div className="htmlContainer">
                                <img
                                    className='htmlContentImg'
                                    src={previewFileObj.html}
                                />
                                {
                                    previewFileObj.previewUrl ? <div
                                        className="previewMask"
                                        onClick={this.previewDocFile.bind(this, previewFileObj.previewUrl)}
                                    ><div>查看全部</div></div> : null
                                }
                            </div>
                        }
                        <div
                            className="detail_content_select"
                            style={{ top: mouseTop, left: mouseLeft, zIndex: isMouseMove && !isMouseDown ? '1' : '-1' }}
                        >复制</div>
                    </section>
                )
            } else if (type === 'image') {
                return (
                    <img style={{ width: '100%' }} src={previewFileObj.image} alt="perview" />
                )
            } else if (type === 'audio') {
                return <div className="audio-view">
                    <img className="cover-img" src={previewFileObj.cover} alt="" />
                    <audio className="audio" src={previewFileObj.audio} controls></audio>
                </div>

            } else if (type === 'other') {
                return (
                    <img style={{ width: '100%' }} src={previewFileObj.image} alt="perview" />
                )
            }
        }


        return (
            <section id="Detail">
                <GoBack />
                <Loading isLoading={isLoading} />
                <Progress progress={progressCount} isShow={progressShow} />
                <section className="detail_container w flex flex-justify-content-space-between">
                    <section className="detail_left">
                        {/* 标题信息 */}
                        <section className="detail_title flex flex-align-items-center flex-justify-content-space-between">
                            <section>
                                {
                                    getIcon(this.props.match.params.type)
                                }
                                <span>{worksDetail.title}</span>
                            </section>
                            <section onClick={e => {
                                Modal.confirm({
                                    title: '下载提示',
                                    icon: <ExclamationCircleOutlined />,
                                    content: '下载作品时，请勿离开此页面；否则会停止下载，是否要下载作品？',
                                    onOk: () => {
                                        // worksDetail['fileList'] &&
                                        //     worksDetail['fileList'][worksIndex] &&
                                        // this.checkDownload(
                                        //     worksDetail['fileList'][worksIndex].id,
                                        //     worksDetail['fileList'][worksIndex].coin,
                                        //     worksDetail['fileList'][worksIndex].fileSizeB
                                        // )
                                        this.getFileList(worksDetail.id)

                                        this.setState({
                                            downListVisible: true
                                        })
                                    },
                                    okText: '确定',
                                    cancelText: '取消'
                                })
                            }
                            } >下载({worksDetail['fileList'] && worksDetail['fileList'][worksIndex] && worksDetail['fileList'][worksIndex].fileSize})</section>
                        </section>

                        {/* 内容 */}
                        <section className="detail_content">

                            {
                                Content()
                            }

                        </section>
                        <section className="auth_container flex flex-align-items-center flex-justify-content-space-between">
                            <span>
                                已有 <b>{worksDetail['authCount'] || 0}</b> 名专家认证
                            </span>
                            {
                                window.localStorage.getItem('userInfo') && parseInt(JSON.parse(window.localStorage.getItem('userInfo')).userType) === 3

                                    ?
                                    <span className="flex flex-align-items-center" onClick={
                                        this.handleExpertWorkAuth.bind(this, worksDetail.id)
                                    }>
                                        <img style={{ marginRight: 7, width: 22, height: 22 }} src={require('../../assets/images/auth_detail.png')} alt="auth" />
                                        {worksDetail['isAuth'] > 0 ? '已认证' : '认证'}
                                    </span>
                                    :
                                    null
                            }
                        </section>

                        {/* 视频分集 */}
                        {
                            previewFileObj.type == 'video' ?
                                <section className="diversity_container">
                                    <h2>集数</h2>
                                    <ul className="flex flex-wrap" >
                                        {
                                            worksDetail['fileList'] && worksDetail['fileList'].map((item, index) => {
                                                return (
                                                    <li
                                                        key={item.id}
                                                        className={`${worksIndex === index ? 'active' : ''}`}
                                                        onClick={() => {
                                                            this.setState({
                                                                worksIndex: index,
                                                            })

                                                            document.querySelector('video').src = item.path
                                                            document.querySelector('video').load()
                                                            document.querySelector('video').play()
                                                        }}
                                                    >
                                                        {`第${index + 1}集`}
                                                        {/* <Tooltip title={`第${index + 1}集 - ${item.name}`} >
                                                        </Tooltip> */}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </section>
                                :
                                null
                        }

                        {/* 评论 */}
                        <section className="detail_comment_container" id="comment" >

                            <section className="detail_comment_textarea">
                                <section className="comment_title" >专家点评</section>
                                <Tooltip visible={commentTipVisible} trigger="click" placement="topLeft" title="成为专家后才能点评！" >
                                    <textarea
                                        style={{ padding: 10 }}
                                        value={commentValue}
                                        maxLength="300"
                                        onInput={e => this.setState({
                                            commentValue: e.target.value
                                        })}
                                        onChange={e => this.setState({
                                            commentValue: e.target.value
                                        })}
                                        onBlur={e => this.setState({
                                            commentTipVisible: false
                                        })}
                                        onFocus={
                                            () => {
                                                this.commentTip()
                                            }
                                        }
                                    >
                                    </textarea>
                                </Tooltip>

                                <section className="comment_action flex flex-align-items-center flex-justify-content-space-between" >
                                    <span>您还可以输入<span>{300 - commentValue.length}</span>个字符</span>
                                    <section onClick={this.handleExpertCommentItem.bind(this)} >专家点评</section>
                                </section>
                            </section>

                            <section className="detail_comment_list">
                                <section className="comment_list_title flex flex-align-items-center flex-justify-content-space-between">
                                    <span>点评列表</span>
                                    {
                                        getStorage('localStorage', 'userInfo') && getStorage('localStorage', 'userInfo').userType === 3
                                        ?
                                        null :
                                        <section style={{ cursor: 'pointer' }} onClick={e => this.setState({ isShowModal: true })} >成为专家</section> 
                                    }
                                </section>
                                {
                                    commentList.length > 0

                                        ?
                                        <ul className="comment_lists" >
                                            {
                                                commentList.map(item => {
                                                    return (
                                                        <li key={item.id} className="flex" >
                                                            <img src={item.headUrl || require('../../assets/images/expert_avatar.png')} alt="avatar" />
                                                            <section className="comment_content_container" >
                                                                <section className="comment_user_info flex flex-align-items-center flex-justify-content-space-between ">
                                                                    <section className="flex flex-align-items-center" >
                                                                        <span>{item.userName}</span>
                                                                        {
                                                                            item.userType === 3
                                                                                ?
                                                                                <img src={AUTH_EXPERT} alt="AUTH_EXPERT" />
                                                                                :
                                                                                null
                                                                        }
                                                                    </section>
                                                                    <span>{moment(item.createTime).format('YYYY-MM-DD')}</span>
                                                                </section>
                                                                <section className="comment_content" >
                                                                    {item.content}
                                                                    {
                                                                        item.subComment && item.subComment.length > 0 ?
                                                                            <div className="comment_list">
                                                                                {
                                                                                    this.subCommentList(item)
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    <div className="reply_content">
                                                                        {/* style={{ display: !item.subComment && (!replyVisible || replyId !== item.id) ? 'block' : 'none' }} */}
                                                                        <span className="reply_btn_1" onClick={() => {
                                                                            this.showReplyContent(item.id, item.id)
                                                                        }}>回复</span>
                                                                        <div className="reply_text" style={{ display: replyVisible && replyId === item.id ? 'block' : 'none' }}>
                                                                            <textarea value={replyValue}
                                                                                maxLength="300"
                                                                                onInput={e => this.setState({
                                                                                    replyValue: e.target.value
                                                                                })}
                                                                                onChange={e => this.setState({
                                                                                    replyValue: e.target.value
                                                                                })} rows="4" placeholder="回复"></textarea>
                                                                            <div className="reply_btngroup">
                                                                                <span className="reply_btn" onClick={() => {
                                                                                    this.replyCancel(item.id)
                                                                                }}>取消</span> <span className="reply_btn" onClick={() => {
                                                                                    this.replySure(item.worksId)
                                                                                }}>回复</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </section>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        :
                                        <NotFound text="暂无评论" />

                                }

                                <Pagination
                                    style={{ textAlign: 'right', padding: '22px' }}
                                    defaultCurrent={1}
                                    total={commentTotal}
                                    pageSize={10}
                                    onChange={p => {
                                        this.getWorksCommentList(worksDetail.id, p)
                                    }}
                                />
                            </section>
                        </section>
                    </section>
                    <section className="detail_right">
                        <section className="detail_author_container">
                            {/* 标题 */}
                            <section className="detail_title" >{worksDetail.title}</section>
                            {/* 用户信息 */}
                            <section className="author_info flex flex-align-items-center" onClick={e => this.props.history.push(`/user/${worksDetail.userId}`)} >
                                <img src={worksDetail.headUrl} alt="" />
                                <section>{worksDetail.userName}</section>
                            </section>
                            {/* 形式 */}
                            <section className="form_info">
                                形式：{worksDetail.clsList && worksDetail.clsList[0] && worksDetail.clsList[0].name}
                            </section>
                            {/* 分类 */}
                            <section className="classify_info" >
                                所属分类：{worksDetail.inList && worksDetail.inList[0] && `${worksDetail.inList[0].parentName} ${worksDetail.inList[0].name ? '>' : ''} ${worksDetail.inList[0].name}`}
                            </section>
                            {/* 文档格式 */}
                            <section>文档格式：{/\.[\w+\d+|\w+]+$/.exec(worksDetail.filePath) && /\.[\w+\d+|\w+]+$/.exec(worksDetail.filePath)[0].replace(/\./, '').toLocaleUpperCase()}</section>
                            <section className="co_title" >
                                {/* <Tooltip title= > */}
                                <span >版权声明</span>
                                <p style={{ color: '#666', fontSize: 14, marginTop: 5 }} >
                                    {worksDetail.ifEmpower === 1 ? '完全授权：上传者拥有作品全部版权，上传后视同无偿将作品版权转让本平台，其他用户下载后可对作品进行二次开发，并可用于商业用途。' : '未完全授权：上传者不拥有全部版权，但拥用信息网络传播权，作品上传后则视为将作品信息网络传播权授予本平台，其他用户下载后可用于公益宣传。'}
                                </p>
                                {/* </Tooltip> */}
                                {/* <i className="iconfont icon-double-arrow-left" ></i> */}
                            </section>
                            {
                                previewFileObj.type == 'html'
                                    ? <div
                                        className="previewBtn"
                                        onClick={this.previewDocFile.bind(this, previewFileObj.previewUrl)}
                                    >查看全部</div>
                                    : null
                            }
                            {/* 简介 */}
                            <section className="desc_infos">
                                <span>简介：</span>
                                <p>
                                    {worksDetail.worksDesc}
                                </p>
                            </section>
                            <span>{moment(worksDetail.createTime).format('YYYY-MM-DD')}</span>
                        </section>
                    </section>
                </section>

                <section className="detail_action_container" >
                    <section className="w flex flex-align-items-center flex-justify-content-space-between">
                        <ul className="flex" >
                            <li onClick={e => {
                                window.scrollTo(0, offsetTop)
                                document.querySelector('#comment').scrollIntoView()
                            }} >
                                <MessageOutlined style={{ fontSize: 25 }} />
                                <span>{commentList.length <= 0 ? 0 : commentTotal} 评论</span>
                            </li>
                            <li onClick={
                                // this.handleLikeWorksAction.bind(this, worksDetail.id)
                                e => {
                                    debounce(this.handleLikeWorksAction(worksDetail.id), 0)
                                }
                            }>
                                {
                                    worksDetail.isPraise === 1
                                        ?
                                        <LikeFilled style={{ fontSize: 25, color: '#F65454' }} />
                                        :
                                        <LikeOutlined style={{ fontSize: 25 }} />
                                }
                                <span>{worksDetail.praiseCount} 点赞</span>
                            </li>
                            <li onClick={this.handleCollWorksAction.bind(this, worksDetail.id)} >
                                {
                                    worksDetail.isCollect === 1
                                        ?
                                        <HeartFilled style={{ fontSize: 25, color: '#F65454' }} />
                                        :
                                        <HeartOutlined style={{ fontSize: 25 }} />
                                }
                                <span>{worksDetail.collectCount} 收藏</span>
                            </li>
                        </ul>

                        <section className="down flex flex-align-items-center" >
                            <section className="flex flex-align-items-center">
                                <i className="iconfont icon-wanggeguanli-wanggeliliang" ></i>
                                <span>{worksDetail.coin}科普力</span>
                            </section >
                            <section className="detail_action_down flex flex-align-items-center flex-justify-content-center" onClick={e => {
                                Modal.confirm({
                                    title: '下载提示',
                                    icon: <ExclamationCircleOutlined />,
                                    content: '下载作品时，请勿离开此页面；否则会停止下载，是否要下载作品？',
                                    onOk: () => {
                                        // worksDetail['fileList'] &&
                                        //     worksDetail['fileList'][worksIndex] &&
                                        // this.checkDownload(
                                        //     worksDetail['fileList'][worksIndex].id,
                                        //     worksDetail['fileList'][worksIndex].coin,
                                        //     worksDetail['fileList'][worksIndex].fileSizeB
                                        // )
                                        this.getFileList(worksDetail.id)
                                        this.setState({
                                            downListVisible: true
                                        })
                                    },
                                    okText: '确定',
                                    cancelText: '取消'
                                })
                            }
                            } >
                                <i className="iconfont icon-xiazaiwancheng"></i>
                                <span>下载({worksDetail['fileList'] && worksDetail['fileList'][worksIndex] && worksDetail['fileList'][worksIndex].fileSize})</span>
                            </section>
                        </section>

                        <section className="share" onClick={this.doShare}>
                            分享
                        </section>
                        <section className="go_top" onClick={e => {
                            window.scrollTo(0, 0)
                        }} >
                            回到顶部
                        </section>
                    </section>
                </section>

                <ApplyExpert visible={isShowModal} onCancel={e => this.setState({ isShowModal: e })} />

                <Modal
                    visible={this.state.downListVisible}
                    footer={null}
                    onCancel={() => {
                        this.setState({
                            downListVisible: false
                        })
                    }}
                    width="600px"
                >
                    <div className="select_file">
                        <h3 className="select_title">下载作品</h3>
                        <div className="select_content">
                            <ul className="select_file_list flex flex-align-items-center flex-wrap" >
                                {
                                    fileList.map((item, index) => {
                                        return (
                                            <li
                                                key={item.id}
                                                onClick={() => {
                                                    this.checkDownload(
                                                        item.id,
                                                        parseInt(item.coin),
                                                        item.filePath,
                                                        item.fileSizeB
                                                    )
                                                    this.setState({
                                                        downListVisible: false
                                                    })
                                                }}
                                            >
                                                <Button type="primary" >{this.props.match.params.type === 'venue' || this.props.match.params.type === 'media' ? `第${index + 1}集` : '下载'}</Button>
                                            </li>
                                        )
                                    })
                                }

                            </ul>

                        </div>
                    </div>
                    {
                        worksDetail.projectFile === ''
                            ?
                            null
                            :
                            <div className="select_file">
                                <h3 className="select_title">工程文件</h3>
                                <div className="select_content">
                                    <Button onClick={e => {
                                        this.checkDownload(
                                            projectFileInfo.id,
                                            worksDetail.coin,
                                            projectFileInfo.projectFile,
                                            projectFileInfo.projectFileSize,
                                            1
                                        )
                                        this.setState({
                                            downListVisible: false
                                        })
                                    }}>工程文件下载</Button>
                                </div>
                            </div>
                    }
                </Modal>

                <QuickReply visible={isShowQuick} onCancel={e => {
                    this.setState({ isShowQuick: e })
                    this.props.history.push(this.props.history.location.pathname)
                }} comment={quickReply} worksId={worksDetail.id} onSuccess={res => {
                    console.log(res)
                    if (!res || res.code !== 200) return notification['error']({
                        message: res.msg || '回复失败，请联系管理员'
                    })
                    notification['success']({
                        message: '回复成功'
                    })
                    this.setState({
                        isShowQuick: false
                    })
                    this.props.history.push(this.props.history.location.pathname)

                    setTimeout(() => {
                        window.location.reload()
                    }, 500)

                }} />
            </section >
        )
    }
}

export default Detail;